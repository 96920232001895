import React from "react";
import googleLogo from "assets/loginRegistration/googleLogo.png";
import { Button } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { socialLoginAction } from "actions/login_registrationActions";
import { useDispatch } from "react-redux";

export const GoogleLogin = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => {
			console.log("Check the Response Token : ", tokenResponse);
			dispatch(
				socialLoginAction(
					{
						accessToken: tokenResponse.access_token,
					},
					navigate
				)
			);
		},
		onError: (errorResponse) => console.log("useGoogleLogin ", errorResponse),
	});

	return (
		<>
			<div>
				<Button
					className="primaryButton hoverEffect social-btn"
					onClick={() => login()}
				>
					<span className="social-logo">
						<GoogleSignIn />
					</span>
					<span className="social-text">Sign in with Google</span>
				</Button>
			</div>
		</>
	);
};

export const GoogleSignIn = () => {
	return (
		<>
			<div className="google-signIn">
				<img src={googleLogo} alt={"googleIcon"} />
			</div>
		</>
	);
};

export default GoogleLogin;
