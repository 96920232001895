import RenderTabs from "components/Tabs/Tabs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import projectCover from "assets/app/house-almost-finished.jpg";
import {
	Overview,
	TaskManager,
	UserManagement,
	ProjectTimelines,
	Analysis,
} from "./tabComponents";
import { fetchProjectById } from "actions/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectData } from "selectors/projectSelector";
import { IconButton, Typography } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { isProjectManager } from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { DynamicBreadCrumb } from "components/BreadCrumb";

const tabs = [
	{
		key: "overview",
		label: "Overview",
		TabComponent: Overview,
	},
	{
		key: "taskManager",
		label: "Jobs",
		TabComponent: TaskManager,
	},
	{
		key: "timelineView",
		label: "Timelines",
		TabComponent: ProjectTimelines,
	},
	{
		key: "suppliers",
		label: "Suppliers",
		TabComponent: Analysis,
	},
	{
		key: "changeOrders",
		label: "Change Orders",
		TabComponent: Analysis,
	},
	{
		key: "invoices",
		label: "Invoices",
		TabComponent: Analysis,
	},
	{
		key: "userManagement",
		label: "Team",
		TabComponent: UserManagement,
		hide: (appRole, projectDetails) => {
			const projectUserRole = projectDetails?.projUserRole;
			return !isProjectManager(appRole, projectUserRole);
		},
	},

	{
		key: "analysis",
		label: "Cost Analysis",
		TabComponent: Analysis,
		hide: (appRole, projectDetails) => {
			const projectUserRole = projectDetails?.projUserRole;
			return !isProjectManager(appRole, projectUserRole);
		},
	},
];

export const ViewProject = () => {
	const appRoleDetails = useContext(AppRoleContext);

	const [selectedTab, updateTab] = React.useState("overview");
	const [value, setValue] = useState(0);
	const [visibleTabs, setVisibleTabs] = useState(tabs);
	const [hiddenTabs, setHiddenTabs] = useState([]);

	useEffect(() => {
		setVisibleTabs(tabs);
	}, []);
	const [anchorEl, setAnchorEl] = useState(null);

	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const projectData = useSelector(getProjectData);

	useEffect(() => {
		const handleResize = () => {
			const containerWidth =
				document.getElementById("tab-container").offsetWidth;
			let visibleWidth = 0;
			const newVisibleTabs = [];
			const newHiddenTabs = [];
			console.log("Check the Widths : ", containerWidth);

			[...tabs, ...tabs].forEach((tab, index) => {
				const tabElement = document.getElementById(`tab-${index}`);
				console.log("Check the Tab Element : ", tabElement);
				if (tabElement) {
					const tabWidth = tabElement.offsetWidth;
					if (visibleWidth + tabWidth < containerWidth - 100) {
						// 100px for dropdown
						newVisibleTabs.push(tab);
						visibleWidth += tabWidth;
					} else {
						newHiddenTabs.push(tab);
					}
				}
			});

			setVisibleTabs(newVisibleTabs);
			setHiddenTabs(newHiddenTabs);
		};

		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const getProjectDetails = useCallback(() => {
		dispatch(fetchProjectById(params.projectId));
	}, [dispatch, params.projectId]);

	useEffect(() => {
		getProjectDetails();
	}, [dispatch, getProjectDetails]);

	console.log("Check the Visible Tabs : ", visibleTabs);

	return (
		<>
			<div className="d-flex flex-wrap justify-content-between">
				<div>
					<IconButton
						style={{ width: "6rem", height: "2rem" }}
						className="secondaryButton"
						onClick={() => window.history.back()}
					>
						<ArrowBackOutlined style={{ fontSize: "1rem" }} />
					</IconButton>
					<DynamicBreadCrumb />

					<Typography className="h1 mt-3">{projectData.projectName}</Typography>
				</div>
				<div></div>
			</div>

			<div className="project-header" style={{ height: "50px" }}>
				{/* <img
					src={projectCover}
					alt="Project Cover"
					className="full-width mt-3 mb-2"
					style={{ height: "15rem" }}
				/> */}
			</div>
			<div className="project-tabs tabs" id="tab-container">
				<RenderTabs
					tabs={tabs.filter((tab) =>
						tab.hide ? !tab.hide(appRoleDetails, projectData) : true
					)}
					selectedTab={selectedTab}
					onTabClick={(newTab) => {
						updateTab(newTab);
					}}
					tabParams={{
						projectId: params.projectId,
						projectData,
						getProjectDetails,
					}}
				/>
			</div>
			<div className="project-tab-content"></div>
		</>
	);
};

export default ViewProject;
