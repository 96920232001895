import { Popover } from "@mui/material";
import React from "react";

export const PopoverMenu = ({
  content,
  open,
  id,
  anchorEl,
  handleClose,
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
  contentWidth = "inherit",
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <div style={{ width: contentWidth }}>{content}</div>
      </Popover>
    </>
  );
};

export default PopoverMenu;
