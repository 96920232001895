import * as signalR from "@microsoft/signalr";

const createSignalRConnection = (hubUrl) => {
	const connection = new signalR.HubConnectionBuilder()
		.withUrl(hubUrl) // Replace with your SignalR hub URL
		.withAutomaticReconnect() // Automatically reconnect if the connection is lost
		.configureLogging(signalR.LogLevel.Information) // Optional: Set logging level
		.build();

	return connection;
};

export default createSignalRConnection;
