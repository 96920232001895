import React from "react";
import GoogleLogin from "./GoogleSignIn";
import MicrosoftLogin from "./MicrosoftSignIn";
import AppleSignIn from "./AppleSignIn";

export const SocialLogins = () => {
	return (
		<div className="paddingNone social-account-login flexWrapper container">
			<div className="social-login-btn google-login ">
				<GoogleLogin />
			</div>
			<div className="social-login-btn microsoft-login ">
				<MicrosoftLogin />
			</div>
			<div className="social-login-btn apple-login ">
				<AppleSignIn />
			</div>
		</div>
	);
};

export default SocialLogins;
