import * as yup from "yup";
import { statesList } from "core/utils/statesList";
import { getText } from "core/utils/getContentText";
import { InputAdornment } from "@mui/material";
import { phoneRegExp } from "constants/appConstants";
import { projectStatusOptions } from "core/utils/appUtils";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import dayjs from "dayjs";

const getTextContent = (key) => getText(`projects.addProject.${key}`);

const addProjectValidationSchema = yup.object({
	propertyId: yup
		.string("Select Property For Project")
		.required("Property is required"),
	projectName: yup
		.string("Enter Project Name")
		.required("Project Name is required"),
	projectCode: yup
		.string("Enter Project Code")
		.required("Project Code is required"),
	customerName: yup
		.string("Enter Home Owner's Name")
		.required("Home Owner's Name is required"),
	areaSqFt: yup
		.number("Enter Construction area")
		.required("Construction area is required")
		.typeError("Please enter a valid number"),
	stage: yup.string("Select Stage").required("Stage is required"),
	projectCostEstimated: yup
		.number("Enter Estimated cost")
		.required("Estimated cost is required")
		.typeError("Please enter a valid number")
		.integer("budget must be an integer"),
	constructionType: yup
		.string("Type of Construction")
		.required("Construction type is required"),
	startDate: yup.string("Enter Start Date").required("Start Date is required"),
	projectDetails: yup
		.string("Enter Project Details")
		.required("Project Details is required"),
	// address1: yup.string("Enter address").required("address is required"),
	// city: yup.string("Enter city").required("city is required"),
	// state: yup.string("Enter state").required("state is required"),
	// zip: yup.string("Enter zipcode").required("ZipCode is required"),
	// latitude: yup.string("Enter Latitude"),
	// longitude: yup.string("Enter Longitude").optional(),
	// siteLocation: yup
	// 	.string("Enter Project Location")
	// 	.required("Project Location is required"),

	landline: yup
		.string("Enter your landline")
		.matches(phoneRegExp, "Phone number is not valid"),

	mobileNumber: yup
		.string("Enter your Mobile")
		.required("Mobile is required")
		.matches(phoneRegExp, "Phone number is not valid"),

	homeownerMobile: yup
		.string("Enter your Mobile")
		.required("Mobile is required")
		.matches(phoneRegExp, "Phone number is not valid"),
});

export const addProjectFields = {
	propertyInformation: [
		{
			key: "projectName",
			label: getTextContent("name"),
			placeholder: "Project Name",
			type: "text",
			required: true,
			width: "100%",
		},
		{
			key: "projectCode",
			label: getTextContent("projectCode"),
			placeholder: "Enter Project Code",
			type: "text",
			required: true,
			width: "48%",
		},
		{
			key: "customerName",
			label: getTextContent("customerName"),
			placeholder: "Enter Customer Name",
			type: "text",
			required: true,
			width: "48%",
		},
		{
			key: "areaSqFt",
			label: getTextContent("area"),
			placeholder: "Enter Construction area",
			type: "text",
			required: true,
			width: "48%",
		},
		{
			key: "stage",
			label: getTextContent("stage"),
			placeholder: "Select Stage",
			type: "select",
			value: "Planning",
			select: true,
			options: projectStatusOptions,
			required: true,
			width: "48%",
		},
		{
			key: "projectCostEstimated",
			label: getTextContent("projectCostEstimated"),
			placeholder: "Enter Estimated cost",
			type: "text",
			required: true,
			width: "48%",
			value: 0,
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},
		{
			key: "projectCostActual",
			label: getTextContent("projectCostActual"),
			placeholder: "Enter Actual Cost",
			type: "text",
			width: "48%",
			value: 0,
			extraInputProps: {
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">{"$"}</InputAdornment>
					),
				},
			},
		},

		{
			key: "constructionType",
			label: getTextContent("type"),
			placeholder: "Type of Construction",
			type: "select",
			select: true,
			options: ["New Site", "Renovation"],
			required: true,
			width: "48%",
		},
		{
			key: "startDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("startDate"),
			type: "dateField",
			required: true,
			width: "48%",
		},
		{
			key: "completionDate",
			placeholder: "MM/DD/YYYY",
			label: getTextContent("completionDate"),
			type: "dateField",
			width: "48%",
			extraInputProps: (formValues) => ({
				disablePast: true,
				disabled: !formValues.values["startDate"],
				minDate: dayjs(formValues.values["startDate"]).add(0, "day"),
				openTo: "day",
			}),
		},
		{
			key: "projectDetails",
			label: getTextContent("projectDetails"),
			placeholder: "Enter Project Details here",
			type: "text",
			required: true,
			width: "100%",
			className: "project-details",
		},
		{
			id: "landline",
			key: "landline",
			label: getTextContent("landline"),
			placeholder: "(xxx) xxx-xxxx",
			type: "phone",
			width: "48%",
			extraInputProps: (formik) => ({
				onChange: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("landline", validatedValue);
					};
					formatNumberChange(e, onValidation);
				},
				onKeyDown: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("landline", validatedValue);
					};
					formatNumberKeyDown(e, onValidation);
				},
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">
							<span class="flag-icon flag-icon-us"></span> +1
						</InputAdornment>
					),
				},
			}),
		},
		{
			id: "mobileNumber",
			key: "mobileNumber",
			label: getTextContent("managerMobile"),
			type: "phone",
			placeholder: "(xxx) xxx-xxxx",
			required: true,
			width: "48%",
			extraInputProps: (formik) => ({
				onChange: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("mobileNumber", validatedValue);
					};
					formatNumberChange(e, onValidation);
				},
				onKeyDown: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("mobileNumber", validatedValue);
					};
					formatNumberKeyDown(e, onValidation);
				},
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">
							<span class="flag-icon flag-icon-us"></span> +1
						</InputAdornment>
					),
				},
			}),
		},
		{
			id: "homeownerMobile",
			key: "homeownerMobile",
			label: getTextContent("homeownerMobile"),
			type: "phone",
			placeholder: "(xxx) xxx-xxxx",
			required: true,
			width: "48%",
			extraInputProps: (formik) => ({
				onChange: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("homeownerMobile", validatedValue);
					};
					formatNumberChange(e, onValidation);
				},
				onKeyDown: (e) => {
					const onValidation = (validatedValue) => {
						formik.setFieldValue("homeownerMobile", validatedValue);
					};
					formatNumberKeyDown(e, onValidation);
				},
				InputProps: {
					startAdornment: (
						<InputAdornment position="start">
							<span class="flag-icon flag-icon-us"></span> +1
						</InputAdornment>
					),
				},
			}),
		},
	],
	//   projectLocation: [
	//     {
	//       key: "address1",
	//       label: getTextContent("address1"),
	//       placeholder: "Street Address",
	//       type: "text",
	//       required: true,
	//       width: "48%",
	//     },
	//     {
	//       key: "address2",
	//       label: getTextContent("address2"),
	//       placeholder: "Appartment, Building, etc",
	//       type: "text",
	//       width: "48%",
	//     },
	//     {
	//       key: "city",
	//       label: getTextContent("city"),
	//       placeholder: "City",
	//       type: "text",
	//       required: true,
	//       width: "33%",
	//     },
	//     {
	//       key: "state",
	//       label: getTextContent("state"),
	//       placeholder: "Enter State",
	//       type: "select",
	//       required: true,
	//       select: true,
	//       options: statesList,
	//       width: "33%",
	//     },
	//     {
	//       key: "zip",
	//       label: getTextContent("zip"),
	//       placeholder: "Zip Code",
	//       type: "text",
	//       required: true,
	//       width: "33%",
	//     },
	//   ],
	additionalInformation: [],
};

export const addProjectForm = {
	validationSchema: addProjectValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		}
		const initialState = Object.fromEntries(
			Object.keys(addProjectValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);

		return {
			...initialState,
			projectCostActual: 0,
			stage: "Planning",
			completionDate: "",
		};
	},
};
