//data for the library @syncfusion/ej2-react-gantt
export let projectNewData = [
	{
		TaskID: 1,
		TaskName: "Product concept",
		StartDate: new Date("04/02/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 2,
				TaskName: "Defining the product and its usage",
				StartDate: new Date("04/02/2024"),
				Duration: 3,
				Progress: 30,
			},
			{
				TaskID: 3,
				TaskName: "Defining target audience",
				StartDate: new Date("04/02/2024"),
				Duration: 3,
			},
			{
				TaskID: 4,
				TaskName: "Prepare product sketch and notes",
				StartDate: new Date("04/02/2024"),
				Duration: 2,
				Predecessor: "2",
				Progress: 30,
			},
		],
	},
	{
		TaskID: 5,
		TaskName: "Concept approval",
		StartDate: new Date("04/02/2024"),
		Duration: 0,
		Predecessor: "3,4",
		Indicators: [
			{
				date: "04/15/2024",
				name: "Design Phase",
				tooltip: "Design phase completed",
				iconClass: "okIcon e-icons",
			},
		],
	},
	{
		TaskID: 6,
		TaskName: "Market research",
		StartDate: new Date("04/02/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 7,
				TaskName: "Demand analysis",
				StartDate: new Date("04/04/2024"),
				EndDate: new Date("04/21/2024"),
				subtasks: [
					{
						TaskID: 8,
						TaskName: "Customer strength",
						StartDate: new Date("04/04/2024"),
						Duration: 4,
						Predecessor: "5",
						Progress: 30,
					},
					{
						TaskID: 9,
						TaskName: "Market opportunity analysis",
						StartDate: new Date("04/04/2024"),
						Duration: 4,
						Predecessor: "5",
					},
				],
			},
			{
				TaskID: 10,
				TaskName: "Competitor analysis",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Predecessor: "7, 8",
				Progress: 30,
			},
			{
				TaskID: 11,
				TaskName: "Product strength analsysis",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Predecessor: "9",
			},
			{
				TaskID: 12,
				TaskName: "Research complete",
				StartDate: new Date("04/04/2024"),
				Duration: 0,
				Predecessor: "10",
				Indicators: [
					{
						date: "04/27/2024",
						name: "Research completed",
						tooltip: "Research completed",
						iconClass: "description e-icons",
					},
				],
			},
		],
	},
	{
		TaskID: 13,
		TaskName: "Product design and development",
		StartDate: new Date("04/04/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 14,
				TaskName: "Functionality design",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Progress: 30,
				Predecessor: "12",
			},
			{
				TaskID: 15,
				TaskName: "Quality design",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Predecessor: "12",
			},
			{
				TaskID: 16,
				TaskName: "Define reliability",
				StartDate: new Date("04/04/2024"),
				Duration: 2,
				Progress: 30,
				Predecessor: "15",
			},
			{
				TaskID: 17,
				TaskName: "Identifying raw materials",
				StartDate: new Date("04/04/2024"),
				Duration: 2,
				Predecessor: "15",
			},
			{
				TaskID: 18,
				TaskName: "Define cost plan",
				StartDate: new Date("04/04/2024"),
				EndDate: new Date("04/21/2024"),
				subtasks: [
					{
						TaskID: 19,
						TaskName: "Manufacturing cost",
						StartDate: new Date("04/04/2024"),
						Duration: 2,
						Progress: 30,
						Predecessor: "17",
					},
					{
						TaskID: 20,
						TaskName: "Selling cost",
						StartDate: new Date("04/04/2024"),
						Duration: 2,
						Predecessor: "17",
					},
				],
			},
			{
				TaskID: 21,
				TaskName: "Development of the final design",
				StartDate: new Date("04/04/2024"),
				EndDate: new Date("04/21/2024"),
				subtasks: [
					{
						TaskID: 22,
						TaskName: "Defining dimensions and package volume",
						StartDate: new Date("04/04/2024"),
						Duration: 2,
						Progress: 30,
						Predecessor: "19,20",
					},
					{
						TaskID: 23,
						TaskName: "Develop design to meet industry standards",
						StartDate: new Date("04/04/2024"),
						Duration: 2,
						Predecessor: "22",
					},
					{
						TaskID: 24,
						TaskName: "Include all the details",
						StartDate: new Date("04/04/2024"),
						Duration: 3,
						Predecessor: "23",
					},
				],
			},
			{
				TaskID: 25,
				TaskName: "CAD computer-aided design",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Progress: 30,
				Predecessor: "24",
			},
			{
				TaskID: 26,
				TaskName: "CAM computer-aided manufacturing",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Predecessor: "25",
			},
			{
				TaskID: 27,
				TaskName: "Design complete",
				StartDate: new Date("04/04/2024"),
				Duration: 0,
				Predecessor: "26",
			},
		],
	},
	{
		TaskID: 28,
		TaskName: "Prototype testing",
		StartDate: new Date("04/04/2024"),
		Duration: 4,
		Progress: 30,
		Predecessor: "27",
	},
	{
		TaskID: 29,
		TaskName: "Include feedback",
		StartDate: new Date("04/04/2024"),
		Duration: 4,
		Predecessor: "28ss",
		Indicators: [
			{
				date: "05/24/2024",
				name: "Production phase",
				tooltip: "Production phase completed",
				iconClass: "okIcon e-icons",
			},
		],
	},
	{
		TaskID: 30,
		TaskName: "Manufacturing",
		StartDate: new Date("04/04/2024"),
		Duration: 5,
		Progress: 30,
		Predecessor: "28,29",
	},
	{
		TaskID: 31,
		TaskName: "Assembling materials to finsihed goods",
		StartDate: new Date("04/04/2024"),
		Duration: 5,
		Predecessor: "30",
	},
	{
		TaskID: 32,
		TaskName: "Feedback and testing",
		StartDate: new Date("04/04/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 33,
				TaskName: "Internal testing and feedback",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Progress: 45,
				Predecessor: "31",
			},
			{
				TaskID: 34,
				TaskName: "Customer testing and feedback",
				StartDate: new Date("04/04/2024"),
				Duration: 3,
				Progress: 50,
				Predecessor: "33",
			},
		],
	},
	{
		TaskID: 35,
		TaskName: "Final product development",
		StartDate: new Date("04/04/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 36,
				TaskName: "Important improvements",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Progress: 30,
				Predecessor: "34",
			},
			{
				TaskID: 37,
				TaskName: "Address any unforeseen issues",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Progress: 30,
				Predecessor: "36ss",
				Indicators: [
					{
						date: "06/21/2024",
						name: "Sales and marketing",
						tooltip: "Sales and marketing",
						iconClass: "description e-icons",
					},
				],
			},
		],
	},
	{
		TaskID: 38,
		TaskName: "Final product",
		StartDate: new Date("04/04/2024"),
		EndDate: new Date("04/21/2024"),
		subtasks: [
			{
				TaskID: 39,
				TaskName: "Branding product",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Predecessor: "37",
			},
			{
				TaskID: 40,
				TaskName: "Marketing and presales",
				StartDate: new Date("04/04/2024"),
				Duration: 4,
				Progress: 30,
				Predecessor: "39",
			},
		],
	},
];

//data for the library gantt-task-react

const currentDate = new Date();

export const projectsData = [
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Mobile Development Project",
		id: "project1",
		progress: 80,
		type: "project",
		hideChildren: false,
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Web Development Project",
		id: "project1",
		progress: 30,
		type: "project",
		hideChildren: false,
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Back end Development Project",
		id: "project1",
		progress: 30,
		type: "project",
		hideChildren: false,
	},
];

export const projectData = [
	// Project 1: Web Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Web Development Project",
		id: "project1",
		progress: 30,
		type: "project",
		hideChildren: false,
	},
	// Job 1.1: Frontend Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 15),
		name: "Frontend Development",
		id: "job1.1",
		progress: 40,
		type: "job",
		project: "project1",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 7),
		name: "Design UI/UX",
		id: "task1.1.1",
		progress: 100,
		type: "task",
		project: "project1",
		job: "job1.1",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 21),
		name: "Implement React Components",
		id: "task1.1.2",
		progress: 70,
		type: "task",
		project: "project1",
		job: "job1.1",
		// dependencies: ["task1.1.1"],
	},
	// Job 1.2: Backend Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Backend Development",
		id: "job1.2",
		progress: 20,
		type: "job",
		project: "project1",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 25),
		name: "Set up Database",
		id: "task1.2.1",
		progress: 100,
		type: "task",
		project: "project1",
		job: "job1.2",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 26),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Develop API",
		id: "task1.2.2",
		progress: 30,
		type: "task",
		project: "project1",
		job: "job1.2",
		// dependencies: ["task1.2.1"],
	},

	// Project 2: Mobile App Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0),
		name: "Mobile App Development",
		id: "project2",
		progress: 10,
		type: "project",
		hideChildren: false,
	},
	// Job 2.1: iOS Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 15),
		name: "iOS Development",
		id: "job2.1",
		progress: 15,
		type: "job",
		project: "project2",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 15),
		name: "Design iOS UI",
		id: "task2.1.1",
		progress: 80,
		type: "task",
		project: "project2",
		job: "job2.1",
	},
	// Job 2.2: Android Development
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 16),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0),
		name: "Android Development",
		id: "job2.2",
		progress: 5,
		type: "job",
		project: "project2",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 16),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Design Android UI",
		id: "task2.2.1",
		progress: 40,
		type: "task",
		project: "project2",
		job: "job2.2",
	},

	// Project 3: Data Analysis
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 15),
		name: "Data Analysis Project",
		id: "project3",
		progress: 5,
		type: "project",
		hideChildren: false,
	},
	// Job 3.1: Data Collection
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 15),
		name: "Data Collection",
		id: "job3.1",
		progress: 30,
		type: "job",
		project: "project3",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 1),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 7),
		name: "Define Data Sources",
		id: "task3.1.1",
		progress: 100,
		type: "task",
		project: "project3",
		job: "job3.1",
	},
	// Job 3.2: Data Processing
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 16),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 15),
		name: "Data Processing",
		id: "job3.2",
		progress: 0,
		type: "job",
		project: "project3",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 16),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0),
		name: "Clean and Preprocess Data",
		id: "task3.2.1",
		progress: 0,
		type: "task",
		project: "project3",
		job: "job3.2",
		dependencies: ["task3.1.1"],
	},

	// Milestones
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0),
		name: "Web App Launch",
		id: "milestone1",
		progress: 0,
		type: "milestone",
		project: "project1",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0),
		name: "Mobile App Beta Release",
		id: "milestone2",
		progress: 0,
		type: "milestone",
		project: "project2",
	},
	{
		start: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 15),
		end: new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 15),
		name: "Data Analysis Report Due",
		id: "milestone3",
		progress: 0,
		type: "milestone",
		project: "project3",
	},
];
