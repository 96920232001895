import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUpdateJob, jobDelete, jobGetAll } from "actions/jobActions";
import { getJobsList } from "selectors/jobSelectors";
import { IconButton, Typography } from "@mui/material";
import DataGridTable from "components/Table/DataGrid";
import AddEditJob from "pages/job/AddNewJob";
import AddEditTask from "pages/task/AddNewTask";
import get from "lodash/get";
import { jobListcolumns } from "pages/job/addJobUtils";
import NoResultsFound from "components/NoResults/NoResultsFound";
import { ViewJob } from "routes/loadables";
import AppRoleContext from "context/AppRoleContext";
import { ModelDialog } from "components/Model";
import { CloseOutlined } from "@mui/icons-material";
import { ManageJobUsers } from "./ManageJobUsers";
import JobTeamList from "./JobTeamList";
import HiddenOn from "components/Wrappers/HiddenOn";
import { isJobManager, isProjectManager } from "core/utils/roleUtils";

export const ProjectJobsList = ({
  projectId,
  selectedJob,
  setSelectedJob,
  projectData,
}) => {
  const dispatch = useDispatch();
  const appRoleDetails = useContext(AppRoleContext);
  const projectUserRole = get(projectData, "projUserRole", {});

  const { jobsList, totalCount } = useSelector(getJobsList);

  const [addNewJob, setAddNewJob] = useState(false);

  const [pageModel, setPageModel] = React.useState({ page: 0, pageSize: 10 });
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [editJob, setEditJob] = useState(false);
  const [addTask, setAddTask] = useState(false);

  const [viewJob, setViewJob] = useState(null);

  const [userManagement, setUserManagement] = useState(false);
  const [jobActionsContext, setJobActionsContext] = useState(null);

  useEffect(() => {
    dispatch(jobGetAll({ ...pageModel, projectId: projectId }));
  }, [dispatch, pageModel, projectId]);

  if (addTask) {
    return (
      <AddEditTask
        onCancel={() => setAddTask(false)}
        parentId={{ jobId: selectedJob.id }}
      />
    );
  }

  if (viewJob && viewJob.jobId) {
    return (
      <ViewJob
        selectedJob={viewJob}
        jobId={viewJob.jobId}
        projectId={projectId}
        onJobCancel={() => {
          setViewJob(null);
        }}
      />
    );
  }

  return (
    <div className="jobs-page">
      <ModelDialog
        fullWidth
        open={userManagement}
        handleClose={() => {
          setUserManagement(false);
        }}
        dialogTitle={
          <div className="d-flex justify-content-between">
            <Typography className="h4 mb-4">{"Assign Job"}</Typography>
            <IconButton
              onClick={() => {
                setUserManagement(false);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        }
        dialogContent={
          <>
            <ManageJobUsers
              jobRefId={jobActionsContext}
              projectId={projectId}
            />
            <JobTeamList projectRefId={jobActionsContext} title={" "} />
          </>
        }
      />
      {addNewJob ? (
        <AddEditJob
          onCancel={() => setAddNewJob(false)}
          jobId={editJob}
          selectedJob={selectedJob}
          projectId={{ projectId }}
          title={get(selectedJob, "data.jobName", null)}
          propData={{ ...pageModel, projectId: projectId }}
        />
      ) : (
        <>
          <div className="projects-header-section">
            <div>
              <Typography className="h1">Jobs</Typography>
            </div>
          </div>
          <div className="projects-content-section">
            {totalCount > 0 ? (
              <DataGridTable
                pagination={totalCount > 10}
                totalCount={totalCount}
                columns={jobListcolumns({
                  setEditJob,
                  setAddTask,
                  setAddNewJob,
                  setSelectedJob,
                  onJobDelete: (jobId) => {
                    dispatch(jobDelete({ jobId, params: pageModel }));
                  },
                  appRoleDetails,
                  projectData,
                  setUserManagement,
                  setJobActionsContext,
                  updateStatus: (status, job) => {
                    dispatch(
                      addUpdateJob({
                        formData: { ...job, status },
                        payload: { ...pageModel, projectId },
                      })
                    );
                  },
                })}
                data={jobsList}
                defineRowId={(row) => {
                  return row.jobId;
                }}
                paginationModel={pageModel}
                onPaginationChange={(props) => {
                  setPageModel(props);
                }}
                onSelect={(props) => {
                  setSelectedRows(props);
                }}
                selectedRows={selectedRows}
                onDeleteSelection={() => {
                  console.log("Delete Selection : ", selectedRows);
                }}
                onRowClick={(row) => {
                  // navigate(`/jobs/view/${row.id}`);
                  setViewJob(row.row);
                }}
              />
            ) : (
              <NoResultsFound />
            )}
          </div>
        </>
      )}
      <HiddenOn isHidden={!isJobManager(appRoleDetails, projectUserRole)}>
        {!addNewJob && (
          <div className="page-footer  fixed-footer align-content-center">
            <div className="d-flex justify-content-end ">
              <button
                className="primaryButton addProjectButton"
                style={{ width: "8rem" }}
                onClick={() => {
                  setAddNewJob(!addNewJob);
                }}
              >
                {"+ Add Job"}
              </button>
            </div>
          </div>
        )}
      </HiddenOn>
    </div>
  );
};

export default ProjectJobsList;
