import * as yup from "yup";
import { getText } from "core/utils/getContentText";
import { InputAdornment, TextField, Typography } from "@mui/material";
import {
  AssignmentOutlined,
  Delete,
  EditOutlined,
  VerifiedUser,
} from "@mui/icons-material";
import BurgerMenu from "components/Menu/BurgerMenu";
import moment from "moment";
import { MenuItem } from "@mui/material";
import HiddenOn from "components/Wrappers/HiddenOn";
import { isJobManager, isProjectManager } from "core/utils/roleUtils";
import get from "lodash/get";

const getTextContent = (key) => getText(`jobs.addJobs.${key}`);

export const jobStatusOptions = [
  "Planning",
  "Pending",
  "In Progress",
  "Completed",
];

const addJobValidationSchema = yup.object({
  jobName: yup.string("Enter Job Name").required("Job Name is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  plannedStartDate: yup
    .string("Enter Planned Start Date")
    .required("Start Date is required"),
  plannedEndDate: yup
    .string("Enter Planned End Date")
    .required("End is required"),
  // actualStartDate: yup.string("Enter Actual Start Date"),
  // actualEndDate: yup.string("Enter Actual End Date"),
  assignedTeam: yup
    .string("Enter Assigned Team")
    .required("Assigned Team is required"),
  status: yup.string("Enter Status").required("Status is required"),
  priority: yup.string("Enter Priority").required("Priority is required"),

  // budget: yup
  // 	.number("Enter total Budget")
  // 	.required("budget count is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("budget count must be an integer"),

  // costToDate: yup
  // 	.number("Enter cost till now")
  // 	.required("Cost is required")
  // 	.typeError("Please enter a valid number")
  // 	.integer("Cost must be an integer"),
});
export const addJobForm = {
  steps: Array.from({ length: 1 }, (_, index) =>
    getText(`profile.contractor.step${index + 1}`)
  ),
  stepDescription: {
    step0: {
      subHeading:
        "Please enter all the details of the job that are required to be filled.",
    },
  },
  formFields: {
    step0: [
      {
        key: "jobName",
        placeholder: "Job Name",
        label: getTextContent("jobName"),
        type: "text",
        required: true,
        width: "100%",
      },
      {
        key: "description",
        placeholder: getTextContent("description"),
        label: getTextContent("description"),
        type: "textArea",
        required: true,
        width: "100%",
      },
      {
        key: "plannedStartDate",
        placeholder: getTextContent("plannedStartDate"),
        label: getTextContent("plannedStartDate"),
        type: "dateField",
        required: true,
        width: "50%",
      },
      {
        key: "plannedEndDate",
        placeholder: getTextContent("plannedEndDate"),
        label: getTextContent("plannedEndDate"),
        type: "dateField",
        required: true,
        width: "50%",
      },
      {
        key: "actualStartDate",
        placeholder: getTextContent("actualStartDate"),
        label: getTextContent("actualStartDate"),
        type: "dateField",
        // required: true,
        width: "50%",
      },
      {
        key: "actualEndDate",
        placeholder: getTextContent("actualEndDate"),
        label: getTextContent("actualEndDate"),
        type: "dateField",
        // required: true,
        width: "50%",
      },

      {
        key: "assignedTeam",
        placeholder: "Assigned Team",
        label: getTextContent("assignedTeam"),
        type: "select",
        width: "50%",
        select: true,
        required: true,
        options: [
          "Plumbing",
          "Electrician",
          "Painter",
          "Landscaping",
          "Roofing",
          "General Contractor",
          "Architect",
          "Interior Designer",
          "Exterior Designer",
          "Flooring",
          "Windows & Doors",
          "Concrete",
          "Demolition",
          "Excavation",
          "Foundation",
        ],
      },
      {
        key: "dependencies",
        placeholder: "Enter Dependencies",
        label: getTextContent("dependencies"),
        type: "text",
        width: "50%",
        extraInputProps: {
          multiline: true,
        },
      },

      {
        key: "status",
        placeholder: "Status of the Job",
        label: getTextContent("status"),
        type: "select",
        select: true,
        required: true,
        options: jobStatusOptions,
        width: "50%",
        value: "Planning",
      },
      {
        key: "priority",
        placeholder: "Priority if Job",
        label: getTextContent("priority"),
        type: "select",
        select: true,
        required: true,
        options: [
          { key: 0, text: "Low" },
          { key: 1, text: "Medium" },
          { key: 2, text: "High" },
        ],
        width: "50%",
      },
      {
        key: "budget",
        placeholder: "Enter Budget for Job",
        label: getTextContent("budget"),
        type: "text",
        width: "50%",
        formatter: (value) => parseInt(value),
        extraInputProps: {
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">{"$"}</InputAdornment>
            ),
          },
        },
      },
      {
        key: "costToDate",
        placeholder: "Enter Cost till Date",
        label: getTextContent("costToDate"),
        type: "text",
        width: "50%",
        formatter: (value) => {
          parseInt(value);
        },
        extraInputProps: {
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">{"$"}</InputAdornment>
            ),
          },
        },
      },
      {
        key: "resourcesNeeded",
        placeholder: "Resources Needed",
        label: getTextContent("resourcesNeeded"),
        type: "text",
        width: "100%",
        select: true,
        required: true,
        options: [
          { key: 0, text: "No" },
          { key: 1, text: "Yes" },
        ],
      },
      {
        key: "notes",
        placeholder: "Enter Notes",
        label: getTextContent("notes"),
        type: "text",
        width: "100%",
        extraInputProps: {
          multiline: true,
        },
      },
    ],
  },
  validationSchema: addJobValidationSchema,
  defaultState: (defaultState) => {
    if (defaultState) {
      return defaultState;
    } else {
      const dynamicInitialState = Object.fromEntries(
        Object.keys(addJobValidationSchema.fields).map((key) => [key, ""])
      );

      return {
        ...dynamicInitialState,
        costToDate: "0",
      };
    }
  },
};

export const priority = {
  0: "Low Priority",
  1: "Medium Priority",
  2: "High Priority",
};
export const jobListcolumns = (props = {}) => [
  { field: "jobName", headerName: "Job Name", width: 200 },
  {
    field: "priority",
    headerName: "Priority",
    width: 100,
    valueFormatter: (value) => priority[value],
  },
  {
    field: "budget",
    headerName: "Budget",
  },

  {
    field: "planningStartDate",
    headerName: "Plan Date",

    valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    field: "planningEndDate",
    headerName: "End Date",

    valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
  },
  // {
  // 	field: "actualEndDate",
  // 	headerName: "Actual End Date",

  // 	valueFormatter: (value) => moment(value).format("DD/MM/YYYY"),
  // },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    resizable: false,
    width: 150,
    renderCell: (rowData) => {
      return rowData.row.status === "Completed" ? (
        rowData.row.status
      ) : (
        <TextField
          className="form-field update-status-field"
          select
          value={rowData.row.status}
          onChange={(e) => {
            props.updateStatus(e.target.value, rowData.row);
          }}
        >
          {jobStatusOptions.map((option) => (
            <MenuItem value={option}>
              <Typography>{option}</Typography>
            </MenuItem>
          ))}
        </TextField>
      );
    },
  },
  {
    field: "assignedTo",
    headerName: "Assigned To",
    sortable: false,
    resizable: false,
    width: 200,
    renderCell: (rowData) => {
      return get(rowData, "row.jobUser.user.userName", "") === ""
        ? get(rowData, "row.jobUser.user.firstName", "") +
            " " +
            get(rowData, "row.jobUser.user.lastName", "")
        : get(rowData, "row.jobUser.user.userName", "");
    },
  },
  {
    field: "asigneeType",
    headerName: "Asignee Type",
    sortable: false,
    resizable: false,
    width: 200,
    renderCell: (rowData) => {
      return get(rowData, "row.jobUser.user.userType", "");
    },
  },
  {
    field: "company",
    headerName: "Company",
    sortable: false,
    resizable: false,
    width: 170,
    renderCell: (rowData) => {
      return get(rowData, "row.jobUser.user.userType", "") === "supplier"
        ? get(rowData, "row.jobUser.company.companyName", "")
        : "-";
    },
  },
  {
    field: "actions",
    type: "actions",
    flex: 1,
    align: "right",
    getActions: (params) => [
      <BurgerMenu>
        <MenuItem
          onClick={() => {
            console.log("Check the Params : ", params);
            props.setEditJob(true);
            props.setAddNewJob(true);
            props.setSelectedJob({ id: params.id, data: params.row });
          }}
        >
          <EditOutlined /> <p className="m-0">Edit Job</p>
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.setAddTask(true);
            props.setSelectedJob({
              id: params.id,
              data: params.row,
            });
          }}
        >
          <AssignmentOutlined /> <p className="m-0">Add Task</p>
        </MenuItem>
        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.setUserManagement(true);
              props.setJobActionsContext(params.id);
            }}
          >
            <VerifiedUser />
            Assign Job
          </MenuItem>
        </HiddenOn>
        <HiddenOn
          isHidden={!isJobManager(props.appRoleDetails, props?.projectData)}
        >
          <MenuItem
            onClick={() => {
              props.onJobDelete(params.id);
            }}
          >
            <Delete /> <p className="m-0">Delete Job</p>
          </MenuItem>
        </HiddenOn>
      </BurgerMenu>,
    ],
  },
];
