import React from "react";
import { Carousel } from "react-bootstrap";
import { loginSliderImages } from "assets/loginRegistration/advImages";

const ImageSlider = () => {
	return (
		<>
			<div style={{ width: "100%" }}>
				<Carousel interval={2000}>
					{loginSliderImages.map((item) => {
						return (
							<Carousel.Item>
								<div
									style={{
										position: "relative",
										backgroundImage: `url(${item})`,
									}}
									className="image-div"
									onClick={(e) => {
										e.stopPropagation();
									}}
								>
									<img
										src={item}
										alt="slide"
										style={{ width: "100%", height: "100vh" }}
									/>
								</div>
							</Carousel.Item>
						);
					})}
				</Carousel>
			</div>
		</>
	);
};

export default ImageSlider;
