import React from "react";
// import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import appleLogo from "assets/loginRegistration/appleLogo.png";
// import { activateUserSignIn } from "core/utils/appUtils";
import { Button } from "@mui/material";

export const AppleLogin = (props) => {
	// const { setModalOpen, setOpen } = props;

	// const auth = getAuth();
	// const provider = new GoogleAuthProvider();

	return (
		<>
			<div>
				<Button
					// variant="outlined"
					className="primaryButton hoverEffect social-btn"
					// onClick={() => {
					// 	signInWithPopup(auth, provider)
					// 		.then((result) => {
					// 			activateUserSignIn(
					// 				result,
					// 				GoogleAuthProvider.credentialFromResult(result)
					// 			);
					// 			// onSuccessRedirect();
					// 			setModalOpen(false);
					// 			setOpen(false);
					// 		})
					// 		.catch((error) => {
					// 			// Handle Errors here.
					// 			// const errorCode = error.code;
					// 			// const errorMessage = error.message;
					// 			// // The email of the user's account used.
					// 			// const email = error.customData.email;
					// 			// // The AuthCredential type that was used.
					// 			// const credential =
					// 			//   GoogleAuthProvider.credentialFromError(error);
					// 			// ...
					// 		});
					// }}
				>
					<span className="social-logo">
						<AppleSignIn />
					</span>
					<span className="social-text">Sign in with Apple</span>
				</Button>
			</div>
		</>
	);
};

export const AppleSignIn = () => {
	return (
		<>
			<div className="social-logo">
				<img src={appleLogo} alt={"appleLogo"} />
			</div>
		</>
	);
};

export default AppleLogin;
