import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.company;

export const companiesLoader = createSelector(selectState, (companies) =>
	get(companies, "loading", false)
);

export const companiesSelector = createSelector(selectState, (companies) => ({
	connected: get(companies, "connections", []),
	newConnections: get(companies, "newConnections", []),
	pendingConnections: get(companies, "pendingConnections", []),
	unfilteredCompanies: get(companies, "unfilteredCompanies", []),
}));

export const connectedCompaniesSelector = createSelector(
	selectState,
	(companies) => get(companies, "connectedCompanies", [])
);

export const connectedSuppliersSelector = createSelector(
	selectState,
	(companies) => ({
		connectedSuppliers: get(companies, "connectedSuppliers", []),
		loadingSuppliers: get(companies, "loadingSuppliers", false),
	})
);
