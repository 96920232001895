import React, { useState } from "react";
import ViewJob from "pages/job/ViewJob";
import ProjectJobsList from "./jobs/ProjectJobs";

export const TaskManager = ({ projectId, projectData }) => {
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			<div>
				<>
					<ProjectJobsList
						projectId={projectId}
						selectedJob={selectedJob}
						setSelectedJob={setSelectedJob}
						projectData={projectData}
					/>
				</>
				<div className="">
					{selectedJob && selectedJob.jobId && (
						<ViewJob
							selectedJob={selectedJob}
							jobId={selectedJob.jobId}
							projectId={projectId}
							// setTaskOpen={setTaskOpen}
						/>
					)}
				</div>
			</div>{" "}
		</>
	);
};

export default TaskManager;
