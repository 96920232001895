import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.connection;

export const getConnectionsSelector = createSelector(
	selectState,
	(connections) => ({
		connectionsList: get(connections, "connectionList", []),
		loading: get(connections, "loading", false),
	})
);
