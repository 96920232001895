import React, { useState, useEffect, useCallback } from "react";
import voiceAssistantimg from "assets/app/voice-assistant.png";

const VoiceAssistant = () => {
	const [position, setPosition] = useState({
		x: window.innerWidth - 100,
		y: window.innerHeight - 100,
	});
	const [isDragging, setIsDragging] = useState(false);
	const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
	const [showMessage, setShowMessage] = useState(false);

	const handleMouseDown = (e) => {
		e.preventDefault();
		setIsDragging(true);
		setDragStart({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};

	const handleMouseMove = useCallback(
		(e) => {
			if (!isDragging) return;

			const newX = e.clientX - dragStart.x;
			const newY = e.clientY - dragStart.y;

			const maxX = window.innerWidth - 64;
			const maxY = window.innerHeight - 64;

			setPosition({
				x: Math.min(Math.max(0, newX), maxX),
				y: Math.min(Math.max(0, newY), maxY),
			});
		},
		[dragStart, isDragging]
	);

	const handleMouseUp = useCallback(() => {
		if (isDragging) setIsDragging(false);
	}, [isDragging]);

	const handleClick = () => {
		if (!isDragging) {
			setShowMessage(!showMessage);
		}
	};

	useEffect(() => {
		if (isDragging) {
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("mouseup", handleMouseUp);
		} else {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		}
		return () => {
			window.removeEventListener("mousemove", handleMouseMove);
			window.removeEventListener("mouseup", handleMouseUp);
		};
	}, [handleMouseMove, handleMouseUp, isDragging]);

	return (
		<div>
			<div
				className="pointer voice-assistant page-footer fixed-footer align-content-center"
				style={{
					position: "absolute",
					left: position.x,
					top: position.y,
					zIndex: "1500",
				}}
			>
				{showMessage && (
					<div
						className="position-absolute bg-warning rounded p-3 shadow"
						style={{
							bottom: "80px",
							// right: "auto",
							left: "-15rem",
							width: "300px",
						}}
					>
						<div className="d-flex justify-content-between align-items-start">
							<div className="pe-2">
								I'm your voice assistant, to Activate me Say "Hello Lee"
							</div>
							<button
								className="btn-close"
								onClick={() => setShowMessage(false)}
								aria-label="Close"
							></button>
						</div>
					</div>
				)}
				<div
					style={{
						cursor: isDragging ? "grabbing" : "grab",
					}}
					onMouseDown={handleMouseDown}
					onClick={handleClick}
				>
					<img
						src={voiceAssistantimg}
						alt="voice assistant"
						style={{ width: "64px", height: "64px" }}
					/>
				</div>
			</div>
		</div>
	);
};

export default VoiceAssistant;
