import React from "react";
import "./statusIndicators.scss";

const getStatusColor = (status) => {
	switch (status) {
		case "active":
			return "#36B37E";
		case "inactive":
			return "gray";
		case "scrutiny":
			return "#FF5630";
		case "rejected":
			return "red";
		default:
			return "bg-gray-300";
	}
};

const StatusIndicator = ({
	color,
	status,
	width,
	height,
	opacity,
	...rest
}) => {
	return (
		<div {...rest}>
			<svg
				viewBox="0 0 30 30"
				width={width || "15px"}
				height={height || "15px"}
			>
				<circle
					cx="15"
					cy="15"
					r="15"
					fill={color || getStatusColor(color || status)}
					opacity={opacity || 1}
				/>
			</svg>
		</div>
	);
};

export default StatusIndicator;
