import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./properties.scss";
import { useFormik } from "formik";
import {
	TextField,
	MenuItem,
	Box,
	Chip,
	Typography,
	IconButton,
	FormHelperText,
} from "@mui/material";
import { addPropertyForm } from "./addPropertyUtils";
import pick from "lodash/pick";
import keys from "lodash/keys";
import get from "lodash/get";
import { LocationField } from "components/Location/GeoLocation";
import { addNewProperty } from "actions/propertyActions";
import { FormLabel } from "components/TextFields/FormInputField";
import { CloseOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getUserProfileDetails } from "selectors/appSelector";

export const AddNewProperty = ({
	onCancel = () => {
		window.history.back();
	},
	propertyId,
	selectedProperty,
	title,
	callback = () => {},
}) => {
	const dispatch = useDispatch();
	const [activeStep, setActiveStep] = useState(0);

	const appRoleDetails = useSelector(getUserProfileDetails);

	const { steps, formFields, validationSchema, stepDescription } =
		addPropertyForm;

	const initialState = addPropertyForm.defaultState(
		get(selectedProperty, "data", null)
	);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			console.log("Check the Onsubmit : ");
		},
		initialTouched: get(selectedProperty, "data", null),
	});
	const handleNext = () => {
		const currentFields = formFields[`step${activeStep}`]
			.filter((field) => field.required)
			.map((field) => field.key);
		const currentTouchedFields = pick(formik.touched, currentFields);
		const currentErrorFields = pick(formik.errors, currentFields);

		if (activeStep + 1 < steps.length) {
			if (
				currentFields.length === keys(currentTouchedFields).length &&
				keys(currentErrorFields).length === 0
			) {
				setActiveStep(activeStep + 1);
			}
		}
		if (activeStep + 1 === steps.length) {
			if (keys(formik.errors).length === 0) {
				dispatch(
					addNewProperty(
						{ ...formik.values, companyId: appRoleDetails.companyId },
						(payload) => {
							callback(payload);
							onCancel();
						}
					)
				);
			}
		}
	};

	return (
		<div className="mt-2 p-2">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Property"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div className="form-section">
				<div>
					<Typography variant="subtitle1" className="text-left m-1">
						{`${activeStep + 1}. ${
							stepDescription[`step${activeStep}`].heading
						}`}
					</Typography>
					<div>
						<p className="m-1">
							{stepDescription[`step${activeStep}`].subHeading}
						</p>
					</div>
				</div>
				<div>
					<form onSubmit={formik.handleSubmit}>
						<div className="stepContent container row">
							{formFields[`step${activeStep}`].map((field, index) => {
								const extraInputProps = get(field, "extraInputProps", {});

								const extraProps =
									typeof extraInputProps === "function"
										? extraInputProps(formik)
										: extraInputProps;
								if (
									field.renderCondition &&
									!field.renderCondition(formik.values["propertyType"])
								) {
									return null;
								}
								return (
									<div
										className={`${field.className} mt-3`}
										style={{ width: field.width }}
									>
										<FormLabel text={field.label} required={field.required} />
										{field.key === "address" ? (
											<div className="google-location-field">
												<LocationField
													defaultAddress={formik.values.address}
													onSelect={(payload) => {
														const addressObj = payload.addressObj;
														formik.setFieldValue(
															"propertyLocation",
															payload.locationUrl
														);
														formik.setFieldValue("address", payload.address);
														formik.setFieldTouched("address", true, false);
														formik.setFieldValue(
															"latitude",
															payload.latLng.lat
														);
														formik.setFieldValue(
															"longitude",
															payload.latLng.lng
														);
														for (let key in addressObj) {
															formik.setFieldValue(key, addressObj[key]);
															if (addressObj[key] && addressObj[key] !== "") {
																formik.setFieldTouched(key, true, false);
															}
														}
														// formik.setFieldValue("locationUrl", payload.locationUrl);
													}}
												/>
												{formik.errors["address"] && (
													<FormHelperText
														style={{ fontSize: "12px" }}
														error
														text={formik.errors["propertyLocation"]}
													>
														{formik.errors["propertyLocation"]}
													</FormHelperText>
												)}
											</div>
										) : (
											<TextField
												key={field.key}
												id={field.key}
												type={field.type}
												select={field.select}
												name={field.key}
												fullWidth
												variant="outlined"
												placeholder={field.placeholder}
												className={`registration-formField `}
												value={formik.values[field.key]}
												disabled={field.disabled}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[field.key] &&
													Boolean(formik.errors[field.key])
												}
												helperText={
													formik.touched[field.key] && formik.errors[field.key]
												}
												SelectProps={{
													displayEmpty: true,
													multiple: field.multiple,
													renderValue: (selected) => {
														if (field.multiple) {
															if (selected && selected.length === 0) {
																return <em>{field.placeholder}</em>;
															}
															return (
																<Box
																	sx={{
																		display: "flex",
																		flexWrap: "wrap",
																		gap: 0.5,
																		marginTop: "-0.75rem",
																	}}
																>
																	{selected &&
																		selected.length > 0 &&
																		selected.map((value) => (
																			<Chip
																				key={value}
																				label={value}
																				style={{
																					fontSize: "12px",
																				}}
																				variant="outlined"
																				onDelete={() => {
																					formik.setFieldValue(
																						field.key,
																						selected.filter(
																							(item) => item !== value
																						)
																					);
																				}}
																			/>
																		))}
																</Box>
															);
														} else {
															return <Typography>{selected}</Typography>;
														}
													},
												}}
												{...extraProps}
											>
												<MenuItem value={""} disabled>
													{field.placeholder}
												</MenuItem>
												{field.options &&
													field.options.map((option) => {
														return <MenuItem value={option}>{option}</MenuItem>;
													})}
											</TextField>
										)}
									</div>
								);
							})}
							<div style={{ textAlign: "right" }}>
								<div className="d-flex justify-content-between">
									<div>
										{activeStep === 0 ? (
											<button
												type="submit"
												className={`save-details-button m-4 outlinedButton`}
												onClick={() => {
													onCancel();
												}}
											>
												{"Cancel"}
											</button>
										) : (
											<button
												type="submit"
												className={`save-details-button m-4 primaryButton`}
												onClick={() => {
													setActiveStep(activeStep - 1);
												}}
											>
												{"< Previous"}
											</button>
										)}
									</div>
									<div className="float-right">
										<button
											type="submit"
											className={`save-details-button m-4 primaryButton`}
											onClick={() => {
												handleNext();
											}}
										>
											{activeStep + 1 === steps.length
												? "Save"
												: selectedProperty?.data?.propertyId
												? "Save & Next"
												: "Next >"}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddNewProperty;
