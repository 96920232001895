import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "styles/app.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
// import { StylesProvider, ThemeProvider } from "@mui/styles";

import Themes from "./themes";
import App from "./components/App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import {
	StyledEngineProvider,
	// StylesProvider,
	ThemeProvider,
} from "@mui/material/styles";
import store from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "core/appSecrets";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "pages/login/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.createRoot(document.getElementById("root")).render(
	<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
		<MsalProvider instance={msalInstance}>
			<Provider store={store}>
				<LayoutProvider>
					<UserProvider>
						<StyledEngineProvider injectFirst>
							{/* <StylesProvider injectFirst> */}
							<ThemeProvider theme={Themes.default}>
								<App />
							</ThemeProvider>
							{/* </StylesProvider> */}
						</StyledEngineProvider>
					</UserProvider>
				</LayoutProvider>
			</Provider>
		</MsalProvider>
	</GoogleOAuthProvider>
);
