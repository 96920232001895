export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_NEW_PROJECT_SUCCESS = "ADD_NEW_PROJECT_SUCCESS";
export const ADD_NEW_PROJECT_ERROR = "ADD_NEW_PROJECT_ERROR";

export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";

export const GET_PROJECT_BY_ID = "GET_PROJECT_BY_ID";
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const GET_PROJECT_BY_ID_ERROR = "GET_PROJECT_BY_ID_ERROR";

export const DELETE_PROJECT_BY_ID = "DELETE_PROJECT_BY_ID";
export const DELETE_PROJECT_BY_ID_SUCCESS = "DELETE_PROJECT_BY_ID_SUCCESS";
export const DELETE_PROJECT_BY_ID_ERROR = "DELETE_PROJECT_BY_ID_ERROR";

export const GET_TIMELINES_BY_PROJECT_ID = "GET_TIMELINES_BY_PROJECT_ID";
export const GET_TIMELINES_BY_PROJECT_ID_SUCCESS =
	"GET_TIMELINES_BY_PROJECT_ID_SUCCESS";
export const GET_TIMELINES_BY_PROJECT_ID_ERROR =
	"GET_TIMELINES_BY_PROJECT_ID_ERROR";

export const UPDATE_PROJECT_USERS_ROLES = "UPDATE_PROJECT_USERS_ROLES";
export const UPDATE_PROJECT_USERS_ROLES_SUCCESS =
	"UPDATE_PROJECT_USERS_ROLES_SUCCESS";
export const UPDATE_PROJECT_USERS_ROLES_ERROR =
	"UPDATE_PROJECT_USERS_ROLES_ERROR";

export const UPDATE_TASK_USERS_ROLES = "UPDATE_TASK_USERS_ROLES";
export const UPDATE_TASK_USERS_ROLES_SUCCESS =
	"UPDATE_TASK_USERS_ROLES_SUCCESS";
export const UPDATE_TASK_USERS_ROLES_ERROR = "UPDATE_TASK_USERS_ROLES_ERROR";

export const GET_TIMELINES = "GET_TIMELINES";
export const GET_TIMELINES_SUCCESS = "GET_TIMELINES_SUCCESS";
export const GET_TIMELINES_ERROR = "GET_TIMELINES_ERROR";
