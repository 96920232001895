import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Typography, IconButton, MenuItem } from "@mui/material";
import { addProjectForm, addProjectFields } from "./addProjectUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import "./projects.scss";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import DatePickerField from "components/DateComponets/DatePicker";
import { addUpdateProject } from "actions/projectActions";
import FileUpload from "components/upload/FileUpload";
import { getProperties } from "selectors/propertySelectors";
import AddNewProperty from "pages/property/AddNewProperty";
import { getUserProfileDetails } from "selectors/appSelector";
import { fetchUsers } from "actions/userActions";
import dayjs from "dayjs";

export const AddNewProject = ({
	propertyId,
	onCancel,
	projectId,
	selectedProject = {},
	title,
	fromProperty,
}) => {
	console.log("Check the property ", propertyId, selectedProject);
	const dispatch = useDispatch();
	const { propertiesList } = useSelector(getProperties);
	const appRoleDetails = useSelector(getUserProfileDetails);

	const [selectedLogo, setSelectedLogo] = useState(null);
	const [selectedPhotos, setSelectedPhotos] = useState(null);

	const { validationSchema } = addProjectForm;
	const initialState = addProjectForm.defaultState(
		get(selectedProject, "data", null)
	);
	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(
				addUpdateProject({
					formData: {
						...formik.values,
						companyId: appRoleDetails.companyId,
					},
					onSuccess: onCancel,
					params: { propertyId },
				})
			);
		},
	});

	const removeFile = (index) => {
		setSelectedPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
	};

	const handlePropertyChange = (e) => {
		const filteredProperties = propertiesList.filter(
			(property) => property.propertyId === e.target.value
		)[0];
		formik.setFieldValue("mobileNumber", appRoleDetails.mobileNumber);
		formik.setFieldValue("customerName", filteredProperties.homeOwnerName);
		formik.setFieldValue("homeownerMobile", filteredProperties.mobileNumber);
		formik.setFieldValue("propertyId", e.target.value);
	};
	const [showAddLocation, setShowAddLocation] = useState(false);

	return (
		<div className="mt-0 p-1" style={{ position: "relative" }}>
			{showAddLocation ? (
				<AddNewProperty
					onCancel={() => {
						setShowAddLocation(false);
					}}
					callback={(payload) => {
						formik.setFieldValue("propertyId", payload.propertyId);
						formik.setFieldTouched("propertyId", true);
					}}
				/>
			) : (
				<>
					<div className="d-flex justify-content-between">
						<Typography className="text-left m-2 h1" id="header">
							{title || "Create New Project"}
						</Typography>
						<IconButton onClick={() => onCancel()}>
							<CloseOutlined />
						</IconButton>
					</div>
					<div id="create-project" className="mt-5">
						<form onSubmit={formik.handleSubmit}>
							<div id="container row">
								<div id="section-1 mt-3">
									<div id="section-header">
										<Typography className="text-left m-2 h2">
											Project Information
										</Typography>
									</div>
									{!fromProperty && (
										<div className="container row">
											<FormInputField
												disabled={fromProperty}
												field={{
													key: "propertyId",
													label: "Property",
													placeholder: "Select Property",
													type: "select",
													select: true,
													required: true,
													width: "100%",
													options: propertiesList,
												}}
												renderOptions={() => {
													return propertiesList.map((property) => {
														return (
															<MenuItem
																key={property.propertyId}
																value={property.propertyId}
															>
																{property.propertyName}
															</MenuItem>
														);
													});
												}}
												formik={formik}
												value={formik.values["propertyId"]}
												key={"propertyId"}
												onChange={handlePropertyChange}
												SelectProps={{
													renderValue: (selected) => {
														return (
															<Typography>
																{
																	propertiesList.find(
																		(option) => option.propertyId === selected
																	)?.propertyName
																}
															</Typography>
														);
													},
												}}
											/>
											{/* <div
												style={{ width: "30%" }}
												className="mt-4 align-content-center"
											>
												<button
													className="primaryButton align-self-center mt-4"
													onClick={() => setShowAddLocation(true)}
													style={{ width: "100%" }}
												>
													Add New Property
												</button>
												{formik.touched["propertyId"] &&
													formik.errors["propertyId"] && (
														<p className="MuiFormHelperText-root"></p>
													)}
											</div> */}
										</div>
									)}
									<div className="d-flex">
										<div className="form-fields col-12 col-md-7">
											<div className="container row justify-content-between">
												{addProjectFields.propertyInformation.map((field) => {
													const extraInputProps = get(
														field,
														"extraInputProps",
														{}
													);

													const extraProps =
														typeof extraInputProps === "function"
															? extraInputProps(formik)
															: extraInputProps;

													return field.type === "dateField" ? (
														<div
															className={`form-field-wrapper ${field.className}`}
															style={{ width: field.width }}
														>
															<FormLabel
																text={field.label}
																required={field.required}
															/>
															<DatePickerField
																onChange={(date) => {
																	if (
																		!date ||
																		dayjs(date).isValid() === false ||
																		dayjs(date).year() === 1970
																	) {
																		formik.setFieldValue("myDate", null); // Explicitly set to null
																	} else {
																		formik.setFieldValue(field.key, date);
																	}
																}}
																onClear={() => {
																	console.log("Check the Top onClear");
																	formik.setFieldValue(field.key, null);
																}}
																value={formik.values[field.key] || null}
																field={field}
																slotProps={{
																	field: {
																		clearable: !field.required,
																		onClear: () => {
																			console.log("Check the onClear");
																			formik.setFieldValue(field.key, null);
																		},
																	},
																}}
																{...extraProps}
															/>
														</div>
													) : (
														<FormInputField
															field={field}
															formik={formik}
															value={formik.values[field.key]}
															key={field.key}
															id={field.id}
															{...extraProps}
														/>
													);
												})}
											</div>
										</div>
										<div className="media-upload col-12 col-md-5">
											<div className="form-field-wrapper project-logo-upload">
												<label className="form-label">Project Logo</label>
												<div className="project-logo-upload-area">
													<FileUpload
														multiple={false}
														accept={{
															"image/*": [],
														}}
														setFiles={(files) => {
															console.log("Files Uploaded : ", files);
															setSelectedLogo(files[0]);
														}}
														children={
															<div className="text-center upload-dropZone-text">
																Upload Project Logo
															</div>
														}
														className={
															"project-logo-upload-area align-content-center"
														}
														dropZoneProps={{ maxFiles: 1 }}
													>
														{selectedLogo && (
															<img
																style={{ width: "100%", height: "90%" }}
																src={selectedLogo.preview}
																alt="logo"
															/>
														)}
													</FileUpload>
												</div>
											</div>
											<div className="form-field-wrapper project-photos-upload">
												<label className="form-label">Project Photos</label>
												<div className="project-photos-upload-area">
													<FileUpload
														multiple={true}
														setFiles={(files) => {
															console.log(
																"Photos Uploaded : ",
																files.browsedFiles
															);
															setSelectedPhotos(files);
														}}
														accept={{
															"image/*": [],
														}}
														className={
															"project-photos-upload-area align-content-center"
														}
													>
														<div className="d-flex flex-wrap">
															{selectedPhotos &&
																selectedPhotos.length > 0 &&
																selectedPhotos.map((photo, index) => {
																	return (
																		<div
																			key={index}
																			className="project-photo d-flex flex-wrap"
																		>
																			<img
																				style={{
																					height: "50px",
																					width: "50px",
																					objectFit: "cover",
																				}}
																				src={photo.preview}
																				alt={photo.path}
																			/>
																			<button
																				className="remove-file-btn"
																				onClick={(e) => {
																					e.stopPropagation();
																					removeFile(index);
																				}}
																			>
																				×
																			</button>
																		</div>
																	);
																})}
														</div>
													</FileUpload>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="section-2" className="mt-5">
									{/* <div id="section-header" className="mt-1">
                    <Typography className="text-left m-2 h2">
                      Project Location
                    </Typography>
                  </div>
                  <div className="container row">
                    <div
                      className={`form-field-wrapper google-location-field form-field`}
                      style={{ width: "100%" }}
                    >
                      <FormLabel text="Address Location" required={true} />
                      <LocationField
                        defaultAddress={formik.values.address1}
                        onSelect={(payload) => {
                          const addressObj = payload.addressObj;
                          formik.setFieldValue(
                            "siteLocation",
                            payload.locationUrl
                          );
                          formik.setFieldValue(
                            "fullAddress",
                            payload.locationUrl
                          );
                          formik.setFieldValue("latitude", payload.latLng.lat);
                          formik.setFieldValue("longitude", payload.latLng.lng);
                          for (let key in addressObj) {
                            formik.setFieldValue(key, addressObj[key]);
                          }
                          // formik.setFieldValue("locationUrl", payload.locationUrl);
                        }}
                      />
                      {formik.errors.siteLocation && (
                        <FormHelperText required className="Mui-error">
                          {formik.errors.siteLocation}
                        </FormHelperText>
                      )}
                    </div>
                  </div> */}

									{/* <div className="form-fields col-12 col-xs-6">
                    <div className="container row justify-content-between">
                      {addProjectFields.projectLocation.map((field) => {
                        const extraProps = get(field, "extraInputProps", {});
                        return (
                          <FormInputField
                            field={field}
                            formik={formik}
                            value={formik.values[field.key]}
                            key={field.key}
                            {...extraProps}
                          />
                        );
                      })}
                    </div>
                  </div> */}
								</div>
								<div className="mt-5">
									<div className="d-flex justify-content-center align-self-center">
										<button
											type="button"
											className={`secondaryButton m-3`}
											onClick={() => {
												onCancel();
											}}
										>
											{"Cancel"}
										</button>
										<button type="submit" className={`primaryButton m-3`}>
											{"Confirm"}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</>
			)}
		</div>
	);
};

export default AddNewProject;
