import * as Icons from "@mui/icons-material";

import { systemAdminMenu } from "./components/systemAdminSidebar";
import { houseOwnerMenu } from "./components/houseownerSidebar";
import { builderAdminMenu } from "./components/builderAdminSidebar";
import { supplierAdminMenu } from "./components/supplierAdminSidebar";

const defaultMenuOptions = [
	{
		id: `sysAdmin1`,
		label: "Dashboard",
		link: "/dashboard",
		icon: <Icons.SpaceDashboardOutlined />,
	},
	{
		id: `sysAdmin14`,
		label: "Signout",
		link: "/login",
		icon: <Icons.LogoutOutlined />,
		onClick: () => {
			localStorage.removeItem("token");
		},
	},
];

export const sidebarOptions = {
	SystemUser: systemAdminMenu,
	Builder: builderAdminMenu,
	HomeOwner: houseOwnerMenu,
	Supplier: supplierAdminMenu,
	default: defaultMenuOptions,
};

export const getSidebarOptions = (userCategory = "default", roleName) => {
	return sidebarOptions[userCategory].filter((options) =>
		options.roles ? options.roles.includes(roleName) : true
	);
};
