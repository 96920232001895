import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
	root: {
		display: "flex",
		maxWidth: "100vw",
		overflowX: "hidden",
	},
	content: {
		flexGrow: 1,
		padding: () => theme.spacing(2),
		paddingTop: () => theme.spacing(3),
		// isExternalAppRoute ? 0 : theme.spacing(3),
		width: (isExternalAppRoute) => `calc(100vw - 240px)`,
		// isExternalAppRoute ? "100vw" : +`calc(100vw - 240px)`,
		minHeight: "100vh",
		overflow: "auto",
		[theme.breakpoints.down("500px")]: {
			padding: theme.spacing(2),
			width: "100vw", // Adjust width for small screens
			margin: 10,
		},
	},
	contentShift: {
		width: `calc(100vw - ${240 + theme.spacing(6)})`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	fakeToolbar: (isExternalAppRoute) => ({
		...theme.mixins.toolbar,
	}),
}));
