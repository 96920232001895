// import { Delete, EditOutlined } from "@mui/icons-material";
// import {
// 	Checkbox,
// 	Divider,
// 	FormControlLabel,
// 	FormGroup,
// 	IconButton,
// 	Typography,
// } from "@mui/material";
// import { fetchProjects } from "actions/projectActions";
// import HiddenOn from "components/Wrappers/HiddenOn";
import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Projects } from "routes/loadables";
// import { getProjects } from "selectors/projectSelector";

export const PropertyOverview = (props) => {
	// const dispatch = useDispatch();
	// const { projectsList, totalCount } = useSelector(getProjects);

	return (
		<div id="property-overview" style={{ background: "#F2F2F2" }}>
			<div
				id="projects-list"
				className="m-2 mt-0 position-relative"
				style={{ top: "1rem" }}
			>
				{/* <Typography className="h4 mt-2 mb-2">Projects</Typography> */}
				{/* <div> */}
				{/* <HiddenOn isHidden={totalCount === 0 || projectsList.length === 0}>
						<Typography className="total-count-text mt-3 mb-3">
							Displaying {projectsList.length} of {totalCount}
						</Typography>
						<Divider className="divider" />

						<div data-testid={"properties-select-div "} className="d-flex">
							<FormGroup className="ms-3" aria-disabled>
								<FormControlLabel
									disabled
									disableTypography
									control={<Checkbox />}
									label="Select All"
								/>
							</FormGroup>
							<IconButton disabled={true} onClick={() => {}}>
								<Delete
									style={{
										fontSize: "20px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Delete</strong>
								</Typography>
							</IconButton>
							<IconButton
								disabled={true}
								// onClick={() =>
								// 	navigate(`/property/edit/${selectedRecord.propertyId}`)
								// }
							>
								<EditOutlined
									style={{
										fontSize: "20px",
										marginTop: "5px",
										color: false ? "black" : "lightgray",
									}}
								/>
								<Typography variant="body1">
									<strong>Edit</strong>
								</Typography>
							</IconButton>
						</div>
					</HiddenOn> */}
				<Projects {...props} />
				{/* </div> */}
			</div>
		</div>
	);
};

export default PropertyOverview;
