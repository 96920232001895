import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	Hidden,
} from "@mui/material";
// import logo from "assets/app/logo.jpeg";
import logo from "assets/app/logo_n.png";
import {
	Menu as MenuIcon,
	NotificationsNone as NotificationsIcon,
	Person as AccountIcon,
	// Search as SearchIcon,
	ArrowBack as ArrowBackIcon,
	SettingsOutlined,
} from "@mui/icons-material";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";

// context
import {
	useLayoutState,
	useLayoutDispatch,
	toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalProjectSelector from "./GlobalProjectSelector";
import "./index.scss";

const notifications = [];

export default function Header({ userProfile }) {
	var classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	// global
	var layoutState = useLayoutState();
	var layoutDispatch = useLayoutDispatch();
	var userDispatch = useUserDispatch();

	// local
	var [notificationsMenu, setNotificationsMenu] = useState(null);
	var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
	var [profileMenu, setProfileMenu] = useState(null);
	var [isSearchOpen, setSearchOpen] = useState(false);

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar className={classes.toolbar}>
				<IconButton
					color="inherit"
					onClick={() => toggleSidebar(layoutDispatch)}
					className={classNames(
						classes.headerMenuButton,
						classes.headerMenuButtonCollapse
					)}
				>
					{layoutState.isSidebarOpened ? (
						<ArrowBackIcon
							classes={{
								root: classNames(
									classes.headerIcon,
									classes.headerIconCollapse
								),
							}}
						/>
					) : (
						<MenuIcon
							classes={{
								root: classNames(
									classes.headerIcon,
									classes.headerIconCollapse
								),
							}}
						/>
					)}
				</IconButton>
				<Hidden smDown>
					<IconButton>
						<img
							src={logo}
							alt="builder copilot"
							className="logo p-0"
							style={{ width: "7rem" }}
							onClick={() => navigate("/")}
						/>
					</IconButton>
				</Hidden>
				<div>
					{/* {!location.pathname.includes("/projects") && ( */}
					<GlobalProjectSelector />
					{/* )} */}
				</div>
				<div className={classes.grow} />
				{/* Commenting The Search Functionality, as it is not used anywere */}
				{/* <div
					className={classNames(classes.search, {
						[classes.searchFocused]: isSearchOpen,
					})}
				>
					<div
						className={classNames(classes.searchIcon, {
							[classes.searchIconOpened]: isSearchOpen,
						})}
						onClick={() => setSearchOpen(!isSearchOpen)}
					>
						<SearchIcon classes={{ root: classes.headerIcon }} />
					</div>
					<InputBase
						placeholder="Search…"
						classes={{
							root: classes.inputRoot,
							input: classes.inputInput,
						}}
					/>
				</div> */}
				{/* Commenting The Search Functionality, as it is not used anywere */}

				<Hidden smDown>
					<IconButton
						color="inherit"
						aria-haspopup="true"
						aria-controls="mail-menu"
						onClick={(e) => {
							setNotificationsMenu(e.currentTarget);
							setIsNotificationsUnread(false);
						}}
						className={classes.headerMenuButton}
					>
						<Badge
							badgeContent={isNotificationsUnread ? notifications.length : null}
							color="warning"
						>
							<SettingsOutlined classes={{ root: classes.headerIcon }} />
						</Badge>
					</IconButton>
					<IconButton
						aria-haspopup="true"
						color="inherit"
						className={classes.headerMenuButton}
						aria-controls="profile-menu"
						onClick={(e) => setProfileMenu(e.currentTarget)}
					>
						<AccountIcon classes={{ root: classes.headerIcon }} />
					</IconButton>

					<Menu
						id="notifications-menu"
						open={Boolean(notificationsMenu)}
						anchorEl={notificationsMenu}
						onClose={() => setNotificationsMenu(null)}
						className={classes.headerMenu}
						disableAutoFocusItem
					>
						{notifications.map((notification) => (
							<MenuItem
								key={notification.id}
								onClick={() => setNotificationsMenu(null)}
								className={classes.headerMenuItem}
							>
								<Notification {...notification} typographyVariant="inherit" />
							</MenuItem>
						))}
					</Menu>
					<Menu
						id="profile-menu"
						open={Boolean(profileMenu)}
						anchorEl={profileMenu}
						onClose={() => setProfileMenu(null)}
						className={classes.headerMenu}
						classes={{ paper: classes.profileMenu }}
						disableAutoFocusItem
					>
						<div className={classes.profileMenuUser}>
							<Typography variant="subtitle1" weight="medium">
								{userProfile.email}
							</Typography>
							<Typography variant="body1" weight="medium">
								User Name:
								<strong>{` ${userProfile.firstName} ${userProfile.lastName}`}</strong>
							</Typography>
						</div>
						<MenuItem
							className={classNames(
								classes.profileMenuItem,
								classes.headerMenuItem
							)}
						>
							<AccountIcon className={classes.profileMenuIcon} /> Profile
						</MenuItem>
						{/* <MenuItem
							className={classNames(
								classes.profileMenuItem,
								classes.headerMenuItem
							)}
						>
							<AccountIcon className={classes.profileMenuIcon} /> Tasks
						</MenuItem> */}
						<div
							className={classes.profileMenuUser}
							onClick={() => {
								console.log("On SignOut");
								localStorage.removeItem("token");
								navigate("/login");
							}}
						>
							<Typography className={classes.profileMenuLink} color="primary">
								Sign Out
							</Typography>
						</div>
					</Menu>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
}
