import request from "config/api";
import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  FETCH_FILES_REQUEST,
  FETCH_FILES_SUCCESS,
  FETCH_FILES_FAILURE,
} from "../constants/documentsConstants";
import { server_url } from "constants/types";

export const uploadFile = (file, label, id, identifier) => async (dispatch) => {
  dispatch({ type: UPLOAD_FILE_REQUEST });

  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    const defaultLabel = file.name.split(".")[0];
    request({
      method: "POST",
      url: `${server_url}/Documents?Label=${
        label || defaultLabel
      }&folderName=${identifier}&InstId=${id}`,
      body: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        dispatch({ type: UPLOAD_FILE_SUCCESS, payload: response });
      })
      .catch((e) => {
        dispatch({
          type: UPLOAD_FILE_FAILURE,
          error: {
            text: "Error occurred during document upload : " + e.message,
            status: "error",
            loading: false,
            showSnackbar: true,
          },
        });
        throw new Error("Failed to upload document");
      });
  } catch (error) {
    //dispatch({ type: UPLOAD_FILE_FAILURE, error: error.message });
  }
};

export const fetchFiles = (identifier, id) => async (dispatch) => {
  dispatch({ type: FETCH_FILES_REQUEST });
  try {
    request({
      method: "GET",
      url: `${server_url}/Documents?ObjectType=${identifier}&InstId=${id}`,
      // url: encodeURI(
      //   `${server_url}/Documents/Download?fileName=DxDiag.txt&folderName=Company_36/Property_82`
      // ),
    })
      .then(async (response) => {
        dispatch({ type: FETCH_FILES_SUCCESS, payload: response.data.items });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            dispatch({ type: FETCH_FILES_SUCCESS, payload: [] });
          }
        } else {
          dispatch({
            type: FETCH_FILES_FAILURE,
            error: {
              text: "Error occurred during documents listing : " + e.message,
              status: "error",
              loading: false,
              showSnackbar: true,
            },
          });
          throw new Error("Failed to fetch documents");
        }
      });
  } catch (error) {
    dispatch({ type: FETCH_FILES_FAILURE, error: error.message });
  }
};
