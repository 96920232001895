import request from "config/api";
import { ActionConstants } from "constants";
import {
	JOB_LOADING,
	JOB_GET_ALL_SUCCESS,
	JOB_ERROR,
	JOB_CLEAR_SNACKBAR,
} from "../constants/types";
import { server_url } from "../constants/types";
import queryString from "query-string";
import get from "lodash/get";

export const addUpdateJob = ({ formData, onSuccess = () => {}, payload }) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_JOB,
				payload: {
					loading: true,
				},
			});
			request({
				method: formData.jobId ? "PUT" : "POST",
				url: `${server_url}/job${formData.jobId ? `/${formData.jobId}` : ""}`,
				body: {
					...formData,
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_JOB_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Job Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					dispatch(jobGetAll(payload));
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: ActionConstants.ADD_NEW_JOB_ERROR,
						payload: {
							text: "Error occured during adding JOB data.",
							status: "error",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const jobGetAll = (params = {}) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: JOB_LOADING,
				payload: {
					loading: true,
				},
			});
			const queryParams = queryString.stringify(params);
			request({
				method: "GET",
				url: `${server_url}/job?${queryParams}`,
			})
				.then((response) => {
					dispatch({
						type: JOB_GET_ALL_SUCCESS,
						payload: {
							jobList: response.data.items,
							totalCount: response.data.totalCount,
							loading: false,
							status: "success",
							text: "Get All JOB data successfully.",
						},
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: JOB_ERROR,
						payload: {
							status: "error",
							text: "Error occured during getting JOB data.",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchJobById = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_JOB_BY_ID,
				payload: {
					loading: true,
				},
			});
			dispatch({
				type: ActionConstants.LOADING_APP,
			});
			request({
				method: "GET",
				url: `${server_url}/job/${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_JOB_BY_ID_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_SUCCESS,
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_JOB_BY_ID_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_ERROR,
					});
					resolve();
				});
		});
	};
};

export const jobDelete = ({ jobId, params = {}, onSuccess = () => {} }) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: JOB_LOADING,
				payload: {
					loading: true,
				},
			});
			request({
				url: `${server_url}/job/${jobId}`,
				method: "DELETE",
			})
				.then((response) => {
					dispatch(jobGetAll(params));
					onSuccess();
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: JOB_ERROR,
						payload: {
							text: "Error occured during deleteing JOB data.",
							status: "error",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const jobClearShowSnackbar = () => {
	return (dispatch, getState) => {
		dispatch({
			type: JOB_CLEAR_SNACKBAR,
		});
	};
};

export const fetchJobUserRoles = (jobRefId) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_JOB_USERS_ROLES,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/jobUser${jobRefId ? `?jobId=${jobRefId}` : ""}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_JOB_USERS_ROLES_SUCCESS,
						payload: {
							response: response.data.items,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_JOB_USERS_ROLES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const updateJobUserRoles = (payload, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.UPDATE_JOB_USERS_ROLES,
				payload: {
					loading: true,
				},
			});
			const query_string = queryString.stringify(payload);
			request({
				method: payload.purId ? "PUT" : "POST",
				url: `${server_url}/jobUser?${query_string}`,
				// body: payload,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.UPDATE_JOB_USERS_ROLES_SUCCESS,
						payload: {
							response: response.data.items,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					dispatch(fetchJobUserRoles(payload.jobId));
					onSuccess();
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.UPDATE_JOB_USERS_ROLES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const deleteJobUserRole = (purId, projectId) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.DELETE_PROJECT_USER_ROLE,
				payload: {
					loading: true,
				},
			});
			request({
				method: "DELETE",
				url: `${server_url}/jobUser/${purId}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.DELETE_PROJECT_USER_ROLE_SUCCESS,
						payload: {
							status: "success",
							message: "Deleted Successfully",
							loading: false,
						},
					});
					dispatch(fetchJobUserRoles(projectId));
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.DELETE_PROJECT_USER_ROLE_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
