import React, { useContext } from "react";
import Reports from "./Reports";
import MyReports from "./MyReports";
import AppRoleContext from "context/AppRoleContext";

export const ReportWrapper = ({ type }) => {
	const appRole = useContext(AppRoleContext);
	if (appRole.roleName === "SystemAdmin") {
		return <Reports />;
	} else {
		return <MyReports />;
	}
};

export default ReportWrapper;
