import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Typography, IconButton } from "@mui/material";
import { addTaskForm, addTaskFields } from "./addTaskUtils";
import get from "lodash/get";
import { CloseOutlined } from "@mui/icons-material";
import "./tasks.scss";
import FormInputField, {
	FormLabel,
} from "components/TextFields/FormInputField";
import DatePickerField from "components/DateComponets/DatePicker";
import { addNewTask } from "actions/taskActions";

export const AddNewProperty = ({
	onCancel,
	taskId,
	selectedTask = {},
	title,
	parentId,
}) => {
	const dispatch = useDispatch();

	const { validationSchema } = addTaskForm;
	const initialState = addTaskForm.defaultState(
		get(selectedTask, "data", null)
	);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: validationSchema,
		onSubmit: () => {
			dispatch(
				addNewTask({ ...formik.values, ...parentId, job: null }, onCancel)
			);
		},
	});

	return (
		<div className="mt-0 p-1">
			<div className="d-flex justify-content-between">
				<Typography className="text-left m-2 h1" id="header">
					{title || "Create New Task"}
				</Typography>
				<IconButton onClick={() => onCancel()}>
					<CloseOutlined />
				</IconButton>
			</div>
			<div id="create-task" className="mt-5">
				<form onSubmit={formik.handleSubmit}>
					<div id="container row">
						<div id="section-1 mt-3">
							<div id="section-header">
								<Typography className="text-left m-2 h2">
									Task Information
								</Typography>
							</div>
							<div className="d-flex">
								<div className="form-fields col-12">
									<div className="container row justify-content-between">
										{addTaskFields.taskInformation.map((field) => {
											const extraProps = get(field, "extraInputProps", {});
											return field.type === "dateField" ? (
												<div
													className={`form-field-wrapper ${field.className}`}
													style={{ width: field.width }}
												>
													<FormLabel
														text={field.label}
														required={field.required}
													/>
													<DatePickerField
														onChange={(date) => {
															formik.setFieldValue(field.key, date);
														}}
														value={formik.values[field.key]}
														field={field}
													/>
												</div>
											) : (
												<FormInputField
													field={field}
													formik={formik}
													value={formik.values[field.key]}
													key={field.key}
													{...extraProps}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<div className="d-flex justify-content-center align-self-center">
								<button
									type="button"
									className={`secondaryButton m-3`}
									onClick={() => {
										onCancel();
									}}
								>
									{"Cancel"}
								</button>
								<button type="submit" className={`primaryButton m-3`}>
									{"Confirm"}
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default AddNewProperty;
