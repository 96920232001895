export const formatNumberKeyDown = (e, onValidate) => {
  if (e.key === "Backspace" || e.keyCode === 8) {
    const { value } = e.target;
    var selectionStart = e.target.selectionStart;
    var selectionEnd = e.target.selectionEnd;

    const selectedLength = selectionEnd - selectionStart;
    if (selectedLength === 0) {
      selectionEnd = selectionStart;
      selectionStart = selectionStart - 1;
    }
    const newValue = value.slice(0, selectionStart) + value.slice(selectionEnd);
    onValidate(newValue);
    e.preventDefault();
  }
};

export const formatNumberChange = (e, onValidate) => {
  const { value } = e.target;
  var result = "";
  if (!value) return value;
  const cleanedNumber = value.replace(/[^\d()-]/g, "");
  const phoneNumber = cleanedNumber.replace(/[^\d]/g, ""); // Remove all non-numeric characters
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) result = phoneNumber;
  if (phoneNumberLength < 7) {
    result = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  result = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;

  onValidate(result);
};
