import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Create styles using makeStyles
const useStyles = makeStyles((theme) => ({
	tableContainer: {
		marginTop: theme.spacing(3),
	},
	tableHead: {
		backgroundColor: theme.palette.primary.main,
	},
	tableHeadCell: {
		color: theme.palette.common.white,
		fontWeight: "bold",
	},
	tableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	tableCell: {
		padding: theme.spacing(1),
	},
	amountCell: {
		fontWeight: "bold",
	},
}));

const PurchaseOrders = () => {
	const classes = useStyles();

	const purchaseOrders = [
		{ id: 1, orderNumber: "PO001", date: "2024-06-15", amount: 1000 },
		{ id: 2, orderNumber: "PO002", date: "2024-06-20", amount: 1500 },
		// Add more mock data as needed
	];

	const handleDownloadPDF = (orderNumber) => {
		// Logic to download PDF for the selected purchase order
		console.log(`Downloading PDF for purchase order: ${orderNumber}`);
	};

	return (
		<div>
			<Typography variant="h6" gutterBottom>
				Purchase Orders
			</Typography>
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell className={classes.tableHeadCell}>
								Order Number
							</TableCell>
							<TableCell className={classes.tableHeadCell}>Date</TableCell>
							<TableCell className={classes.tableHeadCell}>Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{purchaseOrders.map((order) => (
							<TableRow
								key={order.id}
								className={classes.tableRow}
								onClick={() => handleDownloadPDF(order.orderNumber)}
							>
								<TableCell className={classes.tableCell}>
									{order.orderNumber}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{order.date}
								</TableCell>
								<TableCell
									className={`${classes.tableCell} ${classes.amountCell}`}
								>
									${order.amount}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default PurchaseOrders;
