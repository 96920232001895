import { CancelRounded, CheckRounded, Search } from "@mui/icons-material";
import {
	Autocomplete,
	CircularProgress,
	Grid,
	InputAdornment,
	Paper,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { getCompanies } from "actions/companiesActions";
import RenderTabs from "components/Tabs/Tabs";
import { getText } from "core/utils/getContentText";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { companiescolumns } from "pages/companies/CompaniesList";
import React, { useContext, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { companiesSelector } from "selectors/companiesSelectors";
import { getConnectionsSelector } from "selectors/connectionSelectors";
import "./connections.scss";
import AppRoleContext from "context/AppRoleContext";
import HiddenOn from "components/Wrappers/HiddenOn";
import NoResultsFound from "components/NoResults/NoResultsFound";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import { getConnType } from "constants/appConstants";

const RenderConnections = ({ tabConfig, type, selectedTab }) => {
	const columns = companiescolumns;
	const dispatch = useDispatch();
	const tabData = useSelector(companiesSelector);
	const appRoleDetails = useContext(AppRoleContext);

	const companiesList = tabData[tabConfig.key];

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [addNewConnection, setAddNewConnection] = React.useState(false);
	const [toaster, setToaster] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState(false);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const onSuccess = (payload) => {
		setShowAlert(true);
		setToaster({
			open: true,
			message: payload.message,
			type: payload.type,
		});
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	return companiesList.length === 0 ? (
		<NoResultsFound />
	) : (
		<div>
			<SnackBarWithInfoAlert
				open={showAlert}
				alertSeverity={toaster.type}
				alertMessage={toaster.message}
				handleClose={() => {
					setShowAlert(false);
				}}
			/>
			<div>
				{/* <Typography className={"h5"}>Available Connections</Typography> */}
			</div>
			<div>
				{/* <Grid container>
					<Typography
						className="h6 mr-2 align-content-center"
						variant="subtitle1"
					>
						Search Connections:
					</Typography>
					<TextField
						className="form-field ml-3"
						variant="outlined"
						style={{ width: "30%", marginLeft: "1rem" }}
						placeholder={"Company Name/Type/Location"}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									style={{ cursor: "pointer" }}
									onClick={() => {
										console.log("Search clicked");
									}}
								>
									<Search style={{ fontSize: "2rem" }} />
								</InputAdornment>
							),
						}}
					/>
				</Grid> */}
			</div>
			<div className="projects-content-section">
				<HiddenOn isHidden={companiesList.length === 0}>
					<Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-5">
						<TableContainer sx={{ maxHeight: 440 }}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										{columns.map((column) => (
											<TableCell
												key={column.id}
												align={column.align}
												style={{ minWidth: column.minWidth }}
											>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{companiesList
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => {
											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={row.code}
												>
													{columns.map((column) => {
														const value = row[column.id];
														return (
															<TableCell key={column.id} align={column.align}>
																{column.format
																	? column.format({
																			value,
																			company: row,
																			tabConfig,
																			dispatch,
																			type,
																			onSuccess,
																			selectedTab,
																	  })
																	: value}
															</TableCell>
														);
													})}
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={companiesList.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</HiddenOn>
			</div>
		</div>
	);
};

const tabs = [
	{
		key: "pendingConnections",
		label: "Pending",
		TabComponent: RenderConnections,
	},
	{
		key: "newConnections",
		label: "New Connections",
		TabComponent: RenderConnections,
	},
	{
		key: "connected",
		label: "Connected",
		TabComponent: RenderConnections,
	},
];

export const Connections = ({ type }) => {
	const [selectedTab, updateTab] = React.useState("pendingConnections");
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCompanies({ ConnectionStatus: getConnType[selectedTab] }));
	}, [dispatch, selectedTab]);

	return (
		<>
			<div>
				<div>
					<Typography className="h2">Connections </Typography>

					<Typography variant="body1">
						{getText(`connections.${type}Description`)}
					</Typography>
				</div>

				<div className="project-tabs tabs mt-5">
					<RenderTabs
						tabs={tabs}
						selectedTab={selectedTab}
						onTabClick={(newTab) => {
							updateTab(newTab);
						}}
						tabParams={{ type, selectedTab }}
					/>
				</div>
			</div>
		</>
	);
};

export default Connections;
