import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { server_url } from "../../constants/types";
import { EditOutlined, VisibilityRounded } from "@mui/icons-material";

const MyReports = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    // Fetch data from an API or source
    const fetchData = async () => {
      // Example API call

      const tokenString = localStorage.getItem("token");
      //const userToken = JSON.parse(tokenString);
      //const token = userToken?.token;

      const response = await fetch(`${server_url}/Report`, {
        headers: {
          Authorization: "Bearer " + tokenString,
        },
      });
      const result = await response.json();
      setData(result);
      //setTotalRows(result.totalRows);
    };
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleCreateREport = () => {
    navigate("/createreport");
  };

  return (
    <div>
      <h1>Reports</h1>
      <label className="m-3">
        Page Size:
        <select
          className="ms-3"
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </label>
      <table>
        <thead>
          <tr>
            <th>ViewName</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{item.viewName}</td>
                <td>
                  <Link to={`/viewreport/${item.reportID}`}>
                    <VisibilityRounded style={{ fontSize: "1rem" }} />
                    View Report
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {totalRows > pageSize && (
        <div>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * pageSize >= totalRows}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default MyReports;
