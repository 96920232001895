import { ActionConstants } from "constants";
import {
	PROPERTY_LOADING,
	PROPERTY_GET_ALL_SUCCESS,
	PROPERTY_ERROR,
	JOB_LOADING,
	JOB_GET_ALL_SUCCESS,
	JOB_ERROR,
	TASK_LOADING,
	TASK_GET_ALL_SUCCESS,
	TASK_ERROR,
} from "constants/types";

const initialState = {
	loading: false,
	loginError: { status: "", message: "" },
	userDetails: { role: "" },
	dashboardData: {},
	appContext: {},
	globalSearchResults: {},
	fetchingGlobalSearch: false,
	status: "",
	error: "",
};

export const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.REGISTRATION:
		case ActionConstants.SIGNUP_EMAIL:
		case ActionConstants.BUSINESS_PROFILE:
		case ActionConstants.ADD_NEW_PROPERTY:
		case ActionConstants.ADD_NEW_JOB:
		case ActionConstants.GET_COMPANIES:
		case ActionConstants.ADD_NEW_PROJECT:
		case ActionConstants.GET_PROJECTS:
		case ActionConstants.ADD_NEW_TASK:
		case ActionConstants.GET_PROJECT_BY_ID:
		case ActionConstants.GET_DASHBOARD_DATA:
		case ActionConstants.GET_USERS:
		case ActionConstants.DELETE_PROJECT_BY_ID:
		case ActionConstants.GET_PROJECT_USERS_ROLES:
		case TASK_LOADING:
		case JOB_LOADING:
		case PROPERTY_LOADING:
		case ActionConstants.LOADING_APP:
		case ActionConstants.PROPERTY_BY_ID_LOADING:
		case ActionConstants.GET_GUID_INVITE_DETAILS:
			return {
				...state,
				loading: true,
			};
		case ActionConstants.REGISTRATION_SUCCESS:
		case ActionConstants.SIGNUP_EMAIL_SUCCESS:
		case ActionConstants.ADD_NEW_PROPERTY_SUCCESS:
		case ActionConstants.ADD_NEW_JOB_SUCCESS:
		case ActionConstants.GET_COMPANIES_SUCCESS:
		case ActionConstants.ADD_NEW_PROJECT_SUCCESS:
		case ActionConstants.GET_PROJECTS_SUCCESS:
		case ActionConstants.ADD_NEW_TASK_SUCCESS:
		case ActionConstants.GET_PROJECT_BY_ID_SUCCESS:
		case ActionConstants.GET_USERS_SUCCESS:
		case ActionConstants.DELETE_PROJECT_BY_ID_SUCCESS:
		case ActionConstants.GET_PROJECT_USERS_ROLES_SUCCESS:
		case PROPERTY_GET_ALL_SUCCESS:
		case JOB_GET_ALL_SUCCESS:
		case TASK_GET_ALL_SUCCESS:
		case ActionConstants.LOADING_APP_SUCCESS:
		case ActionConstants.PROPERTY_BY_ID_SUCCESS:
		case ActionConstants.GET_GUID_INVITE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case ActionConstants.REGISTRATION_ERROR:
		case ActionConstants.SIGNUP_EMAIL_ERROR:
		case ActionConstants.ADD_NEW_PROPERTY_ERROR:
		case ActionConstants.ADD_NEW_JOB_ERROR:
		case ActionConstants.GET_COMPANIES_ERROR:
		case ActionConstants.ADD_NEW_PROJECT_ERROR:
		case ActionConstants.GET_PROJECTS_ERROR:
		case ActionConstants.ADD_NEW_TASK_ERROR:
		case ActionConstants.GET_PROJECT_BY_ID_ERROR:
		case ActionConstants.GET_USERS_ERROR:
		case ActionConstants.DELETE_PROJECT_BY_ID_ERROR:
		case ActionConstants.GET_PROJECT_USERS_ROLES_ERROR:
		case ActionConstants.LOGIN_EXTERNAL:
		case TASK_ERROR:
		case PROPERTY_ERROR:
		case JOB_ERROR:
		case ActionConstants.LOADING_APP_ERROR:
		case ActionConstants.PROPERTY_BY_ID_ERROR:
		case ActionConstants.GET_GUID_INVITE_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				status: action?.payload?.status,
				error: action?.payload?.message,
			};
		case ActionConstants.LOGIN:
			return {
				...state,
				loading: true,
			};
		case ActionConstants.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case ActionConstants.LOGIN_EXTERNAL_SUCCESS:
			return {
				...state,
				loading: false,
				userDetails: action.payload,
			};
		case ActionConstants.LOGIN_EXTERNAL_ERROR:
		case ActionConstants.LOGIN_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
				loginError: action.payload,
			};
		case ActionConstants.USER_DETAILS:
		case ActionConstants.PAYMENT:
			return {
				...state,
				loading: true,
				userDetails: action.payload,
			};
		case ActionConstants.USER_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				userDetails: action.payload,
			};
		case ActionConstants.USER_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				userDetails: action.payload,
				error: action.payload,
			};
		case ActionConstants.PAYMENT_SUCCESS:
			return {
				...state,
				loading: false,
				paymentDetails: action.payload,
			};
		case ActionConstants.PAYMENT_ERROR:
			return {
				...state,
				loading: false,
				paymentDetails: action.payload,
			};
		case ActionConstants.GET_ROLE:
			return {
				...state,
				loading: true,
			};
		case ActionConstants.GET_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				userDetails: {
					...state.userDetails,
					role: action.payload,
				},
			};
		case ActionConstants.GET_ROLE_ERROR:
			return {
				...state,
				error: action.payload,
			};

		case ActionConstants.BUSINESS_PROFILE_SUCCESS:
			return {
				...state,
				businessProfile: action.payload,
				loading: false,
			};
		case ActionConstants.BUSINESS_PROFILE_ERROR:
			return {
				...state,
				businessProfile: action.payload,
				loading: false,
			};
		case ActionConstants.GET_DASHBOARD_DATA_SUCCESS:
			return {
				...state,
				dashboardData: action.payload,
				loading: false,
			};
		case ActionConstants.GET_DASHBOARD_DATA_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};
		case ActionConstants.GLOBAL_PROJECT_CONTENT: {
			return {
				...state,
				appContext: {
					selectedProject: action.payload.projectId,
				},
			};
		}
		case ActionConstants.GET_GLOBAL_SEARCH:
			return {
				...state,
				fetchingGlobalSearch: true,
			};
		case ActionConstants.GET_GLOBAL_SEARCH_SUCCESS:
			return {
				...state,
				globalSearchResults: action.payload,
				fetchingGlobalSearch: false,
			};
		case ActionConstants.GET_GLOBAL_SEARCH_ERROR:
			return {
				...state,
				fetchingGlobalSearch: false,
			};
		default:
			return state;
	}
};

export default appReducer;
