import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { server_url } from "../../constants/types";
import { EditOutlined, VisibilityRounded } from "@mui/icons-material";

const Reports = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalRows, setTotalRows] = useState(0);

	useEffect(() => {
		// Fetch data from an API or source
		const fetchData = async () => {
			// Example API call

			const tokenString = localStorage.getItem("token");
			//const userToken = JSON.parse(tokenString);
			//const token = userToken?.token;

			const response = await fetch(`${server_url}/Report`, {
				headers: {
					Authorization: "Bearer " + tokenString,
				},
			});
			const result = await response.json();
			setData(result);
			//setTotalRows(result.totalRows);
		};
		fetchData();
	}, [currentPage, pageSize]);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value);
	};

	const handleCreateREport = () => {
		navigate("/createreport");
	};

	return (
		<div>
			<h1>Reports</h1>
			<div>
				<button
					type="button"
					className="primaryButton mb-2 mt-2"
					onClick={handleCreateREport}
				>
					Create Report
				</button>
			</div>
			<label className="m-3">
				Page Size:
				<select
					className="ms-3"
					value={pageSize}
					onChange={handlePageSizeChange}
				>
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
				</select>
			</label>
			<table>
				<thead>
					<tr>
						<th>ViewName</th>
						<th>Paging</th>
						<th>PageSize</th>
						<th>Active</th>
						<th>Category</th>
						<th>ShowTotalRow</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{data &&
						data.map((item, index) => (
							<tr key={index}>
								<td>{item.viewName}</td>
								<td>{item.paging}</td>
								<td>{item.pageSize}</td>
								<td>{item.active.toString()}</td>
								<td>{item.category}</td>
								<td>{item.showTotalRow.toString()}</td>
								<td>
									<Link to={`/createreport/${item.reportID}`}>
										<EditOutlined style={{ fontSize: "1rem" }} />
										Edit
									</Link>{" "}
									&nbsp;
									<Link to={`/viewreport/${item.reportID}`}>
										<VisibilityRounded style={{ fontSize: "1rem" }} />
										View Report
									</Link>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{totalRows > pageSize && (
				<div>
					<button
						onClick={() => handlePageChange(currentPage - 1)}
						disabled={currentPage === 1}
					>
						Previous
					</button>
					<button
						onClick={() => handlePageChange(currentPage + 1)}
						disabled={currentPage * pageSize >= totalRows}
					>
						Next
					</button>
				</div>
			)}
		</div>
	);
};

export default Reports;
