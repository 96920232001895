export const emailRegex =
	/^([A-Za-z0-9](\.|_)?)+@[A-Za-z0-9]+(\.[A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;

export const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

export const phoneRegExp1 =
	/^\+?\d{1,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const getConnType = {
	newConnections: "Notconnected",
	pendingConnections: "waiting",
	connected: "connected",
};

export const APP_ACTION_CONSTANTS = {
	GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
	GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
	GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR",
	LOADING_APP: "LOADING_APP",
	LOADING_APP_SUCCESS: "LOADING_APP_SUCCESS",
	LOADING_APP_ERROR: "LOADING_APP_ERROR",
	LOGIN_EXTERNAL: "LOGIN_EXTERNAL",
	LOGIN_EXTERNAL_SUCCESS: "LOGIN_EXTERNAL_SUCCESS",
	LOGIN_EXTERNAL_ERROR: "LOGIN_EXTERNAL_ERROR",
	GET_GLOBAL_SEARCH: "GET_GLOBAL_SEARCH",
	GET_GLOBAL_SEARCH_SUCCESS: "GET_GLOBAL_SEARCH_SUCCESS",
	GET_GLOBAL_SEARCH_ERROR: "GET_GLOBAL_SEARCH_ERROR",
};
