import React, { useState, useEffect, useMemo, createRef } from "react";
import { AgGridReact } from "ag-grid-react";

import { useParams, useNavigate } from "react-router-dom";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { server_url } from "../../constants/types";

import "ag-grid-enterprise";

const ViewReport = () => {
  // Row Data: The data to be displayed.

  const { id } = useParams();
  const [gridMain, setGridMain] = useState(createRef());

  const defaultColDef = useMemo(() => ({
    filter: true, // Enable filtering on all columns
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
    pinnedRowCellRendererParams: {
      style: { color: "yellow", fontWeight: "bold" },
    },
  }));

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([]);

  const [pagination, setPagination] = useState(false);
  const [paginationPageSize, setpaginationPageSize] = useState(2);
  const [rowModelType, setrowModelType] = useState("");

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const dateFilterParams = {
    buttons: ["clear", "apply"],
    filterOptions: [
      "equals",
      "notEqual",
      "greaterThan",
      "greaterThanOrEqual",
      "lessThan",
      "lessThanOrEqual",
      "inRange",
      "blank",
      "notBlank",
      {
        displayKey: "today",
        displayName: "Today",
        predicate: (_, cellValue) => {
          var dateAsString = cellValue;
          if (dateAsString === null) return -1;

          dateAsString = dateAsString.split(" ")[0]; //dateAsString.replace(/\s\d\d:\d\d:\d\d\.\d+/,'');

          var dateParts = dateAsString.split("/");

          if (dateParts.length > 1) {
            var cellDate = new Date(
              Number(dateParts[2]),
              Number(dateParts[0]) - 1,
              Number(dateParts[1])
            );
          } else {
            dateParts = dateAsString.split("-");
            var cellDate = new Date(
              Number(dateParts[0]),
              Number(dateParts[1]) - 1,
              Number(dateParts[2])
            );
          }

          var filterLocalDateAtMidnight = new Date();
          filterLocalDateAtMidnight.setHours(0, 0, 0, 0);

          if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return true;
          }

          return false;
        },
        numberOfInputs: 0,
      },
    ],
    filterModifiedCallback: function (params) {
      var info = params;
    },
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      var dateAsString = cellValue;
      if (dateAsString === null) return -1;

      dateAsString = dateAsString.split(" ")[0]; //dateAsString.replace(/\s\d\d:\d\d:\d\d\.\d+/,'');

      var dateParts = dateAsString.split("/");

      if (dateParts.length > 1) {
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1])
        );
      } else {
        dateParts = dateAsString.split("-");
        var cellDate = new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1,
          Number(dateParts[2])
        );
      }

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
  };

  useEffect(() => {
    // Fetch data for the specific item to edit

    const tokenString = localStorage.getItem("token");

    const fetchData = async () => {
      if (id) {
        if (rowModelType === "") {
          const responseReport = await fetch(`${server_url}/Report/${id}`, {
            headers: {
              Authorization: "Bearer " + tokenString,
            },
          });
          const reportInfo = await responseReport.json();

          if (reportInfo.Paging !== "none") {
            setpaginationPageSize(reportInfo.pageSize);
            setPagination(true);
            setrowModelType(reportInfo.paging);
          }

          const response = await fetch(`${server_url}/ReportColumn/${id}`, {
            headers: {
              Authorization: "Bearer " + tokenString,
            },
          });
          const result = await response.json();

          var colInfoArray = [];

          result.forEach((item) => {
            var colInfo = {
              field: item.columnName,
              dataType: item.dataType,
              hide: item.hide,
              rowGroup: item.rowGroup,
              enableValue: true,
            };

            if (item.customDef && item.customDef !== "") {
              colInfo.valueGetter = item.customDef;
            }

            if (item.pinned && item.pinned !== "none") {
              colInfo.pinned = item.pinned;
            }

            if (item.dataType !== undefined) {
              if (
                item.dataType.toLowerCase() === "date" ||
                item.dataType.toLowerCase() === "timestamp" ||
                item.dataType.toLowerCase() === "system.datetime"
              ) {
                colInfo.filter = "agDateColumnFilter";
                colInfo.filterParams = dateFilterParams;
                colInfo.valueFormatter = dateFormatter;
              } else if (item.dataType.toLowerCase() === "date_et") {
                colInfo.filterParams = dateFilterParams;
                colInfo.valueFormatter = dateFormatterET;
              } else if (item.dataType.toLowerCase() === "date_without_time") {
                colInfo.filterParams = dateFilterParams;
                colInfo.valueFormatter = dateWihtoutFormatter;
              } else if (
                item.dataType.toLowerCase() === "float8" ||
                item.dataType.toLowerCase() === "decimal" ||
                item.dataType.toLowerCase() === "numeric"
              ) {
                colInfo.filter = "agNumberColumnFilter";
                colInfo.valueFormatter = decimalFormatter;
                colInfo.type = "rightAligned";
                colInfo.numDecimals = item.NumDecimals;

                if (item.AggFunc && item.AggFunc !== "none") {
                  colInfo.aggFunc = item.AggFunc;
                }
              } else if (
                item.dataType.toLowerCase() === "bigint" ||
                item.dataType.toLowerCase() === "integer" ||
                item.dataType.toLowerCase() === "int8" ||
                item.dataType.toLowerCase() === "system.int32"
              ) {
                colInfo.filter = "agNumberColumnFilter";
                colInfo.valueFormatter = numberFormatter;
                colInfo.type = "rightAligned";

                if (item.aggFunc && item.aggFunc !== "none") {
                  colInfo.aggFunc = item.aggFunc;
                }
              } else if (item.filter === "agMultiColumnFilter") {
                colInfo.filterParams = {
                  filters: [
                    {
                      filter: "agTextColumnFilter",
                      filterParams: {
                        buttons: ["clear", "apply"],
                        defaultOption: "contains",
                        maxNumConditions: 10,
                        defaultJoinOperator: "OR",
                        textMatcher: ({ filterOption, value, filterText }) => {
                          const filterTextLowerCase = filterText.toLowerCase();
                          const valueLowerCase = value.toString().toLowerCase();
                          switch (filterOption) {
                            case "contains":
                              return (
                                valueLowerCase.indexOf(filterTextLowerCase) >= 0
                              );
                            case "notContains":
                              return (
                                valueLowerCase.indexOf(filterTextLowerCase) ===
                                -1
                              );
                            case "equals":
                              const myArray =
                                filterTextLowerCase.split(/[\s,\n]+/);
                              var arrayLength = myArray.length;

                              if (arrayLength > 1) {
                                return myArray.includes(valueLowerCase);
                              } else {
                                return valueLowerCase === filterTextLowerCase;
                              }

                            case "notEqual":
                              return valueLowerCase !== filterTextLowerCase;
                            case "startsWith":
                              return (
                                valueLowerCase.indexOf(filterTextLowerCase) ===
                                0
                              );
                            case "endsWith":
                              var index =
                                valueLowerCase.lastIndexOf(filterTextLowerCase);
                              return (
                                index >= 0 &&
                                index ===
                                  valueLowerCase.length -
                                    filterTextLowerCase.length
                              );
                            default:
                              // should never happen
                              console.warn(
                                "invalid filter type " + filterOption
                              );
                              return false;
                          }
                        },
                      },
                    },
                    {
                      filter: "agSetColumnFilter",
                    },
                  ],
                };
              } else {
                colInfo.filter = "agTextColumnFilter";
                colInfo.filterParams = {
                  buttons: ["clear", "apply"],
                  defaultOption: "contains",
                  maxNumConditions: 10,
                  defaultJoinOperator: "OR",
                };
              }
            }

            colInfoArray.push(colInfo);
          });

          setColDefs(colInfoArray);
        }

        if (gridApi) {
          if (rowModelType !== "" && rowModelType === "serverSide") {
            const dataSource = {
              getRows: (params) => {
                var strColumns = selectSql(params.request, gridApi.Options);
                var filterModel = params.request.filterModel;
                var strSort = orderBySql(params.request);
                var strSQL = whereSql(params.request, gridApi.Options);
                var strGroupBy = groupBySql(params.request);

                var QueryInfo = {
                  StartRow: params.request.startRow,
                  EndRow: params.request.endRow,
                  WhereClause: strSQL,
                  SortClause: strSort,
                  Columns: strColumns,
                  GroupBy: strGroupBy,
                  ReportID: id,
                };
                fetch(`${server_url}/ReportView`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + tokenString,
                  },
                  body: JSON.stringify(QueryInfo),
                })
                  .then((resp) => resp.json())
                  .then((res) => {
                    params.success({
                      rowData: res.data,
                      rowCount: res.rowCount,
                    });

                    autoSizeAll(gridApi, false);
                  })
                  .catch((err) => {
                    params.success({ rowData: [], rowCount: 0 });
                  });
              },
            };

            gridApi.setGridOption("rowModelType", "serverSide");
            gridApi.setGridOption("serverSideDatasource", dataSource);
          } else if (rowModelType !== "" && rowModelType === "clientSide") {
            var QueryInfo = {
              StartRow: 0,
              EndRow: 0,
              WhereClause: "",
              SortClause: "",
              Columns: "",
              GroupBy: "",
              ReportID: id,
            };

            await fetch(`${server_url}/ReportView`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenString,
              },
              body: JSON.stringify(QueryInfo),
            })
              .catch((error) => {})
              .then((response) => response.json())
              .then((data) => {
                if (data.Error !== undefined && data.Error !== "") {
                  return;
                }

                //gridApi.setGridOption("rowModelType", "clientSide");
                gridApi.setGridOption("rowData", data.data);

                autoSizeAll(gridApi, false);
              });
          }
        }
      }
    };
    fetchData();
  }, [id, gridApi]);

  const selectSql = (request, gridOptions) => {
    var rowGroupCols = request.rowGroupCols;
    var valueCols = request.valueCols;
    var groupKeys = request.groupKeys;

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      var rowGroupCol = rowGroupCols[groupKeys.length];
      var colsToSelect = ['"' + rowGroupCol.id + '"'];

      if (request.pivotMode) {
        var pivotCols = request.pivotCols;

        pivotCols.forEach(function (valueCol) {
          colsToSelect.push('"' + valueCol.id + '"');
        });
      }

      valueCols.forEach(function (valueCol) {
        if (valueCol.aggFunc === "weightaverage") {
          var colDef = gridOptions.columnDefs.find(
            (x) => x.field === valueCol.id
          );

          if (
            colDef !== null &&
            colDef.weightColumn !== undefined &&
            colDef.weightColumn !== ""
          ) {
            colsToSelect.push(
              'case when SUM("' +
                colDef.weightColumn +
                '") = 0 then 0 else SUM("' +
                valueCol.id +
                '" * "' +
                colDef.weightColumn +
                '")/ SUM("' +
                colDef.weightColumn +
                '") end AS "' +
                valueCol.id +
                '"'
            );
          } else {
            if (valueCol.id !== "click" && valueCol.id !== "check") {
              colsToSelect.push(
                valueCol.aggFunc +
                  '("' +
                  valueCol.id +
                  '") AS "' +
                  valueCol.id +
                  '"'
              );
            }
          }
        } else {
          if (valueCol.id !== "click" && valueCol.id !== "check") {
            colsToSelect.push(
              valueCol.aggFunc +
                '("' +
                valueCol.id +
                '") AS "' +
                valueCol.id +
                '"'
            );
          }
        }
      });

      return colsToSelect.join(", ");
    }

    return "*";
  };

  function isDoingGrouping(rowGroupCols, groupKeys) {
    // we are not doing grouping if at the lowest level
    return rowGroupCols.length > groupKeys.length;
  }

  function dateFormatter(params) {
    var sansDec = params.value;
    if (sansDec === null || sansDec === undefined) return sansDec;

    var dateTimeParts = sansDec.split(" ");

    var datePortion = dateTimeParts[0];
    var timePortion = ""; // sansDec.replace(/\s\d\d:\d\d:\d\d\.\d+/,'');

    if (dateTimeParts.length > 1) timePortion = dateTimeParts[1];

    var dateParts = datePortion.split("-");

    return (
      dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0] + " " + timePortion
    );
  }

  function dateFormatterET(params) {
    var sansDec = params.value;
    if (sansDec === null || sansDec === undefined) return sansDec;

    if (!sansDec.endsWith("Z") && !sansDec.endsWith("z")) sansDec += "Z";

    const dt = new Date(sansDec);

    var dateOptions = { day: "2-digit", month: "2-digit", year: "numeric" };
    var timeOptions = {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return (
      dt.toLocaleDateString("en", dateOptions) +
      " " +
      dt.toLocaleTimeString("en", timeOptions) +
      " ET"
    );
  }

  function dateWihtoutFormatter(params) {
    var sansDec = params.value;
    if (sansDec === null || sansDec === undefined) return sansDec;

    var formatted = sansDec.split(" ")[0]; // sansDec.replace(/\s\d\d:\d\d:\d\d\.\d+/,'');

    var dateParts = formatted.split("-");

    return dateParts[1] + "/" + dateParts[2] + "/" + dateParts[0];
  }

  function decimalFormatter(params) {
    var sansDec = params.value;
    if (sansDec === null || sansDec === undefined) return sansDec;

    // sansDec = sansDec.toFixed(0);

    // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return formatted;

    var numDecimals = 2;

    if (
      params.colDef.numDecimals !== undefined &&
      params.colDef.numDecimals !== 0
    ) {
      numDecimals = params.colDef.numDecimals;
    }

    var value = sansDec.toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: numDecimals, maximumFractionDigits: numDecimals }
    );

    if (params.colDef.append !== undefined && params.colDef.append !== "") {
      value = value + params.colDef.append;
    }

    if (params.colDef.prepend !== undefined && params.colDef.prepend !== "") {
      value = params.colDef.prepend + value;
    }

    return value;
  }

  function numberFormatter(params) {
    var sansDec = params.value;
    if (sansDec === null || sansDec === undefined) return sansDec;

    // sansDec = sansDec.toFixed(0);

    // var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return formatted;

    var value = sansDec.toLocaleString(
      undefined, // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 0 }
    );

    if (params.colDef.append !== undefined && params.colDef.append !== "") {
      value = value + params.colDef.append;
    }

    if (params.colDef.prepend !== undefined && params.colDef.prepend !== "") {
      value = params.colDef.prepend + value;
    }

    return value;
  }

  function orderBySql(request) {
    var rowGroupCols = request.rowGroupCols;
    var groupKeys = request.groupKeys;

    var sortModel = request.sortModel;

    if (sortModel.length === 0) return "";

    var sorts = sortModel.map(function (s) {
      var colID = s.colId;

      colID = '"' + colID + '"';

      if (isDoingGrouping(rowGroupCols, groupKeys)) {
        var rowGroupCol = rowGroupCols[groupKeys.length];

        if (s.colId === rowGroupCol.id) {
          return colID + " " + s.sort.toUpperCase();
        } else {
          return "";
        }
      } else {
        return colID + " " + s.sort.toUpperCase();
      }
    });

    var sortCols = sorts
      .filter(function (val) {
        return val !== "";
      })
      .join(", ");

    if (sortCols === "") return "";

    return " ORDER BY " + sortCols;
  }

  function whereSql(request, gridOptions) {
    var whereParts = [];
    var filterModel = request.filterModel;

    var strURL = window.location.href;
    var bolLocalHost = true;

    if (strURL.indexOf("localhost") > -1) {
      bolLocalHost = true;
    }

    var bolDateFiltered = false;

    if (!filterModel) filterModel = gridOptions.api.getFilterModel();

    if (filterModel) {
      Object.keys(filterModel).forEach(function (key) {
        var item = filterModel[key];

        if (
          gridOptions !== undefined &&
          gridOptions.dateColumn !== undefined &&
          gridOptions.dateColumn !== "" &&
          gridOptions.dateColumn === key
        ) {
          bolDateFiltered = true;
        }

        if (bolLocalHost) {
          key = '"' + key + '"';
        }

        switch (item.filterType) {
          case "text":
            whereParts.push(createFilterSql(textFilterMapper, key, item));
            break;
          case "number":
            whereParts.push(createFilterSql(numberFilterMapper, key, item));
            break;
          case "date":
            whereParts.push(createFilterSql(dateFilterMapper, key, item));
            break;
          case "custom":
            alert("custom filter is active");
            break;
          default:
            console.log("unknown filter type: " + item.filterType);
            break;
        }
      });
    }

    var rowGroups = request.rowGroupCols;
    var groupKeys = request.groupKeys;

    if (groupKeys) {
      groupKeys.forEach(function (key, i) {
        var value = typeof key === "string" ? "'" + key + "'" : key;

        if (bolLocalHost) {
          whereParts.push('"' + rowGroups[i].id + '" = ' + value);
        } else {
          whereParts.push(rowGroups[i].id + " = " + value);
        }
      });
    }

    if (
      gridOptions !== undefined &&
      !bolDateFiltered &&
      gridOptions.customFilter !== undefined &&
      gridOptions.customFilter !== ""
    ) {
      whereParts.push(gridOptions.customFilter);
    }

    if (whereParts.length > 0) {
      return whereParts.join(" AND "); //' WHERE ' +
    }

    return "";
  }

  function createFilterSql(mapper, key, item) {
    if (item.operator) {
      var output = "";

      var condition1 = mapper(key, item.condition1);
      var condition2 = mapper(key, item.condition2);

      output = "(" + condition1 + " " + item.operator + " " + condition2;

      for (var index = 2; index < item.conditions.length; index++) {
        if (item.conditions[index]) {
          var condition3 = mapper(key, item.conditions[index]);
          output += " " + item.operator + " " + condition3;
        }
      }

      return output + ")";
    }

    return mapper(key, item);
  }

  function textFilterMapper(key, item) {
    switch (item.type) {
      case "equals":
        const myArray = item.filter.split(/[\s,\n]+/);
        var arrayLength = myArray.length;

        if (arrayLength > 1) {
          var filterVal = "";

          for (var indx = 0; indx < arrayLength; indx++) {
            console.log(myArray[indx]);
            if (indx === arrayLength - 1) {
              filterVal += "'" + myArray[indx] + "'";
            } else {
              filterVal += "'" + myArray[indx] + "',";
            }
          }

          return "lower(" + key + ") in (" + filterVal.toLowerCase() + ")";
        } else {
          return "lower(" + key + ") = '" + item.filter.toLowerCase() + "'";
        }

      case "notEqual":
        return "lower(" + key + ") !== '" + item.filter.toLowerCase() + "'";
      case "contains":
        return "lower(" + key + ") LIKE '%" + item.filter.toLowerCase() + "%'";
      case "notContains":
        return (
          "lower(" + key + ") NOT LIKE '%" + item.filter.toLowerCase() + "%'"
        );
      case "startsWith":
        return "lower(" + key + ") LIKE '" + item.filter.toLowerCase() + "%'";
      case "endsWith":
        return "lower(" + key + ") LIKE '%" + item.filter.toLowerCase() + "'";
      case "blank":
        return "(" + key + " is null or " + key + " = '' )";
      case "notBlank":
        return "(" + key + " is not null and " + key + " <> '' )";
      default:
        console.log("unknown text filter type: " + item.type);
    }
  }

  function numberFilterMapper(key, item) {
    switch (item.type) {
      case "equals":
        return key + " = " + item.filter;
      case "notEqual":
        return key + " !== " + item.filter;
      case "greaterThan":
        return key + " > " + item.filter;
      case "greaterThanOrEqual":
        return key + " >= " + item.filter;
      case "lessThan":
        return key + " < " + item.filter;
      case "lessThanOrEqual":
        return key + " <= " + item.filter;
      case "inRange":
        return (
          "(" +
          key +
          " >= " +
          item.filter +
          " and " +
          key +
          " <= " +
          item.filterTo +
          ")"
        );
      case "blank":
        return key + " is null  ";
      case "notBlank":
        return key + " is not null  ";
      default:
        console.log("unknown number filter type: " + item.type);
    }
  }

  function dateFilterMapper(key, item) {
    switch (item.type) {
      case "equals":
        var nextDay = new Date(item.dateFrom);
        nextDay.setDate(nextDay.getDate() + 1);
        return (
          key +
          " between '" +
          item.dateFrom +
          "' and '" +
          nextDay.toISOString().slice(0, 10) +
          "'"
        );
      case "notEqual":
        return key + " !== '" + item.dateFrom + "'";
      case "greaterThan":
        return key + " > '" + item.dateFrom + "'";
      case "greaterThanOrEqual":
        return key + " >= '" + item.dateFrom + "'";
      case "lessThan":
        return key + " < '" + item.dateFrom + "'";
      case "lessThanOrEqual":
        return key + " <= '" + item.dateFrom + "'";
      case "inRange":
        return (
          "(" +
          key +
          " >= '" +
          item.dateFrom +
          "'" +
          " and " +
          key +
          " <= '" +
          item.dateTo +
          "'" +
          ")"
        );
      case "blank":
        return key + " is null  ";
      case "notBlank":
        return key + " is not null  ";
      case "today":
        var d = new Date(),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return key + " = '" + [year, month, day].join("-") + " 00:00:00'";
      default:
        console.log("unknown date filter type: " + item.type);
    }
  }

  function groupBySql(request) {
    var rowGroupCols = request.rowGroupCols;
    var groupKeys = request.groupKeys;

    var strURL = window.location.href;
    var bolLocalHost = true;

    if (strURL.indexOf("localhost") > -1) {
      bolLocalHost = true;
    }

    if (isDoingGrouping(rowGroupCols, groupKeys)) {
      var rowGroupCol = rowGroupCols[groupKeys.length];

      var groupBy = "";

      if (bolLocalHost) {
        groupBy = ' GROUP BY "' + rowGroupCol.id + '"';
      } else {
        groupBy = " GROUP BY " + rowGroupCol.id;
      }

      if (request.pivotMode) {
        var pivotCols = request.pivotCols;

        pivotCols.forEach(function (valueCol) {
          if (bolLocalHost) {
            groupBy += ', "' + valueCol.id + '"';
          } else {
            groupBy += ", " + valueCol.id;
          }
        });
      }

      return groupBy;
    }

    return "";
  }

  function autoSizeAll(gridOptions, skipHeader) {
    const allColumnIds = [];

    var valueCols = gridOptions.getAllDisplayedColumns();

    valueCols.forEach((column) => {
      allColumnIds.push(column.getId());
    });

    gridOptions.autoSizeColumns(allColumnIds, skipHeader);

    // valueCols = gridOptions.getColumnDefs();

    // valueCols.forEach(function (colDef, index) {
    //   colDef.maxWidth = 2000;

    //   if (!allColumnIds.includes(colDef.field)) colDef.hide = true;
    // });
    //gridOptions.setColumnDefs(valueCols);
  }

  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: "90vh" }} // the Data Grid will fill the size of the parent container
    >
      {rowModelType !== "" && (
        <AgGridReact
          onGridReady={onGridReady}
          ref={gridMain}
          columnDefs={colDefs}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          defaultColDef={defaultColDef}
          enableCharts={true}
          enableRangeSelection={true}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          sideBar={true}
          paginationPageSizeSelector={false}
          cacheBlockSize={paginationPageSize}
          rowModelType={rowModelType}
          suppressAggFuncInHeader={true}
        />
      )}
    </div>
  );
};

export default ViewReport;
