import {
  Close,
  CloseOutlined,
  DeleteOutline,
  EditOutlined,
  Task,
} from "@mui/icons-material";
import { IconButton, Typography, Grid, CircularProgress } from "@mui/material";
import { fetchJobById, jobDelete } from "actions/jobActions";
import { taskGetAll } from "actions/taskActions";
import DataGridTable from "components/Table/DataGrid";
import { convertCamelToNormal, getStatus } from "core/utils/appUtils";
import { get, isObject } from "lodash";
import JobTeamList from "pages/projects/tabComponents/jobs/JobTeamList";
import ManageJobUsers from "pages/projects/tabComponents/jobs/ManageJobUsers";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ViewTask } from "routes/loadables";
import { getjobData } from "selectors/jobSelectors";
import { getTasks } from "selectors/taskSelectors";
import AddEditJob from "pages/job/AddNewJob";
import AddEditTask from "pages/task/AddNewTask";
import {
  isProjectManager,
  isSupplierAdmin,
  hasAdminRole,
} from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { uploadFile, fetchFiles } from "actions/documentsActions";
import {
  selectAllFiles,
  selectIsUploading,
  selectIsFetching,
  selectFilesByType,
  selectFilesCount,
  selectUploadError,
} from "selectors/documentsSelectors";
import HiddenOn from "components/Wrappers/HiddenOn";

export const ViewJob = ({
  job,
  jobId = null,
  projectId,
  // setTaskOpen,
  onJobCancel,
}) => {
  const params = useParams();
  const dispatch = useDispatch();

  const allFiles = useSelector(selectAllFiles);
  const isUploading = useSelector(selectIsUploading);
  const isUploadError = useSelector(selectUploadError);
  const appRoleDetails = useContext(AppRoleContext);
  const jobData = useSelector(getjobData);
  const { tasksList } = useSelector(getTasks);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [editJob, setEditJob] = React.useState(false);
  const [addNewTask, setAddNewTask] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [fileLabels, setFileLabels] = React.useState({});
  const [uploadStatus, setUploadStatus] = React.useState({});

  useEffect(() => {
    dispatch(fetchJobById(params.jobId || jobId));
    dispatch(taskGetAll({ jobId: params.jobId || jobId }));
  }, [dispatch, params.jobId, jobId]);

  const handleFileUpload = (file, label) => {
    dispatch(uploadFile(file, label, params.jobId, "Job"));
  };
  useEffect(() => {
    //if(editProperty)
    dispatch(fetchFiles("Job", params.jobId || jobId));
  }, [isUploading]);

  if (addNewTask) {
    return (
      <AddEditTask
        onCancel={() => setAddNewTask(false)}
        parentId={{ jobId: jobData.jobId }}
      />
    );
  }

  return selectedTask && selectedTask.taskId ? (
    <>
      <ViewTask
        task={selectedTask}
        job={jobData}
        projectId={projectId}
        onCancel={() => {
          setSelectedTask(null);
          // setTaskOpen(false);
        }}
      />
    </>
  ) : editJob ? (
    <>
      <AddEditJob
        jobId={editJob}
        selectedJob={{ data: jobData }}
        projectId={{ projectId }}
        title={`Edit Details of "${get(jobData, "jobName", null)}"`}
        propData={{ projectId: projectId }}
        onCancel={() => {
          setEditJob(false);
        }}
        onSave={() => {
          dispatch(fetchJobById(params.jobId || jobId));
        }}
      />
    </>
  ) : (
    <>
      <div className="d-flex justify-content-between">
        <Typography className="h1 mt-3">
          {jobData.jobName || "View Job Name"}
        </Typography>
        <IconButton>
          <Close
            onClick={() => {
              if (onJobCancel) {
                onJobCancel();
              }
            }}
          />
        </IconButton>
      </div>
      <div className="d-flex justify-content-end">
        <button
          className="primaryButton icon-btn ms-2"
          style={{ width: "8rem" }}
          onClick={() => {
            setAddNewTask(true);
          }}
        >
          <Task style={{ fontSize: "14px", marginRight: "3px" }} />
          Add Task
        </button>
        <button
          className="secondaryButton icon-btn ms-2"
          style={{ width: "3rem" }}
          onClick={() => {
            setEditJob(true);
          }}
        >
          <EditOutlined style={{ fontSize: "14px" }} />
        </button>

        <button
          className="secondaryButton delete-btn ms-2"
          style={{ width: "3rem" }}
          onClick={() => {
            dispatch(jobDelete({ jobId, onSuccess: () => onJobCancel() }));
          }}
        >
          <DeleteOutline style={{ fontSize: "14px" }} />
        </button>
      </div>
      <div className="d-flex flex-wrap">
        <div className="col-md-7 col-12">
          <div className="m-3 mt-5 mb-5">
            <Typography className="h2">Job Details:</Typography>
            <Typography className="body1">{jobData.projectDetails}</Typography>
          </div>
          <div className="d-flex flex-wrap">
            {Object.keys(jobData).map((key) => {
              return (
                <div className="m-3 col-3">
                  <Typography className="body1">
                    <strong>{convertCamelToNormal(key)}</strong>
                  </Typography>
                  <Typography style={{ wordBreak: "all" }} className="body1">
                    {key === "siteLocation" ? (
                      <a
                        href={jobData[key]}
                        target="_blank"
                        rel="noreferrer"
                        className="link-text"
                      >
                        View Location
                      </a>
                    ) : isObject(jobData[key]) ? (
                      ""
                    ) : (
                      jobData[key]
                    )}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-5">
          <div className="col-12 m-3 mt-5 mb-5">
            <Typography className="h3">Tasks</Typography>

            <div>
              <Typography className="subtitle1 mt-2 mb-2">
                List of Tasks associated with the Job
              </Typography>
            </div>
            <div className="" style={{ width: "100%" }}>
              <div className="p-0">
                <DataGridTable
                  columns={[
                    {
                      field: "header",
                      headerName: "Task Name",
                      width: 150,
                      sortable: false,
                      editable: false,
                      resizable: false,
                    },
                    {
                      field: "job",
                      headerName: "Job Name",
                      width: 150,
                      sortable: false,
                      editable: false,
                      resizable: false,
                      renderCell: (params) => {
                        return params.row.job.jobName;
                      },
                    },
                    {
                      field: "taskStatus",
                      headerName: "Task Status",
                      width: 150,
                      sortable: false,
                      editable: false,
                      resizable: false,
                      renderCell: (params) => {
                        return (
                          <>
                            <div>
                              {getStatus(
                                get(
                                  params.row,
                                  "taskStatus.statusName",
                                  "Pending"
                                )
                              )}
                              <span style={{ marginLeft: "5px" }}>
                                {get(
                                  params.row,
                                  "taskStatus.statusName",
                                  "Pending"
                                )}
                              </span>
                            </div>
                          </>
                        );
                      },
                    },
                  ]}
                  data={tasksList}
                  checkboxSelection={false}
                  loading={false}
                  pageSize={10}
                  showToolbar={false}
                  pagination={false}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  defineRowId={(row) => row.taskId}
                  onRowClick={(row) => {
                    setSelectedTask(row.row);
                    // setTaskOpen(true);
                    // navigate(`/project/${projectId}/tasks/${row.row.taskId}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Typography className="h2 text-center">Assign User</Typography>
        <>
          <AssignJobToUser jobId={jobId} projectId={projectId} />
        </>
      </div>

      {/* {Document upload } */}

      <HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
        <div className="d-flex flex-wrap col-12 pt-5 mb-5">
          <div className={"col-12 "}>
            {/* <div className="d-flex flex-wrap col-12"> */}
            <h5>Project Documents</h5>

            <FileUpload handleFileUpload={handleFileUpload} />

            {/* Pass the files, labels, upload status, and existing files to FilesList */}
            {!isUploading && (
              <FilesList
                files={allFiles}
                fileLabels={fileLabels}
                uploadStatus={uploadStatus}
              />
            )}
            {isUploading && (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "50vh" }}
              >
                <Grid item xs={3}>
                  <CircularProgress size="3rem" />
                </Grid>
              </Grid>
            )}

            {/* </div> */}
          </div>
        </div>
      </HiddenOn>
    </>
  );
};

export const AssignJobToUser = ({ jobId, projectId }) => {
  const appRoleDetails = useContext(AppRoleContext);

  if (isProjectManager(appRoleDetails)) {
    return (
      <>
        <ManageJobUsers jobRefId={jobId} projectId={projectId} />
        <JobTeamList projectRefId={jobId} title={""} />
      </>
    );
  }
  // if (isSupplierAdmin(appRoleDetails))
  // 	return (
  // 		<>
  // 			<ManageJobUsers jobRefId={jobId} projectId={projectId} />
  // 			<JobTeamList projectRefId={jobId} title={""} />
  // 		</>
  // 	);
  return null;
};
export default ViewJob;
