import React, { useEffect, useState } from "react";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";

// import logo from "assets/app/logo_tagline.jpeg";
import logo from "assets/app/logo_tagline.png";

import emailSentLogo from "assets/loginRegistration/email-sent.png";

import { getText } from "core/utils/getContentText";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	loginAction,
	signUpEmailVerify,
} from "actions/login_registrationActions";
import { BackdropLoader } from "components/Loader";
import { appLoadingSelector, loginErrorSelector } from "selectors/appSelector";
import SocialLogins from "./SocialLogins";
import { emailRegex } from "constants/appConstants";
import { SnackBarWithInfoAlert } from "components/Alerts/AlertSnackbar";
import "./login.scss";
import ImageCarousel from "./ImageSlider";
import { isActivateUserSignIn } from "core/utils/appUtils";

export default function Login() {
	const dispatch = useDispatch();

	const isLoading = useSelector(appLoadingSelector);
	const loginError = useSelector(loginErrorSelector);

	const [credentials, setCredentials] = useState({});
	const [rememberEmail, setRememberEmail] = useState(false);

	const [showSignUp, setShowSignUp] = useState(false);
	const [emailPreExists, setEmailPreExists] = useState({});
	const [emailSent, setEmailSent] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [emailAddress, setEmailAddress] = useState(null);

	const history = useNavigate();

	useEffect(() => {
		if (isActivateUserSignIn()) {
			history("/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const existingUsername = localStorage.getItem("builderCopilotUsername");

		if (existingUsername) {
			setCredentials({
				username: existingUsername,
			});
			setRememberEmail(true);
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!showSignUp) {
			dispatch(loginAction(credentials, history));
		}
	};

	useEffect(() => {}, [credentials]);

	const handleRememberEmail = (e) => {
		e.persist();
		if (e.target.checked) {
			localStorage.setItem("builderCopilotUsername", credentials.username);
			setRememberEmail(true);
		} else {
			localStorage.removeItem("builderCopilotUsername");
			setRememberEmail(false);
		}
	};

	return (
		<>
			<BackdropLoader open={isLoading} />
			<>
				<SnackBarWithInfoAlert
					open={showAlert}
					alertSeverity={emailPreExists?.emailExists ? "error" : "success"}
					alertMessage={emailPreExists?.message}
					handleClose={() => {
						setShowAlert(false);
						if (!emailPreExists?.emailExists) setShowSignUp(false);
						setEmailPreExists({});
					}}
				/>
				<form onSubmit={handleSubmit}>
					<div className="row vh-100">
						<div className="col-md-5 paddingNone login-section">
							<div className="login-block">
								<div className="login-logo-section">
									<img
										className="logo"
										src={logo}
										alt={"BuilderCopilot_logo"}
									/>
									<p className="login-text">
										{emailSent
											? "Check your inbox to login"
											: showSignUp
											? "Sing up for your account"
											: "Log in to your account"}
									</p>
								</div>
								{loginError.status === "error" && (
									<p className="error-text">{loginError.message}</p>
								)}
								{emailSent ? (
									<div className="text-center email-sent-response-section">
										<div className="">
											<img
												style={{ width: "20%" }}
												src={emailSentLogo}
												alt="email-sent"
											/>
										</div>
										<div className="mt-4 mb-4">
											<p>{`${getText("emailSentMessage")} ${emailAddress}`}</p>
										</div>
										<div className="mt-4 mb-4">
											<p
												className="link-text"
												onClick={() => {
													dispatch(
														signUpEmailVerify(
															{
																eMailAddress: emailAddress,
															},
															(returnPayload, hasEmailSent) => {
																setEmailPreExists(returnPayload);
																setShowAlert(true);
																setEmailSent(hasEmailSent);
															},
															true
														)
													);
												}}
											>
												Resend Verification email{" "}
											</p>
										</div>
									</div>
								) : (
									<div>
										<>
											{showSignUp ? (
												<div className="login-form-section">
													<div className="login-formField">
														<p className="grey-label">Email</p>
														<TextField
															className="primaryInput singup-email-field"
															placeholder="Enter Your Email"
															onChange={(e) => {
																e.preventDefault();
																setEmailAddress(e.target.value);
															}}
															variant="outlined"
														/>
													</div>
													<div className="terms-conditions-text">
														<p>
															By Clicking "Continue below", you agree to the
															Builder Copilot{" "}
															<span className="link-text">
																Terms of service{" "}
															</span>
															and acknowledge the{" "}
															<span className="link-text">Privacy Policy</span>
														</p>
													</div>

													<div
														className="login-button-section"
														style={{ textAlign: "center" }}
													>
														<button
															variant="outlined"
															className={`primaryButton login-button  ${
																emailRegex.test(emailAddress)
																	? ""
																	: "disabledButton"
															}`}
															// disabled={!emailRegex.test(emailAddress)}
															onClick={() => {
																dispatch(
																	signUpEmailVerify(
																		{
																			eMailAddress: emailAddress,
																		},
																		(returnPayload, hasEmailSent) => {
																			setEmailPreExists(returnPayload);
																			setShowAlert(true);
																			setEmailSent(hasEmailSent);
																		}
																	)
																);
															}}
														>
															Continue
														</button>
													</div>
													<div className="mt-2 mb-2">
														<p
															className="link-text"
															onClick={() => {
																setShowSignUp(false);
															}}
														>
															Already have an account ? Login
														</p>
													</div>
												</div>
											) : (
												<>
													<div className="login-form-section">
														<div className="login-formField">
															<p className="grey-label">Email</p>
															<TextField
																variant="outlined"
																required
																placeholder="Enter your Company email"
																type="email"
																className="primaryInput"
																value={credentials.username}
																onChange={(e) => {
																	setCredentials({
																		...credentials,
																		username: e.target.value,
																	});
																}}
															/>
														</div>
														<div className="login-formField">
															<p className="grey-label">Password</p>
															<TextField
																variant="outlined"
																className="primaryInput"
																required
																placeholder="Enter Password"
																type="password"
																onChange={(e) => {
																	setCredentials({
																		...credentials,
																		password: e.target.value,
																	});
																}}
															/>
														</div>
														<div className="login-formField">
															<FormControlLabel
																control={
																	<Checkbox
																		className={"remember-me-checkbox"}
																		checked={rememberEmail}
																		onChange={handleRememberEmail}
																	/>
																}
															/>
															<span className="grey-label remember-me-text">
																Remember Email
															</span>
														</div>
													</div>
													<div className="login-button-section">
														<button
															type="submit"
															variant="contained"
															className={`login-button primaryButton`}
														>
															Log in
														</button>
														<div>
															<p className="forgot-password">
																Forgot your Password ?
															</p>
														</div>
													</div>
												</>
											)}
											<div className="cl-or-divider">
												<span> OR </span>
											</div>
											<SocialLogins />

											{!showSignUp && (
												<div className="login-signup-section">
													<div className="signup-now">
														<p className="login-text">
															Don't have an account ?
														</p>
														<p
															className="signup-now-text"
															onClick={() => setShowSignUp(true)}
														>
															Sign up now!
														</p>
													</div>
												</div>
											)}
										</>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-7 paddingNone">
							<div className="login-ad-block">
								<ImageCarousel />
							</div>
						</div>
					</div>
				</form>
			</>
		</>
	);
}
