import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import { useDropzone } from "react-dropzone"; // Import Dropzone

const FileUpload = ({ handleFileUpload }) => {
  const [files, setFiles] = useState([]);
  const [fileLabels, setFileLabels] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [previewFile, setPreviewFile] = useState(null); // State for file preview

  const allowedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
    "text/csv",
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/vnd.dwg",
    "application/vnd.dxf",
    "application/vnd.dgn",
    "message/rfc822", // Email files (.eml)
    "application/zip", // Email attachments
  ];

  // Handle file selection from both the button and drag-and-drop
  const handleFileSelection = (selectedFiles) => {
    const newFiles = selectedFiles.filter(
      (file) =>
        file.size < 10 * 1024 * 1024 && // Ensure file size < 10MB
        allowedFileTypes.includes(file.type)
    );

    if (newFiles.length !== selectedFiles.length) {
      alert("Some files were rejected due to size or unsupported format.");
    }

    const newUploadStatus = {};
    newFiles.forEach((file, index) => {
      newUploadStatus[index] = "uploading"; // Initially mark file as uploading
      //simulateUpload(file, fileIndex); // Start upload simulation
      //handleFileUpload(file);
    });

    setFiles((prevFiles) => [...newFiles, ...prevFiles]);
    setUploadStatus((prevStatus) => ({ ...newUploadStatus, ...prevStatus }));
  };

  // Handle label change
  const handleLabelChange = (event, index) => {
    const { value } = event.target;
    setFileLabels((prevLabels) => ({
      ...prevLabels,
      [index]: value,
    }));
  };

  // Handle file removal
  // const handleRemoveFile = (index) => {
  //   setFiles(files.filter((_, i) => i !== index));
  //   setFileLabels((prevLabels) => {
  //     const newLabels = { ...prevLabels };
  //     delete newLabels[index];
  //     return newLabels;
  //   });
  //   setUploadStatus((prevStatus) => {
  //     const newStatus = { ...prevStatus };
  //     delete newStatus[index];
  //     return newStatus;
  //   });
  // };

  const proceedToUpload = (index) => {
    handleFileUpload(files[index], fileLabels[index]);

    setFileLabels((prevLabels) => {
      const newLabels = { ...prevLabels };
      delete newLabels[index];
      return newLabels;
    });
    setFiles(files.filter((_, i) => i !== index));
  };

  // Simulate file upload
  // const simulateUpload = (file, index) => {
  //   setTimeout(() => {
  //     setUploadStatus((prevStatus) => ({
  //       ...prevStatus,
  //       [index]: "completed", // Mark file as uploaded
  //     }));

  //     // Clear the green tick after 3 seconds
  //     setTimeout(() => {
  //       setUploadStatus((prevStatus) => ({
  //         ...prevStatus,
  //         [index]: null, // Clear upload status
  //       }));
  //     }, 3000); // 3 seconds delay to clear green tick
  //   }, 2000); // Simulate 2 seconds upload time
  // };

  // Dropzone configuration for drag-and-drop
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => handleFileSelection(acceptedFiles),
    accept: allowedFileTypes.join(","),
    maxSize: 10 * 1024 * 1024, // Max file size of 10MB
  });

  return (
    <Box sx={{ margin: "0 auto", padding: 2 }}>
      <div {...getRootProps()} style={{ marginBottom: "16px" }}>
        <Paper
          elevation={isDragActive ? 6 : 2}
          sx={{
            padding: 3,
            border: isDragActive ? "2px dashed #3f51b5" : "2px dashed #ccc",
            textAlign: "center",
            backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="h6" color="primary">
              Drop the files here...
            </Typography>
          ) : (
            <Typography variant="h6">
              Drag & Drop files here, or click to select files
            </Typography>
          )}
        </Paper>
      </div>

      <br />
      <Typography variant="caption" gutterBottom fontSize={"12px"}>
        Upload Files (Max: 10MB, Formats: PDF, Word, Excel, PPT, PNG, JPEG, EML,
        TXT, CSV, DWG, DXF)
      </Typography>

      {files.length > 0 && (
        <Box mt={2}>
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid item xs={12} md={8} key={index}>
                <Box display="flex" alignItems="center">
                  <Box width="40%">
                    <Typography noWrap>{file.name}</Typography>
                  </Box>
                  <Box width="40%" mx={2}>
                    <TextField
                      label="Label"
                      variant="outlined"
                      size="small"
                      value={fileLabels[index] || ""}
                      onChange={(event) => handleLabelChange(event, index)}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => proceedToUpload(index)}
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
