import * as Icons from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faShop,
	faFileInvoiceDollar,
	faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { roleConstants as roles } from "core/utils/roleUtils";

export const supplierAdminMenu = [
	{
		id: 0,
		label: "Dashboard",
		link: "/dashboard",
		icon: <Icons.SpaceDashboardOutlined />,
	},
	{
		id: 0,
		label: "Jobs",
		link: "/jobs",
		icon: <Icons.WorkHistoryOutlined />,
		roles: [
			roles.SUPPLIER_ADMIN,
			roles.SUPPLIER_GENERAL_MANAGER,
			roles.SUPPLIER_TEAM_MEMBER,
			roles.SUPPLIER_JOB_MANAGER,
			roles.SUPPLIER_PROJECT_MEMBER,
		],
	},
	{
		id: 25,
		label: "Orders History",
		icon: <Icons.PaymentsOutlined />,
		children: [
			{
				id: 211,
				label: "Purchase Orders",
				link: "/supplier/purchaseOrders",
				icon: (
					<FontAwesomeIcon
						icon={faShop}
						style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
					/>
				),
			},
			{
				id: 212,
				label: "Invoices",
				link: "/supplier/invoiceOrders",
				icon: (
					<FontAwesomeIcon
						icon={faFileInvoiceDollar}
						style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
					/>
				),
			},
			{
				id: 213,
				label: "Payments",
				link: "/supplier/payments",
				icon: (
					<FontAwesomeIcon
						icon={faDollarSign}
						style={{ fontSize: "1.7rem", marginLeft: "1rem", color: "white" }}
					/>
				),
			},
		],
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},

	{
		id: 24,
		label: "Builders",
		link: "/supplier/connections",
		icon: <Icons.Inventory2Outlined />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: 28,
		label: "Reports",
		link: "/reports",
		icon: <Icons.Summarize />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: 27,
		label: "Timelines",
		link: "/timelines",
		icon: <Icons.TimelineOutlined />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: 33,
		label: "TimeSheets",
		link: "/timesheet",
		icon: <Icons.PunchClockOutlined />,
	},
	{
		id: 34,
		label: "Users",
		link: "/users",
		icon: <Icons.PeopleOutlineOutlined />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: 26,
		label: "Estimates",
		// link: "/estimatesApprovals",
		link: "/supplier/estimations",
		icon: <Icons.ApprovalOutlined />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: `sysAdmin12`,
		label: "Change Requests",
		// link: "/changeRequests",
		link: "/page/underDevelopment",
		icon: <Icons.BorderAllOutlined />,
		roles: [roles.SUPPLIER_ADMIN, roles.SUPPLIER_GENERAL_MANAGER],
	},
	{
		id: `sysAdmin13`,
		label: "Settings",
		// link: "/settings",
		link: "/page/underDevelopment",
		icon: <Icons.SettingsOutlined />,
	},
	{
		id: `sysAdmin14`,
		label: "Signout",
		link: "/login",
		icon: <Icons.LogoutOutlined />,
		onClick: () => {
			localStorage.removeItem("token");
		},
	},
];
