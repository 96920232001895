import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { deleteJobUserRole, fetchJobUserRoles } from "actions/jobActions";
import HiddenOn from "components/Wrappers/HiddenOn";
import AppRoleContext from "context/AppRoleContext";
import { isJobManager } from "core/utils/roleUtils";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobUserRolesList } from "selectors/jobSelectors";

export const JobTeamList = ({ projectRefId, title = null }) => {
	const dispatch = useDispatch();

	const { jobUsersRolesList } = useSelector(getJobUserRolesList);
	const appRoleDetails = useContext(AppRoleContext);

	useEffect(() => {
		dispatch(fetchJobUserRoles(projectRefId));
	}, [dispatch, projectRefId]);

	return (
		<div>
			<div className="assigned-users-list mt-4">
				<Typography className="h4">
					{title || "Assigned Users For Project"}
				</Typography>
				<table className="mt-3">
					<thead>
						<tr>
							<th>Team Member</th>
							<th>Email</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{jobUsersRolesList.length > 0 &&
							jobUsersRolesList.map((userRole) => {
								return (
									<tr>
										<td>{`${userRole.user.firstName} ${userRole.user?.lastName}`}</td>
										<td>
											<Typography variant="body1">
												{userRole.user.email}
											</Typography>
										</td>
										<td>
											<HiddenOn isHidden={!isJobManager(appRoleDetails)}>
												<IconButton
													onClick={() => {
														dispatch(
															deleteJobUserRole(
																userRole.jobUserId,
																projectRefId
															)
														);
													}}
												>
													<Delete style={{ fontSize: "1rem" }} />
													<Typography variant="body1">Remove</Typography>
												</IconButton>
											</HiddenOn>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default JobTeamList;
