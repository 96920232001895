import {
  React,
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  createRef,
} from "react";
import useToken from "../useToken";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";

import { server_url } from "../../constants/types";

const DynamicDropdown = forwardRef((props, ref) => {
  //forwardRef(({ options, onChange, width, dataType, controlID, idColumn, nameColumn, lable, value, filter, ref }) => {

  const [optionValues, setOptions] = useState([]);
  const { token, setToken } = useToken();

  useImperativeHandle(ref, () => ({
    UpdateOptions: UpdateOptions,
    UpdateFilter: UpdateFilter,
  }));

  useEffect(() => {
    async function fetchData(dataType) {
      fetch(
        `${server_url}/generic/getdropdownvalues?DataType=` +
          dataType +
          "&Filter=" +
          encodeURIComponent(props.filter),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          const results = [];
          // Store results in the results array
          setOptions(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    // Trigger the fetch
    fetchData(props.dataType);
  }, []);

  function UpdateOptions(options) {
    setOptions(options);
  }

  function UpdateFilter(filter) {
    fetch(
      `${server_url}/generic/getdropdownvalues?DataType=` +
        props.dataType +
        "&Filter=" +
        encodeURIComponent(filter),
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        const results = [];
        // Store results in the results array
        setOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  return (
    <>
      <InputLabel
        id={"lbl" + props.controlID}
        shrink={props.value !== null && props.value !== ""}
      >
        {" "}
        {props.lable}{" "}
      </InputLabel>

      <Select
        native
        onChange={(event) => {
          props.onChange(event);
        }}
        text="DynamicDropdown"
        style={{ width: props.width }}
        id={props.controlID}
        name={props.controlID}
        value={props.value}
        labelId={"lbl" + props.controlID}
      >
        <option></option>
        {props.options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}

        {optionValues.map((option, index) => (
          <option value={option[props.idColumn]}>
            {option[props.nameColumn]}
          </option>
        ))}
      </Select>
    </>
  );
});
export default DynamicDropdown;
