import axios from "axios";
import {
	TASK_LOADING,
	TASK_GET_ALL_SUCCESS,
	TASK_ERROR,
	TASK_CLEAR_SNACKBAR,
} from "../constants/types";

import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";

export const addNewTask = (formData, onSuccess) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_TASK,
				payload: {
					loading: true,
				},
			});
			request({
				method: formData.taskId ? "PUT" : "POST",
				url: `${server_url}/tasks${
					formData.taskId ? `/${formData.taskId}` : ""
				}`,
				body: {
					...formData,
					actualCost: parseInt(formData.actualCost),
					plannedBudget: parseInt(formData.plannedBudget),
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_TASK_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Task Added Successfully",
							loading: false,
						},
					});
					onSuccess();
					dispatch(taskGetAll());
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_TASK_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const taskGetAll = (params = {}) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: TASK_LOADING,
				payload: {
					loading: true,
				},
			});
			const queryParams = queryString.stringify(params);
			request({ url: `${server_url}/tasks?${queryParams}` })
				.then((response) => {
					dispatch({
						type: TASK_GET_ALL_SUCCESS,
						payload: {
							taskList: response.data.items,
							totalCount: response.data.totalCount,
							loading: false,
							status: "success",
							text: "Get All TASK data successfully.",
						},
					});
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: TASK_ERROR,
						payload: {
							status: "error",
							text: "Error occured during getting TASK data.",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchTaskById = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_TASK_BY_ID,
				payload: {
					loading: true,
				},
			});
			dispatch({
				type: ActionConstants.LOADING_APP,
			});
			request({
				method: "GET",
				url: `${server_url}/tasks/${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_TASK_BY_ID_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_SUCCESS,
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_TASK_BY_ID_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_ERROR,
					});
					resolve();
				});
		});
	};
};

export const taskDelete = ({ taskId, params }) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: TASK_LOADING,
				payload: {
					loading: true,
				},
			});
			request({
				url: `${server_url}/tasks/${taskId}?${queryString.stringify(params)}`,
				method: "DELETE",
			})
				.then((response) => {
					fetch(taskGetAll(params));
					resolve();
				})
				.catch((e) => {
					dispatch({
						type: TASK_ERROR,
						payload: {
							text: "Error occured during deleteing TASK data.",
							status: "error",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const taskAddOrUpdate = (task, state) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			dispatch({
				type: TASK_LOADING,
				payload: {
					loading: true,
				},
			});
			if (state === "add") {
				axios
					.post(`${server_url}/task`, task)
					.then((response) => {
						axios
							.get(`${server_url}/task`)
							.then((response) => {
								dispatch({
									type: TASK_GET_ALL_SUCCESS,
									payload: {
										taskList: response.data,
										loading: false,
										status: "success",
										text: "Add TASK data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: TASK_ERROR,
									payload: {
										text: "Error occured during getting TASK data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: TASK_ERROR,
							payload: {
								text: "Error occured during adding TASK data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			} else if (state === "edit") {
				axios
					.put(`${server_url}/task`, task)
					.then((response) => {
						axios
							.get(`${server_url}/task`)
							.then((response) => {
								dispatch({
									type: TASK_GET_ALL_SUCCESS,
									payload: {
										taskList: response.data,
										loading: false,
										status: "success",
										text: "Update TASK data successfully.",
									},
								});
								resolve();
							})
							.catch((e) => {
								dispatch({
									type: TASK_ERROR,
									payload: {
										text: "Error occured during getting TASK data.",
										status: "error",
										loading: false,
									},
								});
								resolve();
							});
					})
					.catch((e) => {
						dispatch({
							type: TASK_ERROR,
							payload: {
								text: "Error occured during updating TASK data.",
								status: "error",
								loading: false,
							},
						});
						resolve();
					});
			}
		});
	};
};

export const taskClearShowSnackbar = () => {
	return (dispatch, getState) => {
		dispatch({
			type: TASK_CLEAR_SNACKBAR,
		});
	};
};
