import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RegistrationPage from "pages/login/RegistrationPage";
import LoginPage from "pages/login/Login";
import AppWrapper from "./AppWrapper";
import { Typography } from "@mui/material";
import { InviteUsers } from "routes/loadables";

function Layout2() {
	return (
		<div>
			<div>
				<Routes>
					<Route path="/login" element={<LoginPage />} />
					<Route path="/app/registerForm" element={<RegistrationPage />} />
					<Route path="/users/inviteUser/:GUID" element={<InviteUsers />} />
					,
					<Route path="*" element={<AppWrapper />} />
				</Routes>
			</div>
		</div>
	);
}

export default Layout2;
