import { createSelector } from "reselect";
import get from "lodash/get";

const selectState = (state) => state.user;

export const getUsersList = createSelector(selectState, (user) =>
	get(user, "usersList", [])
);

export const getUsersLoading = createSelector(selectState, (user) =>
	get(user, "usersLoading", false)
);

export const getProjectRolesList = createSelector(selectState, (user) => ({
	projectRolesList: get(user, "projectRoles", []),
	projectRolesLoading: get(user, "projectRolesLoading", false),
}));

export const getProjectUserRolesList = createSelector(selectState, (user) => ({
	projectUsersRolesList: get(user, "projectUserRoles", []),
	projectUsersRolesLoading: get(user, "projectRolesLoading", false),
}));

export const getTimesheetData = createSelector(selectState, (user) => ({
	timesheetData: get(user, "timesheetData", []),
	totalCount: get(user, "totalTimesheetEntries", 0),
}));

export const getGuidDetails = createSelector(selectState, (user) => ({
	guidDetails: get(user, "guidDetails", {}),
	invitationLinkError: get(user, "inviteLinkError", null),
}));
