import { Close, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { fetchTaskById, taskDelete } from "actions/taskActions";
import { convertCamelToNormal } from "core/utils/appUtils";
import { isObject } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTaskData } from "selectors/taskSelectors";
import AddEditTask from "pages/task/AddNewTask";

export const ViewJob = ({ task, job, projectId, onCancel = null }) => {
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const taskData = useSelector(getTaskData);
	const [editTask, setEditTask] = React.useState(false);

	useEffect(() => {
		dispatch(fetchTaskById(params.taskId || task.taskId));
	}, [dispatch, params.taskId, task.taskId]);

	return editTask ? (
		<AddEditTask
			onCancel={() => setEditTask(false)}
			selectedTask={{ data: taskData }}
			parentId={{ jobId: job.jobId }}
			title={`Edit Details of "${taskData.header}"`}
		/>
	) : (
		<>
			<div className="d-flex justify-content-between">
				<Typography className="h1 mt-3">
					{taskData.header || "View Task"}
				</Typography>
				<IconButton>
					<Close
						onClick={() => {
							if (onCancel) {
								onCancel();
							} else {
								navigate("/projects");
							}
						}}
					/>
				</IconButton>
			</div>
			<div className="d-flex justify-content-end">
				<button
					className="secondaryButton icon-btn ms-2"
					style={{ width: "3rem" }}
					onClick={() => {
						setEditTask(true);
					}}
				>
					<EditOutlined style={{ fontSize: "14px" }} />
				</button>

				<button
					className="secondaryButton delete-btn ms-2"
					style={{ width: "3rem" }}
					onClick={() => {
						dispatch(
							taskDelete({
								taskId: task.taskId,
								params: { taskId: task.taskId },
								// onSuccess: () => onTaskCancel(),
							})
						);
					}}
				>
					<DeleteOutline style={{ fontSize: "14px" }} />
				</button>
			</div>
			<div className="m-3 mt-5 mb-5">
				<Typography className="h2">Task Details:</Typography>
				<Typography className="body1">{taskData.description}</Typography>
			</div>
			<div className="d-flex flex-wrap">
				{Object.keys(taskData).map((key) => {
					return (
						<div className="m-3 col-3">
							<Typography className="body1">
								<strong>{convertCamelToNormal(key)}</strong>
							</Typography>
							<Typography style={{ wordBreak: "all" }} className="body1">
								{key === "siteLocation" ? (
									<a
										href={taskData[key]}
										target="_blank"
										rel="noreferrer"
										className="link-text"
									>
										View Location
									</a>
								) : isObject(taskData[key]) ? (
									""
								) : (
									taskData[key]
								)}
							</Typography>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default ViewJob;
