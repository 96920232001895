import { fetchProjectTimeLinesById } from "actions/projectActions";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Gantt, ViewMode } from "gantt-task-react";
import { getProjectTimelines } from "selectors/projectSelector";
import { projectData as exGantt } from "pages/GanttChart/data";

export const ProjectTimelines = ({ projectId }) => {
	const ganttRef = useRef(null);

	const dispatch = useDispatch();
	const { projectTimelines } = useSelector(getProjectTimelines);

	const formattedTimelineData = (timelineData) => {
		console.log("Check the projectTimelines ", projectTimelines, timelineData);
		const data = timelineData.map((timeline, index) => {
			const typeFormat = {
				project: (data) => ({
					start: new Date(data.startDate),
					end: new Date(data.completionDate || new Date()),
					name: data.name,
					id: `project${index}`,
					progress: timeline.progress,
					type: "project",
					project: data.name,
					hideChildren: false,
				}),
				Job: (data) => ({
					start: new Date(data.startDate),
					end: new Date(data.completionDate || new Date()),
					name: data.name,
					id: `job${index}`,
					progress: data.progress,
					type: "job",
					project: data.projectId,
					// job: data.jobId,
				}),
				Task: (data) => ({
					start: new Date(data.startDate),
					end: new Date(data.completionDate || new Date()),
					name: data.name,
					id: `task${index}`,
					progress: timeline.progress,
					type: "task",
					project: data.projectId,
					job: data.jobId,
					// jobId: timeline.jobId,
				}),
			};
			return typeFormat[timeline.type](timeline);
		});
		console.log("Check the Data : ", data, exGantt);
		return data;
	};

	useEffect(() => {
		dispatch(fetchProjectTimeLinesById(projectId));
	}, [dispatch, projectId]);

	return (
		<div>
			ProjectTimelines
			{projectTimelines.length > 0 ? (
				<Gantt
					tasks={formattedTimelineData(projectTimelines)}
					viewMode={ViewMode.Month}
					ref={ganttRef}
					// {...eventOptions}
				/>
			) : null}
		</div>
	);
};

export default ProjectTimelines;
