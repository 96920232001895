import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import omit from "lodash/omit";
import queryString from "query-string";

export const addUpdateProject = ({
	formData,
	onSuccess = () => {},
	params,
}) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.ADD_NEW_PROJECT,
				payload: {
					loading: true,
				},
			});
			request({
				method: formData.projectId ? "PUT" : "POST",
				url: `${server_url}/project${
					formData.projectId ? `/${formData.projectId}` : ""
				}`,
				body: {
					...omit(formData, ["property"]),
				},
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.ADD_NEW_PROJECT_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					onSuccess(response.data);
					dispatch(fetchProjects({ ...params, pageSize: 10, pageNumber: 1 }));
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.ADD_NEW_PROJECT_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
export const fetchProjects = (params) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_PROJECTS,
				payload: {
					loading: true,
				},
			});
			const query = queryString.stringify(params);
			request({
				method: "GET",
				url: `${server_url}/project?${query}`,
				// projUserRole
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_PROJECTS_SUCCESS,
						payload: {
							response: response.data.items,
							totalCount: response.data.totalCount,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					// dispatch(ProjectGetAll());
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_PROJECTS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchProjectById = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_PROJECT_BY_ID,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/project/${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_PROJECT_BY_ID_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_PROJECT_BY_ID_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchProjectTimeLinesById = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_TIMELINES_BY_PROJECT_ID,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/project/timeLineData/${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_TIMELINES_BY_PROJECT_ID_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_TIMELINES_BY_PROJECT_ID_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const deleteProjectById = (id, pageModel) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.DELETE_PROJECT_BY_ID,
				payload: {
					loading: true,
				},
			});
			request({
				method: "DELETE",
				url: `${server_url}/project/${id}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.DELETE_PROJECT_BY_ID_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					dispatch(fetchProjects(pageModel));
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.DELETE_PROJECT_BY_ID_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const fetchTimelines = (id) => {
	return (dispatch) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.GET_TIMELINES,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/project/timeLineData`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.GET_TIMELINES_SUCCESS,
						payload: {
							response: response.data,
							status: "success",
							message: "Project Added Successfully",
							loading: false,
						},
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.GET_TIMELINES_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
