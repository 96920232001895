import React, { useEffect, useState } from "react";
import createSignalRConnection from "components/Alerts/NotificationsAlerts";

export const AlertNotifications = () => {
	const [messages, setMessages] = useState([]);
	const hubUrl = "https://your-backend-url/notificationHub"; // Replace with your SignalR hub URL

	useEffect(() => {
		const connection = createSignalRConnection(hubUrl);

		// Start the connection
		connection
			.start()
			.then(() => {
				console.log("Connected to SignalR hub");

				// Listen for messages from the server
				connection.on("ReceiveNotification", (message) => {
					setMessages((prevMessages) => [...prevMessages, message]);
				});
			})
			.catch((err) =>
				console.error("Error while starting SignalR connection:", err)
			);

		// Clean up on component unmount
		return () => {
			connection.stop();
		};
	}, [hubUrl]);

	return (
		<div>
			<h2>Notifications</h2>
			<ul>
				{messages.map((msg, index) => (
					<li key={index}>{msg}</li>
				))}
			</ul>
		</div>
	);
};

export default AlertNotifications;
