import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import EmailIcon from "@mui/icons-material/Email";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import * as XLSX from "xlsx";
import { server_url } from "../../constants/types";

const FilesList = ({ files, fileLabels, uploadStatus }) => {
  const [previewFile, setPreviewFile] = useState(null);
  const [excelData, setExcelData] = useState(null);

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfIcon color="error" />;
      case "docx":
      case "csv":
        return <DescriptionIcon color="primary" />;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return <SlideshowIcon color="secondary" />;
      case "png":
      case "jpeg":
      case "jpg":
        return <ImageIcon color="action" />;
      case "txt":
        return <TextSnippetIcon color="action" />;
      case "message/rfc822": // Email file
        return <EmailIcon color="info" />;
      default:
        return <InsertDriveFileIcon />; // Default file icon
    }
  };

  const getUploadIcon = (status) => {
    if (status === "uploading") {
      return <CircularProgress size={24} />;
    } else if (status === "completed") {
      return <CheckCircleIcon color="success" />;
    }
    return null;
  };

  const handlePreview = (file) => {
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      handleExcelPreview(file.url); // Use URL from the server
    } else if (
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    ) {
      handleOfficePreview(file.url); // Use URL from the server
    } else {
      setPreviewFile(file);

      console.log(
        "URL",
        encodeURI(
          `${server_url}/Documents/Download?fileName=${file.fileName}&folderName=${file.filePath}&downloadPath=C:\Users\Venkatesh\Downloads`
        )
      );
    }
  };

  const handleExcelPreview = (url) => {
    fetch(url)
      .then((res) => res.arrayBuffer())
      .then((data) => {
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(jsonData);
        setPreviewFile({ name: url.split("/").pop(), type: "excel", url });
      });
  };

  const handleOfficePreview = (url) => {
    setPreviewFile({ name: url.split("/").pop(), url, type: "office" });
  };

  return (
    <Box sx={{ margin: "0 auto", paddingTop: 2 }}>
      <h5>Uploaded Documents</h5>

      <List sx={{ width: "70%" }}>
        {files.length > 0 &&
          files?.map((file, index) => (
            <ListItem key={index}>
              <ListItemIcon>{getFileIcon(file.type)}</ListItemIcon>
              <ListItemText
                primary={file.fileName}
                secondary={file.documentLabel}
              />
              <ListItemIcon>{getUploadIcon(uploadStatus[index])}</ListItemIcon>
              <Link href="#" onClick={() => handlePreview(file)}>
                Preview
              </Link>
            </ListItem>
          ))}

        {files.length === 0 && (
          <Typography
            sx={{ justifyContent: "center", display: "flex" }}
            fontWeight={"bold"}
          >
            No Documents Found...
          </Typography>
        )}
      </List>

      {previewFile && (
        <Dialog
          open={!!previewFile}
          onClose={() => setPreviewFile(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Preview: {previewFile.name}</DialogTitle>
          <DialogContent>
            {previewFile.type === "excel" && (
              <div>
                <Typography variant="h6">Excel File Preview</Typography>
                {excelData ? (
                  <table>
                    <thead>
                      <tr>
                        {Object.keys(excelData[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {excelData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((value, cellIndex) => (
                            <td key={cellIndex}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <Typography>No data available.</Typography>
                )}
              </div>
            )}

            {previewFile.type === "office" && (
              <iframe
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                  previewFile.url
                )}`}
                width="100%"
                height="500px"
                frameBorder="0"
                title="Office Preview"
              />
            )}

            {previewFile.type.startsWith("image/") && (
              <img
                src={previewFile.url}
                alt={previewFile.name}
                style={{ maxWidth: "100%" }}
              />
            )}
            {previewFile.type === "application/pdf" && (
              <>
                <a
                  href={encodeURI(
                    `${server_url}/Documents/Download?fileName=${previewFile.fileName}&folderName=${previewFile.filePath}&downloadPath=C:\Users\Venkatesh\Downloads`
                  )}
                >
                  Open the file
                </a>

                <h4>Testing</h4>
                {/* <embed
                  src={encodeURI(
                    `${server_url}/Documents/Download?fileName=${previewFile.fileName}&folderName=${previewFile.filePath}&downloadPath=C:\Users\Venkatesh\Downloads`
                  )}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                /> */}
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default FilesList;
