import {
  EMPLOYEE_LOADING,
  EMPLOYEE_GET_ALL_SUCCESS,
  EMPLOYEE_ERROR,
  EMPLOYEE_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
  employeeList: [],
  loading: true,
  status: "success",
  text: "",
  showSnackbar: false,
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_CLEAR_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
      };
    case EMPLOYEE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_ERROR:
      return {
        ...state,
        employeeList: [],
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    case EMPLOYEE_GET_ALL_SUCCESS:
      return {
        ...state,
        employeeList: action.payload.employeeList,
        loading: false,
        status: action.payload.status,
        text: action.payload.text,
        showSnackbar: true,
      };
    default:
      return state || {};
  }
};

export default employeeReducer;
