export const statesList = [
	"Alabama",
	"Alaska",
	"Arizona",
	"Arkansas",
	"California",
	"Colorado",
	"Connecticut",
	"Delaware",
	"Florida",
	"Georgia",
	"Hawaii",
	"Idaho",
	"Illinois",
	"Indiana",
	"Iowa",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Maine",
	"Maryland",
	"Massachusetts",
	"Michigan",
	"Minnesota",
	"Mississippi",
	"Missouri",
	"Montana",
	"Nebraska",
	"Nevada",
	"NewHampshire",
	"NewJersey",
	"NewMexico",
	"NewYork",
	"NorthCarolina",
	"NorthDakota",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"RhodeIsland",
	"SouthCarolina",
	"SouthDakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Vermont",
	"Virginia",
	"Washington",
	"WestVirginia",
	"Wisconsin",
	"Wyoming",
];
