import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	Typography,
	Button,
	styled,
	CircularProgress,
	Grid,
	Snackbar,
	Alert,
	Paper,
	Divider,
} from "@mui/material";
import {
	ArrowBackOutlined,
	Edit,
	EngineeringOutlined,
	CloudUpload,
} from "@mui/icons-material";
import AppRoleContext from "context/AppRoleContext";
import { getPropertyById } from "../../actions/propertyActions";
import { getPropertyData } from "selectors/propertySelectors";

import {
	selectAllFiles,
	selectIsUploading,
	selectIsFetching,
	selectFilesByType,
	selectFilesCount,
	selectUploadError,
} from "selectors/documentsSelectors";
import AddNewProperty from "./AddNewProperty";
import { convertCamelToNormal } from "core/utils/appUtils";
import { isObject, omit } from "lodash";
import AddNewProject from "pages/projects/AddNewProject";
import HiddenOn from "components/Wrappers/HiddenOn";
import { hasAdminRole } from "core/utils/roleUtils";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { uploadFile, fetchFiles } from "actions/documentsActions";
import pick from "lodash/pick";
import moment from "moment";
import SiteLocationMap from "./SiteLocationMap";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

export const ViewPropertyDetails = () => {
	const appRoleDetails = useContext(AppRoleContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const propertyRawData = useSelector(getPropertyData);
	const propertyData = omit(propertyRawData, ["projectHeaderList"]);

	const allFiles = useSelector(selectAllFiles);
	const isUploading = useSelector(selectIsUploading);
	const isUploadError = useSelector(selectUploadError);
	//   const isFetching = useSelector(selectIsFetching);
	//   const pdfFiles = useSelector(selectFilesByType("application/pdf"));
	//   const fileCount = useSelector(selectFilesCount);

	const [editProperty, setEditProperty] = React.useState(false);

	const [files, setFiles] = React.useState([]);
	const [fileLabels, setFileLabels] = React.useState({});
	const [uploadStatus, setUploadStatus] = React.useState({});

	const handleFileUpload = (file, label) => {
		dispatch(uploadFile(file, label, params.propertyId, "Property"));
	};

	useEffect(() => {
		//if(editProperty)
		dispatch(fetchFiles("Property", params.propertyId));
	}, [isUploading]);

	const handleCloseSnackbar = () => {};

	return editProperty ? (
		<AddNewProperty
			onCancel={() => {
				setEditProperty(false);
				// getProjectDetails();
			}}
			propertyId={propertyData.propertyId}
			selectedProperty={{ data: propertyData }}
			title={" "}
		/>
	) : (
		<>
			<div style={{ background: "#F2F2F2" }}>
				<div className="m-2 mt-0">
					<HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
						<div
							className="d-flex mb-3"
							style={{ width: "100%", justifyContent: "space-between" }}
						>
							<Typography
								className="h4 mt-4 mb-4 col-lg-7 col-md-6 col-12"
								style={{ fontWeight: "700  " }}
							>
								Property Details
							</Typography>
							<div className="mt-4 col-lg-5 col-md-6 col-12 d-flex justify-content-between">
								<Typography
									className="body1"
									style={{ fontSize: "14px", color: "#8B8B8B" }}
								>
									<div>Last modified</div>
									<div>
										<span>
											{propertyData.modifiedBy},{" "}
											{moment(propertyData.modifiedDate).calendar()}
										</span>
									</div>
								</Typography>
								<button
									className="primaryButton"
									style={{ width: "5rem" }}
									onClick={() => {
										setEditProperty(true);
									}}
								>
									<Edit style={{ fontSize: "16px" }} /> Edit
								</button>
							</div>
						</div>
					</HiddenOn>
				</div>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					open={isUploadError?.showSnackbar}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
				>
					<Alert onClose={handleCloseSnackbar} severity={isUploadError?.status}>
						{isUploadError?.text}
					</Alert>
				</Snackbar>
				<div className="mt-3 d-flex flex-wrap full-width">
					<div className="col-lg-7 col-md-6 col-12">
						<RenderPropertyFields propertyData={propertyData} />
					</div>
					<div className="col-lg-5 col-md-6 col-12">
						<Paper style={{ marginRight: "0.75rem" }}>
							<div id="property-contant-section">
								<div
									id="contact-fields"
									className="m-2 d-flex flex-wrap justify-content-between"
								>
									{["homeOwnerName", "mobileNumber", "address"].map((key) => {
										return (
											<div className="m-1 col-6">
												{propertyData[key] && propertyData[key] !== "" && (
													<Typography
														className="body1"
														style={{
															fontSize: "12px",
															fontWeight: 500,
															color: "#0B2240",
														}}
													>
														{convertCamelToNormal(key)}
													</Typography>
												)}

												<Typography
													style={{
														fontSize: "12px",
														wordBreak: "all",
														fontWeight: 700,
														color: "#0B2240",
													}}
													className="body1"
												>
													{propertyData[key]}
												</Typography>
											</div>
										);
									})}
								</div>
								<Divider
									className="divider"
									style={{ width: "90%", justifySelf: "center" }}
								/>
								<div id="map-location" className="mt-2">
									<SiteLocationMap propertyData={propertyData} />
								</div>
							</div>
						</Paper>
						<div
							id="contact-fields"
							className="m-2 d-flex flex-wrap justify-content-between"
						>
							{["createdBy", "createdDate"].map((key) => {
								return (
									<div className="m-1 col-6">
										{propertyData[key] && propertyData[key] !== "" && (
											<Typography
												className="body1"
												style={{
													fontSize: "12px",
													fontWeight: 500,
													color: "#0B2240",
												}}
											>
												{convertCamelToNormal(key)}
											</Typography>
										)}

										<Typography
											style={{
												fontSize: "12px",
												wordBreak: "all",
												fontWeight: 700,
												color: "#0B2240",
											}}
											className="body1"
										>
											{propertyData[key]}
										</Typography>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
					<div className="d-flex flex-wrap col-12 pt-5 mb-5">
						<div className={"col-12 "}>
							{/* <div className="d-flex flex-wrap col-12"> */}
							<h5>Property Documents</h5>

							<FileUpload handleFileUpload={handleFileUpload} />

							{/* Pass the files, labels, upload status, and existing files to FilesList */}
							{!isUploading && (
								<FilesList
									files={allFiles}
									fileLabels={fileLabels}
									uploadStatus={uploadStatus}
								/>
							)}
							{isUploading && (
								<Grid
									container
									spacing={0}
									direction="column"
									alignItems="center"
									justifyContent="center"
									sx={{ minHeight: "50vh" }}
								>
									<Grid item xs={3}>
										<CircularProgress size="3rem" />
									</Grid>
								</Grid>
							)}

							{/* </div> */}
						</div>
					</div>
				</HiddenOn>
			</div>
		</>
	);
};

export const RenderPropertyFields = ({ propertyData }) => {
	console.log("Check the Property Details Data : ", propertyData);
	const pickedFields = [
		"propertyName",
		"propertyType",
		"propertyDetails",
		"architectureStyle",
		"legalDescription",
		"stories",
		"lotSize",
		"buildingSize",
		"bedroom",
		"bath",
		"garages",
	];

	const specificProps = pick(propertyData, pickedFields);
	return (
		<div className="d-flex flex-wrap col-12 ">
			<div className={"col-12 "}>
				<div className="d-flex flex-wrap col-12">
					{Object.keys(specificProps).map((key) => {
						return (
							<div className="m-2 col-4">
								{specificProps[key] && specificProps[key] !== "" && (
									<Typography
										className="body1"
										style={{ fontWeight: 500, color: "#0B2240" }}
									>
										{/* <strong></strong> */}
										{convertCamelToNormal(key)}
									</Typography>
								)}

								<Typography
									style={{
										wordBreak: "all",
										fontWeight: 700,
										color: "#0B2240",
									}}
									className="body1"
								>
									{key === "propertyLocation" ? (
										<a
											href={propertyData[key]}
											target="_blank"
											rel="noreferrer"
											className="link-text"
										>
											View Location
										</a>
									) : isObject(propertyData[key]) ? (
										""
									) : (
										propertyData[key]
									)}
								</Typography>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ViewPropertyDetails;
