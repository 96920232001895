import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";

import {
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { server_url } from "../../constants/types";
import { CloseOutlined } from "@mui/icons-material";

const ReportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    viewName: "",
    source: "",
    paging: "serverSide",
    pageSize: "",
    active: false,
    category: "",
    showTotalRow: false,
  });

  const [reportColumns, setReportColumns] = useState([]);

  const roleOptions = [
    { roleId: 1, roleName: "SystemAdmin" },
    { roleId: 2, roleName: "Homeowner" },
    { roleId: 3, roleName: "BuilderAdmin" },
    { roleId: 4, roleName: "ProjectManager" },
    { roleId: 5, roleName: "SiteSupervisor" },
    { roleId: 6, roleName: "TeamMember" },
    { roleId: 7, roleName: "SupplierAdmin" },
  ];

  const gridColumns = [
    {
      name: "columnName",
      label: "Column Name",
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: "dataType",
      label: "Data Type",
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: "columnOrder",
      label: "Column Order",
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <IconButton
                style={{ width: "50%" }}
                onClick={() => handleDelete(this.state.jobList[dataIndex])}
              >
                <DeleteIcon color="secondary" style={{ fontSize: "16px" }} />
              </IconButton>
              <IconButton
                style={{ width: "50%" }}
                onClick={() => handleEdit(dataIndex)}
              >
                <EditIcon color="primary" style={{ fontSize: "16px" }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    // Fetch data for the specific item to edit
    const fetchData = async () => {
      const tokenString = localStorage.getItem("token");

      if (id) {
        const response = await fetch(`${server_url}/Report/${id}`, {
          headers: {
            Authorization: "Bearer " + tokenString,
          },
        });
        const result = await response.json();
        setFormData(result);

        fetch(`${server_url}/ReportColumn/${id}`, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + tokenString,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setReportColumns(data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleChangeSelect = (event) => {
    setFormData({
      ...formData,
      ["reportRoles"]: event,
    });
  };

  const handleCancel = (event) => {
    navigate("/reports");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Submit the updated data to the API
    const tokenString = localStorage.getItem("token");
    await fetch(`${server_url}/Report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenString,
      },
      body: JSON.stringify(formData),
    })
      .catch((error) => {
        alert(error);
        return;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          alert(data.message);
          return;
        } else {
          navigate("/reports");
        }
      });
  };

  const handleEdit = (index) => {
    var objColumn = reportColumns[index];

    navigate(`/reportcolumn/${id}/` + objColumn.columnID);
  };

  const handleDelete = (index) => {
    var objColumn = reportColumns[index];
  };

  const handleCreateColumn = () => {
    window.location.replace("/reportcolumn/0/" + id);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Typography className="h4">Edit Page</Typography>
        <IconButton onClick={handleCancel}>
          <CloseOutlined />
        </IconButton>
      </div>
      <form onSubmit={handleSubmit} className="mt-5">
        <div>
          <div className="d-flex continer">
            <Typography
              // variant="h6"
              className="mb-2 h6"
              style={{ width: "10rem" }}
            >
              View Name:{" "}
            </Typography>
            <TextField
              type="text"
              name="viewName"
              className="form-field ms-3"
              style={{ width: "25rem" }}
              value={formData.viewName}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex mt-3">
            <Typography
              // variant="body1"
              className="mb-2 h6"
              style={{ width: "10rem" }}
            >
              Source:
            </Typography>
            <TextField
              type="textarea"
              multiline
              name="source"
              className="form-field ms-3"
              style={{ width: "25rem" }}
              value={formData.source}
              onChange={handleChange}
              rows={4}
              cols={100}
            />
          </div>
          <div className="d-flex mt-3">
            <Typography className="mb-2 h6" style={{ width: "10rem" }}>
              Paging:
            </Typography>
            <TextField
              className="form-field ms-3"
              select
              value={formData.paging}
              onChange={handleChange}
              style={{ width: "25rem" }}
            >
              <MenuItem value="serverSide">
                <Typography>Server Side</Typography>
              </MenuItem>
              <MenuItem value="clientSide">
                <Typography>Client Side</Typography>
              </MenuItem>
              <MenuItem value="none">
                <Typography>None</Typography>
              </MenuItem>
            </TextField>
          </div>
          <div className="d-flex mt-3">
            <Typography className="mb-2 h6" style={{ width: "10rem" }}>
              Category:
            </Typography>
            <TextField
              type="text"
              name="category"
              className="form-field ms-3"
              style={{ width: "25rem" }}
              value={formData.category}
              onChange={handleChange}
            />
          </div>

          <div className="d-flex mt-3">
            <div className="d-flex ">
              {" "}
              <Typography className="mb-2 h6" style={{ width: "10rem" }}>
                Page Size:
              </Typography>
              <TextField
                type="text"
                name="pageSize"
                className="form-field ms-3"
                style={{ width: "25rem" }}
                value={formData.pageSize}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex ms-3 align-items-center">
              <Typography className="mb-2 h6" style={{ width: "10rem" }}>
                Active:
              </Typography>
              <input
                type="checkbox"
                name="active"
                checked={formData.active}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex ms-3 align-items-center">
              <Typography className="mb-2 h6" style={{ width: "10rem" }}>
                Show Total Row:
              </Typography>
              <input
                type="checkbox"
                name="showTotalRow"
                checked={formData.showTotalRow}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="d-flex mt-4 mb-4">
            <Typography className="mb-2 h6" style={{ width: "10rem" }}>
              Allowed Roles:
            </Typography>
            <Select
              value={formData.reportRoles}
              name={"reportRoles"}
              isMulti
              style={{ width: "10rem" }}
              className="ms-3 primaryInput"
              options={roleOptions}
              onChange={handleChangeSelect}
              getOptionValue={(option) => option.roleId}
              getOptionLabel={(option) => option.roleName}
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="primaryButton">
              Save
            </button>
            <button onClick={handleCancel} className="secondaryButton ms-3">
              Canel
            </button>
          </div>
        </div>
      </form>
      <br /> <br /> <br />
      <div>
        <button
          type="button"
          className="primaryButton"
          style={{ width: "20rem" }}
          onClick={handleCreateColumn}
        >
          Create Custom Column
        </button>
      </div>
      <br /> <br /> <br />
      {reportColumns && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MUIDataTable
              title="Report Columns"
              data={reportColumns}
              columns={gridColumns}
              options={{
                rowHover: true,
                selectableRows: "none",
                viewColumns: false,
                rowsPerPage: 10,
                rowsPerPageOptions: [5, 10, 15],
                filter: false,
                download: false,
                print: false,
                search: false,
                pagination: false,
                filterType: "dropdown",
                responsive: "standard",
                expandableRows: false,
                expandableRowsOnClick: false,
                delete: false,
                isRowExpandable: (dataIndex, expandedRows) => {
                  return false;
                },
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ReportDetails;
