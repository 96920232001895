import React from "react";
import { BrowserRouter } from "react-router-dom";
import { BackdropLoader } from "./Loader";
import { useSelector } from "react-redux";
import { appLoadingSelector } from "selectors/appSelector";
import { ProjectProvider } from "context/ProjectContext";
import Layout2 from "./Layout/Layout2";

export default function App() {
	const isLoading = useSelector(appLoadingSelector);

	return (
		<div className="app">
			<BackdropLoader open={isLoading} />
			<BrowserRouter>
				<AppWrapper />
			</BrowserRouter>
		</div>
	);
}

const AppWrapper = () => {
	return (
		<ProjectProvider>
			<Layout2 />
		</ProjectProvider>
	);
};
