import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Create styles using makeStyles
const useStyles = makeStyles((theme) => ({
	tableContainer: {
		marginTop: theme.spacing(3),
	},
	tableHead: {
		backgroundColor: theme.palette.primary.main,
	},
	tableHeadCell: {
		color: theme.palette.common.white,
		fontWeight: "bold",
	},
	tableRow: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
	},
	tableCell: {
		padding: theme.spacing(1),
	},
	amountCell: {
		fontWeight: "bold",
	},
	receivedChip: {
		marginLeft: theme.spacing(1),
	},
}));

const Payments = () => {
	const classes = useStyles();

	const payments = [
		{
			id: 1,
			paymentNumber: "PAY001",
			date: "2024-06-05",
			amount: 800,
			received: true,
		},
		{
			id: 2,
			paymentNumber: "PAY002",
			date: "2024-06-25",
			amount: 1500,
			received: false,
		},
		// Add more mock data as needed
	];

	const handleDownloadPDF = (paymentNumber) => {
		// Logic to download PDF for the selected payment
		console.log(`Downloading PDF for payment: ${paymentNumber}`);
	};

	return (
		<div>
			<Typography variant="h6" gutterBottom>
				Payments
			</Typography>
			<TableContainer component={Paper} className={classes.tableContainer}>
				<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell className={classes.tableHeadCell}>
								Payment Number
							</TableCell>
							<TableCell className={classes.tableHeadCell}>Date</TableCell>
							<TableCell className={classes.tableHeadCell}>Amount</TableCell>
							<TableCell className={classes.tableHeadCell}>Received</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{payments.map((payment) => (
							<TableRow
								key={payment.id}
								className={classes.tableRow}
								onClick={() => handleDownloadPDF(payment.paymentNumber)}
							>
								<TableCell className={classes.tableCell}>
									{payment.paymentNumber}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{payment.date}
								</TableCell>
								<TableCell
									className={`${classes.tableCell} ${classes.amountCell}`}
								>
									${payment.amount}
								</TableCell>
								<TableCell className={classes.tableCell}>
									{payment.received ? (
										<Chip
											label="Yes"
											color="primary"
											className={classes.receivedChip}
										/>
									) : (
										<Chip
											label="No"
											color="secondary"
											className={classes.receivedChip}
										/>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default Payments;
