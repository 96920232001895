import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { convertCamelToNormal } from "core/utils/appUtils";
import { useDispatch, useSelector } from "react-redux";
import { getProjectData } from "selectors/projectSelector";
import { getTasks } from "selectors/taskSelectors";
import { getJobsList } from "selectors/jobSelectors";
import { AgChartsReact } from "ag-charts-react";
import get from "lodash/get";
import isObject from "lodash/isObject";

import { Edit } from "@mui/icons-material";
import AddNewProject from "../AddNewProject";
import HiddenOn from "components/Wrappers/HiddenOn";
import {
  hasAppRole,
  hasJobRole,
  roleConfigKeys,
  hasAdminRole,
} from "core/utils/roleUtils";
import AppRoleContext from "context/AppRoleContext";
import { Link } from "react-router-dom";
import FileUpload from "components/FileUpload/FileUpload";
import FilesList from "components/FileList/FileList";
import { uploadFile, fetchFiles } from "actions/documentsActions";
import {
  selectAllFiles,
  selectIsUploading,
  selectIsFetching,
  selectFilesByType,
  selectFilesCount,
  selectUploadError,
} from "selectors/documentsSelectors";

export const Overview = ({ getProjectDetails }) => {
  const projectData = useSelector(getProjectData);
  const [editProject, setEditProject] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [fileLabels, setFileLabels] = React.useState({});
  const [uploadStatus, setUploadStatus] = React.useState({});
  const appRoleDetails = useContext(AppRoleContext);
  const projRole = get(projectData, "projUserRole.projrole.projRoleName", "");
  const jobsData = get(projectData, "jobStatus.jobs", []);
  const tasksData = get(projectData, "taskStatus.tasks", []);
  const params = useParams();
  const dispatch = useDispatch();

  const allFiles = useSelector(selectAllFiles);
  const isUploading = useSelector(selectIsUploading);
  const isUploadError = useSelector(selectUploadError);
  let totalJobs = 0;
  let totalTasks = 0;

  jobsData.forEach((job) => {
    totalJobs += job.count;
  });
  tasksData.forEach((task) => {
    totalTasks += task.count;
  });

  const handleFileUpload = (file, label) => {
    dispatch(uploadFile(file, label, params.projectId, "Project"));
  };
  useEffect(() => {
    //if(editProperty)
    dispatch(fetchFiles("Project", params.projectId));
  }, [isUploading]);

  return editProject ? (
    <AddNewProject
      onCancel={() => {
        setEditProject(false);
        getProjectDetails();
      }}
      projectId={projectData.projectId}
      selectedProject={{ data: projectData }}
      title={" "}
    />
  ) : (
    <>
      <div className="m-2 mt-3 mb-3">
        <HiddenOn
          isHidden={
            !hasAppRole(roleConfigKeys.EDIT_PROJECT, appRoleDetails) &&
            !hasJobRole(roleConfigKeys.EDIT_PROJECT, projRole)
          }
        >
          <div className="d-flex justify-content-end">
            <button
              className="secondaryButton"
              style={{ width: "5rem" }}
              onClick={() => {
                setEditProject(true);
              }}
            >
              <Edit style={{ fontSize: "16px" }} /> Edit
            </button>
          </div>
        </HiddenOn>
        {projectData.property?.propertyId && (
          <div className="mb-5">
            <div>
              <Typography className="h2">Property Details:</Typography>
            </div>
            <div className="d-flex mb-2">
              <Typography className="body1 me-3">Property Name: </Typography>
              <Link to={`/properties/view/${projectData.property.propertyId}`}>
                {get(projectData, "property.propertyName", "")}
              </Link>
            </div>
            <Typography className="body1">Address: </Typography>
            <a
              href={projectData?.property?.propertyLocation}
              target="_blank"
              rel="noreferrer"
              className="link-text"
            >
              {get(projectData, "property.address", "")}
            </a>
          </div>
        )}

        <Typography className="h2">Project Details:</Typography>
        <Typography className="body1">{projectData.projectDetails}</Typography>
      </div>
      <div className="d-flex flex-wrap">
        <div className={"col-7 col-xs-6"}>
          <div className="d-flex flex-wrap">
            {Object.keys(projectData).map((key) => {
              return (
                <div className="m-2 col-3">
                  <Typography className="body1">
                    <strong>{convertCamelToNormal(key)}</strong>
                  </Typography>
                  <Typography style={{ wordBreak: "all" }} className="body1">
                    {key === "siteLocation" ? (
                      <a
                        href={projectData[key]}
                        target="_blank"
                        rel="noreferrer"
                        className="link-text"
                      >
                        View Location
                      </a>
                    ) : isObject(projectData[key]) ? (
                      ""
                    ) : (
                      projectData[key]
                    )}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
        <div className={"col-5 col-xs-6"}>
          <div
            style={{
              // height: "10rem",
              width: "100%",
              border: "1px solid",
              borderRadius: "9px",
            }}
          >
            <div>
              <Typography className="body1 m-2">
                Total Jobs: {totalJobs}
              </Typography>
              <div style={{ height: "200px", width: "100%" }}>
                <AgChartsReact
                  options={{
                    data: jobsData,
                    series: [
                      {
                        type: "pie",
                        angleKey: "count",
                        labelKey: "status",
                        innerRadiusOffset: "stage",
                        legendItemKey: "status",
                        sectorLabelKey: "count",
                        sectorLabel: {
                          color: "white",
                          fontWeight: "bold",
                          formatter: ({ params }) => {
                            const total =
                              params.angleValue * params.angleValueScale.total;
                            const percentage = (
                              (params.angleValue / total) *
                              100
                            ).toFixed(2);
                            return `${percentage}%`;
                          },
                        },
                      },
                    ],
                    title: {
                      text: "Jobs Status",
                    },
                    subtitle: {
                      text: "Distribution of jobs by status",
                    },
                    legend: {
                      position: "bottom",
                    },
                  }}
                />
              </div>
            </div>
            <div>
              <Typography className="body1 m-2">
                Total Tasks: {totalJobs}
              </Typography>
              <div style={{ height: "200px", width: "100%" }}>
                <AgChartsReact
                  options={{
                    data: tasksData,
                    series: [
                      {
                        type: "pie",
                        angleKey: "count",
                        labelKey: "status",
                        innerRadiusOffset: "stage",
                        legendItemKey: "status",
                        sectorLabelKey: "count",
                        sectorLabel: {
                          color: "white",
                          fontWeight: "bold",
                          formatter: ({ params }) => {
                            const total =
                              params.angleValue * params.angleValueScale.total;
                            const percentage = (
                              (params.angleValue / total) *
                              100
                            ).toFixed(2);
                            return `${percentage}%`;
                          },
                        },
                      },
                    ],
                    title: {
                      text: "Task Distribution",
                    },
                    subtitle: {
                      text: "Distribution of tasks by status",
                    },
                    legend: {
                      position: "bottom",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {Document upload } */}

      <HiddenOn isHidden={!hasAdminRole(appRoleDetails)}>
        <div className="d-flex flex-wrap col-12 pt-5 mb-5">
          <div className={"col-12 "}>
            {/* <div className="d-flex flex-wrap col-12"> */}
            <h5>Project Documents</h5>

            <FileUpload handleFileUpload={handleFileUpload} />

            {/* Pass the files, labels, upload status, and existing files to FilesList */}
            {!isUploading && (
              <FilesList
                files={allFiles}
                fileLabels={fileLabels}
                uploadStatus={uploadStatus}
              />
            )}
            {isUploading && (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "50vh" }}
              >
                <Grid item xs={3}>
                  <CircularProgress size="3rem" />
                </Grid>
              </Grid>
            )}

            {/* </div> */}
          </div>
        </div>
      </HiddenOn>
    </>
  );
};

export default Overview;
