import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { getText } from "core/utils/getContentText";
import { useNavigate } from "react-router-dom";
import React from "react";

export const ConfirmationPage = ({ userType, setUserType }) => {
	const navigate = useNavigate();
	const redirectUrls = {
		homeOwner: "/profile/homeOwner/addProperty",
		contractor: `/profile/${userType}/businessProfile`,
		supplier: "/profile/contractor/businessProfile",
	};
	return (
		<div className="fullHeight" style={{ marginTop: "5rem" }}>
			<div className="confirmation-container mt-5 text-center align-center place-center">
				<div className="confirmation-message1">
					<CheckCircleOutlineOutlined className="check-circle" />
					<h3>{getText("confirmationPage.heading")}</h3>
					<p>{getText("confirmationPage.message")}XX/XX/XXXX</p>
				</div>
				<div className="confirmation-message2 mt-3">
					<p>{getText(`confirmationPage.visibilityMessage.${userType}`)}</p>
				</div>
				<div>
					<button
						onClick={() => {
							navigate(redirectUrls[userType]);
							setUserType(null);
						}}
						className="primaryButton mt-3"
						style={{ width: "auto" }}
					>
						{getText(`confirmationPage.buttonText.${userType}`)}
					</button>
				</div>
			</div>
		</div>
	);
};
export default ConfirmationPage;
