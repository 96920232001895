import { Typography } from "@mui/material";
import React from "react";
import "./noResults.scss";

export const NoResultsFound = ({ customText }) => {
	return (
		<div className="no-results-message ">
			<Typography className="h1">{customText || "No Results Found"}</Typography>
		</div>
	);
};

export default NoResultsFound;
