import axios from "axios";
import request from "config/api";
import { ActionConstants } from "constants";
import { server_url } from "constants/types";
import get from "lodash/get";
import queryString from "query-string";
import { decodeToken } from "core/utils/appUtils";

export const loginAction = (credentials, history) => {
	return (dispatch, _) => {
		console.log("IN Action ");
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.LOGIN,
				payload: {
					loading: true,
				},
			});
			axios
				.post(`${server_url}/account/login`, {
					...credentials,
				})
				.then((response) => {
					dispatch({
						type: ActionConstants.LOGIN_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "Get All LEAD data successfully.",
						},
					});
					localStorage.setItem("token", get(response, "data.token", ""));
					dispatch(loadUserDetails(credentials.username));
					if (history) {
						history("/");
					}
					console.log("Response : ", response);
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.LOGIN_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const socialLoginAction = (credentials, history) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.LOGIN_EXTERNAL,
				payload: {
					loading: true,
				},
			});
			dispatch({ type: ActionConstants.LOADING_APP });
			localStorage.setItem("ssoToken", credentials.accessToken);
			axios
				.post(`${server_url}/account/loginExternal`, {
					AccessToken: credentials.accessToken,
				})
				.then((response) => {
					console.log("After Social Login : ", response, response.data);
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "Get All LEAD data successfully.",
						},
					});
					localStorage.setItem("token", response.data.token);
					const decodedToken = decodeToken();
					console.log("Check the Decoded Token : ", decodedToken);
					// if (response.data.registeredNewUser) {
					// 	history(
					// 		`/app/registerForm?email=${decodedToken.unique_name}&type=sso`
					// 	);
					// } else {
					if (response.data.registeredNewUser) {
						history("/sso-login/new-registration");
					} else {
						history("/");
					}
					// }
					console.log("Response : ", response);
					dispatch({ type: ActionConstants.LOADING_APP_SUCCESS });

					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.LOGIN_EXTERNAL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					dispatch({
						type: ActionConstants.LOADING_APP_ERROR,
					});

					resolve();
				});
		});
	};
};

export const signUpEmailVerify = (signUpBody, onSuccess, resend = false) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.SIGNUP_EMAIL,
				payload: {
					loading: true,
				},
			});
			axios
				.get(
					`${server_url}/user/getUserExistsByEmail?email=${signUpBody.eMailAddress}`
				)
				.then((emailCheckResponse) => {
					const query = queryString.stringify(signUpBody);
					if (emailCheckResponse.data) {
						onSuccess(
							{
								emailExists: true,
								message: "Given Email already exists",
							},
							false
						);
						dispatch({
							type: ActionConstants.SIGNUP_EMAIL_SUCCESS,
							payload: {
								loading: false,
								status: "error",
								text: "Already had an email",
							},
						});
					} else {
						axios
							.post(`${server_url}/Generic/sendemail?${query}`)
							.then((response) => {
								dispatch({
									type: ActionConstants.SIGNUP_EMAIL_SUCCESS,
									payload: {
										leadList: response.data,
										loading: false,
										status: "success",
										text: "Get All LEAD data successfully.",
									},
								});
								onSuccess(
									{
										emailExists: false,
										message:
											"Verification email has been sent, please verify for registration process",
									},
									true
								);
								resolve();
							})
							.catch((e) => {
								console.log("Error Response : ", e);
								const errorResponse = get(e, "response", {});
								onSuccess();
								dispatch({
									type: ActionConstants.SIGNUP_EMAIL_ERROR,
									payload: {
										status: "error",
										message:
											errorResponse.status === 401
												? "Bad Credentials"
												: "Something Went Wrong",
										loading: false,
									},
								});
								resolve();
							});
					}
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					onSuccess(
						{
							emailExists: true,
							message: "Something went wrong! please try again",
						},
						false
					);
					dispatch({
						type: ActionConstants.SIGNUP_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const registerUserDetailsAction = (userDetails, userType, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.REGISTRATION,
				payload: {
					loading: true,
				},
			});
			axios
				.get(
					`${server_url}/user/getUserExistsByEmail?email=${userDetails.email}`
				)
				.then((emailCheckResponse) => {
					if (emailCheckResponse.data) {
						console.log("Email Exists");
						dispatch({
							type: ActionConstants.REGISTRATION_ERROR,
							payload: {
								status: "error",
								message: "Account with this Email already exists....",
								loading: false,
							},
						});
					} else {
						axios
							.post(`${server_url}/`, {
								...userDetails,
								userType: userType,
								// companyName: null,
								// role: "admin",
							})
							.then((response) => {
								dispatch({
									type: ActionConstants.REGISTRATION_SUCCESS,
									payload: {
										leadList: response.data,
										loading: false,
										status: "success",
										text: "User Registered Successfuly",
									},
								});
								onSuccess(response.data);
								dispatch(
									loginAction({
										username: userDetails.email,
										password: userDetails.password,
									})
								);
								// history("/");
								console.log("Response : ", response);
								resolve();
							})
							.catch((e) => {
								console.log("Error Response : ", e);
								const errorResponse = get(e, "response", {});
								dispatch({
									type: ActionConstants.REGISTRATION_ERROR,
									payload: {
										status: "error",
										message:
											errorResponse.status === 401
												? "Bad Credentials"
												: "Something Went Wrong",
										loading: false,
									},
								});
								resolve();
							});
					}
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					onSuccess(
						{
							emailExists: true,
							message: "Something went wrong! please try again",
						},
						false
					);
					dispatch({
						type: ActionConstants.SIGNUP_EMAIL_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const updateSSOUserDetails = (userDetails, userType, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.REGISTRATION,
				payload: {
					loading: true,
				},
			});
			const decodedToken = decodeToken();
			console.log("Check the Decoded Token : ", decodedToken);
			request({
				method: "PUT",
				url: `${server_url}/user/UpdateSSOUser/${decodedToken.nameid}`,
				body: {
					...userDetails,
					userType: userType,
					email: decodedToken.unique_name,
					userId: decodedToken.nameid,
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.REGISTRATION_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "User Registered Successfuly",
						},
					});
					const ssoToken = localStorage.getItem("ssoToken");

					dispatch(socialLoginAction({ accessToken: ssoToken }, () => {}));
					onSuccess(response.data);
					// history("/");
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.REGISTRATION_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
export const paymentInformationAction = (paymentDetails, onSuccess) => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.PAYMENT,
				payload: {
					loading: true,
				},
			});
			request({
				method: "POST",
				url: `${server_url}/card`,
				body: {
					...paymentDetails,
				},
				headers: { "Content-Type": "application/json" },
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.PAYMENT_SUCCESS,
						payload: {
							leadList: response.data,
							loading: false,
							status: "success",
							text: "User Registered Successfuly",
						},
					});
					onSuccess(response.data);
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.PAYMENT_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};

export const loadUserDetails = (email = "") => {
	return (dispatch, _) => {
		return new Promise((resolve) => {
			dispatch({
				type: ActionConstants.USER_DETAILS,
				payload: {
					loading: true,
				},
			});
			request({
				method: "GET",
				url: `${server_url}/user/getUserDetails?${
					email ? `email=${email}` : ""
				}`,
			})
				.then((response) => {
					dispatch({
						type: ActionConstants.USER_DETAILS_SUCCESS,
						payload: response.data,
					});
					resolve();
				})
				.catch((e) => {
					console.log("Error Response : ", e);
					const errorResponse = get(e, "response", {});
					dispatch({
						type: ActionConstants.USER_DETAILS_ERROR,
						payload: {
							status: "error",
							message:
								errorResponse.status === 401
									? "Bad Credentials"
									: "Something Went Wrong",
							loading: false,
						},
					});
					resolve();
				});
		});
	};
};
