import {
	Autocomplete,
	CircularProgress,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { fetchSuppliersList } from "actions/companiesActions";
import { updateJobUserRoles } from "actions/jobActions";
import {
	fetchProjRoles,
	fetchProjUserRoles,
	fetchUsers,
} from "actions/userActions";
import { FormLabel } from "components/TextFields/FormInputField";
import { getConnType } from "constants/appConstants";
import { get } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connectedSuppliersSelector } from "selectors/companiesSelectors";
import { getJobUserRolesList } from "selectors/jobSelectors";
import {
	getProjectUserRolesList,
	getUsersLoading,
} from "selectors/userSelector";

export const ManageJobUsers = ({ jobRefId, projectId }) => {
	const dispatch = useDispatch();
	const usersLoading = useSelector(getUsersLoading);
	const { projectUsersRolesList } = useSelector(getProjectUserRolesList);
	const { connectedSuppliers, loadingSuppliers } = useSelector(
		connectedSuppliersSelector
	);
	const { jobUsersRolesList } = useSelector(getJobUserRolesList);

	const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState(null);
	const [selectedType, setSelectedType] = React.useState("supplier");

	useEffect(() => {
		dispatch(fetchProjUserRoles(projectId));
		// dispatch(fetchProjRoles());
		// dispatch(fetchUsers({ AllUsers: true }));
		dispatch(
			fetchSuppliersList({ ConnectionStatus: getConnType["connected"] })
		);
	}, [dispatch, jobRefId, projectId]);

	console.log("Check the JobUserRole List", jobUsersRolesList);

	return (
		<div>
			<div>
				{selectedUser && jobUsersRolesList.length > 0 && (
					<p style={{ fontSize: "10x", color: "red" }}>
						User Already assigned to this job please remove existing one and
						re-assign user
					</p>
				)}
			</div>
			<div className="d-flex flex-wrap row align-items-center">
				<div className=" col-md-5 col-12">
					<FormLabel text={"Type"} className={"mb-3"} />
					<TextField
						className="form-field"
						select
						onChange={(event) => {
							setSelectedType(event.target.value);
							setSelectedUser(null);
						}}
						value={selectedType}
					>
						<MenuItem value={"projectTeam"}>
							<Typography variant="body1">Team Member</Typography>
						</MenuItem>
						<MenuItem value={"supplier"}>
							<Typography variant="body1">Supplier</Typography>
						</MenuItem>
					</TextField>
				</div>
				<div className=" col-md-5 col-12">
					<FormLabel text={"Select User"} className={"mb-3"} />
					<RenderOptions
						setSelectedUser={setSelectedUser}
						autoCompleteOpen={autoCompleteOpen}
						setAutoCompleteOpen={setAutoCompleteOpen}
						selectedType={selectedType}
						options={
							selectedType === "supplier"
								? connectedSuppliers
								: projectUsersRolesList
						}
						usersLoading={usersLoading || loadingSuppliers}
					/>
				</div>
				<div className="align-items-center col-md-2 mt-4">
					<button
						className={`primaryButton col-12 full-width mt-2 ${
							selectedUser === null || jobUsersRolesList.length > 0
								? "disabledButton"
								: ""
						}`}
						disabled={selectedUser === null || jobUsersRolesList.length > 0}
						onClick={() => {
							const payload =
								selectedType === "supplier"
									? {
											jobId: jobRefId,
											SupplierId:
												selectedUser.connection.supplierOrganizationId,
									  }
									: {
											jobId: jobRefId,
											userId: selectedUser.userId,
									  };
							dispatch(
								updateJobUserRoles(payload, () => setSelectedUser(null))
							);
						}}
					>
						Add
					</button>
				</div>
			</div>
		</div>
	);
};

const RenderOptions = ({
	setSelectedUser,
	autoCompleteOpen,
	setAutoCompleteOpen,
	selectedType,
	options,
	usersLoading,
}) => {
	return (
		<Autocomplete
			onChange={(event, value) => {
				setSelectedUser(value);
			}}
			id="asynchronous-demo"
			className="form-field"
			open={autoCompleteOpen}
			onOpen={() => {
				setAutoCompleteOpen(true);
			}}
			onClose={() => {
				setAutoCompleteOpen(false);
			}}
			isOptionEqualToValue={(option, value) => {
				if (selectedType === "supplier") {
					return (
						get(option, "connection.supplierOrganizationId") ===
						get(value, "connection.supplierOrganizationId")
					);
				} else {
					return option.projRoleId === value.projRoleId;
				}
			}}
			// filterOptions={(x) => x}
			getOptionLabel={(option) => {
				if (selectedType === "supplier") {
					return get(option, "company.companyName", "");
				} else {
					return get(option, "user.email");
				}
			}}
			options={options}
			loading={usersLoading}
			renderInput={(params) => (
				<TextField
					{...params}
					className="form-field"
					onChange={(event, value) => {
						const textLen = event.target.value.length;
						if (textLen >= 3) {
							console.log("Fetching the record");
						}
					}}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{usersLoading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	);
};

export default ManageJobUsers;
