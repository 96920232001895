import React, { useState } from "react";
import { CopilotFooter, CopilotHeader } from "components/Header";
import RegisterTypeModelSSO from "./SSO_RegistrationType";
import UserRegistrationForm from "./SSO_ProfileForm";
import ConfirmationPage from "../ConfirmationPage";
import "../login.scss";

export const RegistrationPage = () => {
	const [userType, setUserType] = useState(null);
	const [showConfirmationPage, setShowConfirmationPage] = useState(false);

	return (
		<div className={`register-form-type-${userType}`}>
			<CopilotHeader fixed={true} />
			{showConfirmationPage ? (
				<ConfirmationPage userType={userType} setUserType={setUserType} />
			) : userType ? (
				<div className="registration-form ">
					<UserRegistrationForm
						userType={userType}
						setUserType={setUserType}
						setShowConfirmationPage={setShowConfirmationPage}
					/>
				</div>
			) : (
				<div className="registration-form pb-5">
					<RegisterTypeModelSSO setUserType={setUserType} />
				</div>
			)}

			<CopilotFooter fixed={!userType || showConfirmationPage} />
		</div>
	);
};

export default RegistrationPage;
