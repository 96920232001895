import { ActionConstants } from "constants";

const initialState = {
	usersList: [],
	userData: {},
	usersLoading: false,
	projectRolesLoading: false,
	projectRoles: [],
	projectUserRoles: [],
	projectUserRolesLoading: false,
	status: "success",
	text: "",
	showSnackbar: false,
	timesheetData: [],
	totalTimesheetEntries: 0,
	guidDetails: {},
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionConstants.GET_USERS:
			return {
				...state,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.GET_USERS_SUCCESS:
			return {
				...state,
				usersList: action.payload.response.items,
				totalCount: action.payload.response.totalCount,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.GET_USERS_ERROR:
			return {
				...state,
				usersLoading: action.payload.loading,
			};
		case ActionConstants.ADD_NEW_USER_SUCCESS:
			return {
				...state,
				usersList: action.payload.response,
			};
		case ActionConstants.ADD_NEW_USER_ERROR:
			return {
				...state,
			};
		case ActionConstants.INVITE_NEW_USER_SUCCESS:
			return {
				...state,
				userData: action.payload.response,
			};
		case ActionConstants.INVITE_NEW_USER_ERROR:
			return {
				...state,
			};
		case ActionConstants.GET_TIME_SHEET_SUCCESS:
			return {
				...state,
				timesheetData: action.payload.response,
				totalTimesheetEntries: action.payload.totalCount,
			};
		case ActionConstants.GET_PROJECT_ROLES:
			return {
				...state,
				projectRolesLoading: true,
			};
		case ActionConstants.GET_PROJECT_ROLES_SUCCESS:
			return {
				...state,
				projectRoles: action.payload.response,
				projectRolesLoading: false,
			};
		case ActionConstants.GET_PROJECT_ROLES_ERROR:
			return {
				...state,
				projectRolesLoading: false,
			};
		case ActionConstants.GET_PROJECT_USERS_ROLES:
			return {
				...state,
				projectUserRolesLoading: true,
				projectUserRoles: [],
			};
		case ActionConstants.GET_PROJECT_USERS_ROLES_SUCCESS:
			return {
				...state,
				projectUserRoles: action.payload.response,
				projectUserRolesLoading: false,
			};
		case ActionConstants.GET_PROJECT_USERS_ROLES_ERROR:
			return {
				...state,
				projectUserRolesLoading: false,
			};
		case ActionConstants.GET_GUID_INVITE_DETAILS_SUCCESS:
			return {
				...state,
				guidDetails: action.payload.data,
			};
		case ActionConstants.GET_GUID_INVITE_DETAILS_ERROR:
			return {
				...state,
				inviteLinkError: action.payload,
			};
		default:
			return state || {};
	}
};

export default userReducer;
