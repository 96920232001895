import { DeleteOutline } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { fetchUsers } from "actions/userActions";
import BurgerMenu from "components/Menu/BurgerMenu";
import ManageUsers from "./ManageUsers";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ProjectTeamList from "./ProjectTeamList";

export const UserManagement = ({ projectId }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const columns = [
		{
			field: "userId",
			headerName: "User ID",
			width: 100,
		},
		{
			field: "firstName",
			headerName: "First Name",
			width: 200,
			valueFormatter: (_, row) => {
				return `${row.firstName} ${row.lastName}`;
			},
			// ,
		},
		{
			field: "email",
			headerName: "Email",
			width: 200,
		},
		{
			field: "mobileNumber",
			headerName: "Contact Number",
			width: 200,
		},
		{
			field: "userType",
			headerName: "Role",
			width: 200,
		},
		{
			field: "actions",
			type: "actions",
			flex: 1,
			align: "right",
			getActions: (params) => [
				<BurgerMenu>
					<MenuItem
						onClick={() => {
							console.log("Check the Params : ", params);
						}}
					>
						<DeleteOutline /> <p className="m-0">Remove User</p>
					</MenuItem>
				</BurgerMenu>,
			],
		},
	];

	return (
		<>
			<div className="user-list-container">
				<div>
					<ManageUsers
						buttonText="Add to Team"
						headerTitle="Add members to team"
						projectRefId={projectId}
					/>
				</div>
				<ProjectTeamList projectRefId={projectId} />
			</div>
		</>
	);
};

export default UserManagement;
