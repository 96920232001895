import React, { useState } from "react";

import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { ModelDialog } from "components/Model";
import { setDefaults } from "react-geocode";
import { IconButton, Typography } from "@mui/material";
import mapBackground from "../../assets/app/map-background.png";
import "./properties.scss";
import { CloseOutlined } from "@mui/icons-material";
export const MAPS_API_KEY = "AIzaSyBB_ou3GdFq8n3oCoVpB8fNPQaMndh-_WI";

// Geocode.setApiKey(MAPS_API_KEY);

setDefaults({
	key: MAPS_API_KEY, // Your API key here.
	language: "en", // Default language for responses.
	region: "us", // Default region for responses.
});

export const SiteLocationMap = ({ propertyData }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyBB_ou3GdFq8n3oCoVpB8fNPQaMndh-_WI",
	});

	const containerStyle = {
		width: "100%",
		height: "400px",
	};

	const center = {
		lat: propertyData.latitude,
		lng: propertyData.longitude,
	};

	return (
		<>
			<div className="m-2 mb-4">
				<Typography className="h5">Site Location</Typography>
			</div>
			<div className="site-location-bg">
				<button className="btn btn-primary" onClick={toggleModal}>
					Go to Map
				</button>
			</div>
			<ModelDialog
				fullWidth
				open={isModalOpen}
				dialogTitle={
					<div className="d-flex justify-content-between">
						<Typography variant="body1" className="h5">
							<strong>{"Site Location"}</strong>
						</Typography>
						<IconButton onClick={toggleModal}>
							<CloseOutlined />
						</IconButton>
					</div>
				}
				dialogContent={
					isLoaded ? (
						<GoogleMap
							defaultZoom={20}
							mapContainerStyle={containerStyle}
							center={center}
							zoom={14}
						>
							<Marker position={center} />
						</GoogleMap>
					) : (
						<div>Loading...</div>
					)
				}
				handleClose={toggleModal}
			/>
		</>
	);
};

export default SiteLocationMap;
