import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { companiesSelector } from "selectors/companiesSelectors";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import AppRoleContext from "context/AppRoleContext";
import { CancelRounded, CheckRounded, Search } from "@mui/icons-material";
import {
	addNewConnection,
	updateConnectionStatus,
} from "actions/companiesActions";
import { decodeToken } from "core/utils/appUtils";
import { getConnType } from "constants/appConstants";
import get from "lodash/get";

export const companiescolumns = [
	{ id: "companyName", label: "Company Name", minWidth: 150 },
	{
		id: "companyType",
		label: "Type",
		minWidth: "auto",
	},
	{
		id: "marketSectors",
		label: "Sectors",
		minWidth: "auto",
	},
	{
		id: "suppliersAndServices",
		label: "Services",
		minWidth: "auto",
	},
	{
		id: "serviceAreas",
		label: "Service Areas",
		minWidth: "auto",
	},
	{
		id: "connect",
		label: "",
		minWidth: "auto",
		format: ({
			value,
			company,
			tabConfig,
			dispatch,
			type,
			onSuccess,
			selectedTab,
		}) => {
			const { CompanyId } = decodeToken();
			const clientOrgId = String(
				get(company, "connection.clientOrganizationId", null)
			);
			console.log("Compare ", typeof CompanyId, typeof clientOrgId);

			const payload =
				type === "builder"
					? {
							clientOrganizationId: CompanyId,
							supplierOrganizationId: company.companyId,
					  }
					: {
							clientOrganizationId: company.companyId,
							supplierOrganizationId: CompanyId,
					  };

			if (tabConfig.key === "newConnections") {
				return (
					<div style={{ textAlign: "center", marginTop: "0.8rem" }}>
						<p>
							<button
								className="primaryButton"
								style={{
									width: "5rem",
									height: "2rem",
									fontWeight: 500,
									borderRadius: "1rem",
								}}
								onClick={() => {
									dispatch(
										addNewConnection(
											{
												...payload,
												connectionStatusId: 7,
											},
											onSuccess,
											{ ConnectionStatus: getConnType[selectedTab] }
										)
									);
								}}
							>
								+ Connect
							</button>
						</p>
					</div>
				);
			}
			if (tabConfig.key === "pendingConnections") {
				const {
					connection: { connectionId },
				} = company;
				return (
					<>
						{clientOrgId === CompanyId ? (
							<button
								className="primaryButton"
								disabled
								style={{
									width: "5rem",
									height: "2rem",
									fontWeight: 500,
									borderRadius: "1rem",
									margin: "0.7rem",
									cursor: "default",
								}}
							>
								Pending
							</button>
						) : (
							<button
								className="outlinedButton"
								style={{
									width: "5rem",
									height: "2rem",
									fontWeight: 500,
									borderRadius: "1rem",
									margin: "0.7rem",
								}}
								onClick={() => {
									dispatch(
										updateConnectionStatus(
											{
												...payload,
												connectionStatusId: 6,
											},
											connectionId,
											onSuccess,
											{ ConnectionStatus: getConnType[selectedTab] }
										)
									);
								}}
							>
								<CheckRounded style={{ fontSize: "14px", color: "green" }} />
								Approve
							</button>
						)}
						<button
							className="outlinedButton"
							style={{
								borderColor: "red",
								width: "5rem",
								color: "red",
								marginLeft: "5px",
								height: "2rem",
								borderRadius: "1rem",
								margin: "0.7rem",
							}}
							onClick={() => {
								dispatch(
									updateConnectionStatus(
										{
											...payload,
											connectionStatusId: 8,
										},
										connectionId,
										onSuccess,
										{ ConnectionStatus: getConnType[selectedTab] }
									)
								);
							}}
						>
							<CancelRounded style={{ fontSize: "14px", color: "red" }} />{" "}
							{clientOrgId === CompanyId ? "Revert" : "Reject"}
						</button>
					</>
				);
			}
			return "";
		},
	},
];

export const CompaniesList = ({ type }) => {
	const dispatch = useDispatch();
	const companiesList = useSelector(companiesSelector);
	const appRoleDetails = useContext(AppRoleContext);

	// useEffect(() => {
	// 	dispatch(getCompanies());
	// }, [dispatch]);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [addNewConnection, setAddNewConnection] = React.useState(false);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<div>
			{" "}
			<div>
				{/* <Typography className={"h5"}>Available Connections</Typography> */}
			</div>
			<div>
				<Grid container>
					<Typography
						className="h6 mr-2 align-content-center"
						variant="subtitle1"
					>
						Search Connections:
					</Typography>
					<TextField
						className="form-field ml-3"
						variant="outlined"
						style={{ width: "30%", marginLeft: "1rem" }}
						placeholder={"Company Name/Type/Location"}
						InputProps={{
							endAdornment: (
								<InputAdornment
									position="end"
									style={{ cursor: "pointer" }}
									onClick={() => {
										console.log("Search clicked");
									}}
								>
									<Search style={{ fontSize: "2rem" }} />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</div>
			<div className="projects-content-section">
				<Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-5">
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{companiescolumns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{companiesList
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}
											>
												{companiescolumns.map((column) => {
													const value = row.company[column.id];
													return (
														<TableCell key={column.id} align={column.align}>
															{column.format ? column.format(value) : value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={companiesList.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
			{/* <HiddenOn isHidden={!isBuilderAdmin(appRoleDetails)}> */}
			{/* {
				<div className="page-footer  fixed-footer align-content-center">
					<div className="d-flex justify-content-end ">
						<button
							className="primaryButton addProjectButton"
							style={{ width: "15rem" }}
							onClick={() => {
								setAddNewConnection(!addNewConnection);
							}}
						>
							{"+ Add New Connection"}
						</button>
					</div>
				</div>
			} */}
			{/* </HiddenOn> */}
		</div>
	);
};

export default CompaniesList;
