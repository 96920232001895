import { roleConstants as roles, rolesConfig } from "core/utils/roleUtils";
import PropertiesFolderView from "pages/property/PropertiesFolderView";
import {
	AddNewProperty,
	Projects,
	UnderDevelopment,
	ViewProject,
	ViewProperty,
} from "./loadables";
import Dashboard from "pages/dashboard/Dashboard";
import EditProperty from "pages/property/EditProperty";
import AlertNotifications from "pages/alerts/AlertNotifications";
import { Navigate, redirect } from "react-router-dom";
import { RedirectToProperty } from "pages/property/ViewProperty";

export const new_routes = [
	{
		path: "/property/addNew/",
		component: <AddNewProperty />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/property/edit/:propertyId",
		component: <EditProperty />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/properties/:propertyId",
		component: <ViewProperty />,
	},
	{
		path: "/properties/:propertyId/projects",
		component: <RedirectToProperty />,
	},
	{
		path: "/properties/:propertyId/projects/:projectId",
		component: <ViewProject />,
		roles: [
			roles.BUILDER_ADMIN,
			roles.BUILDER_GENERAL_MANAGER,
			...rolesConfig.projectViewRole,
		],
	},
	{
		path: "builder/crm",
		component: <UnderDevelopment />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/builder/financials",
		component: <UnderDevelopment />,
		roles: [roles.BUILDER_ADMIN, roles.BUILDER_GENERAL_MANAGER],
	},
	{
		path: "/alerts",
		component: <AlertNotifications />,
	},
];
