import React from "react";
import logo from "assets/app/logo.png";
import { getText } from "core/utils/getContentText";
import { IconButton } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";

export const CopilotHeader = ({ handleClose = null, fixed = false }) => {
	return (
		<>
			<div
				className={`copilot-header-footer containerSection header ${
					fixed && "fixed-header"
				}`}
			>
				<img className="header-logo" src={logo} alt={"BuilderCopilot_logo"} />
				{handleClose && (
					<IconButton onClick={handleClose} className="close-icon">
						<HighlightOff color="red" />
					</IconButton>
				)}
			</div>
		</>
	);
};
export const CopilotFooter = ({
	footerText = getText("footerText"),
	fixed = false,
}) => {
	return (
		<div className={`copilot-header-footer footer ${fixed && "fixed-footer"}`}>
			<p>{footerText}</p>
		</div>
	);
};
