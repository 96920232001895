import styled from "styled-components";
import { Tooltip, tooltipClasses } from "@mui/material";

export const StatsTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#F7F6F6",
		color: "rgba(0, 0, 0, 0.87)",
		maxHeight: "22rem",
		border: "1px solid #dadde9",
		[`& .${tooltipClasses.arrow}`]: {
			color: "#dadde9",
			"&:before": {
				width: 10,
			},
		},
	},
}));
