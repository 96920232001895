import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { server_url } from "../../constants/types";
import {
	IconButton,
	MenuItem,
	MenuList,
	TextField,
	Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const ReportColumnDetails = () => {
	const { id, reportid } = useParams();
	console.log("Check Id, Report ID ", id, reportid);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		columnName: "",
		dataType: "",
		columnType: "",
		customDef: "",
		conditionalFormat: "",
		pinned: "",
		aggFunc: "",
		header: "",
		append: "",
		prepend: "",
		hyperlinkUrl: "",
		numDecimals: 0,
		scaleFactor: 0,
		columnOrder: 0,
		reportID: 0,
		hide: false,
		rowGroup: false,
	});

	useEffect(() => {
		// Fetch data for the specific item to edit
		const tokenString = localStorage.getItem("token");

		const fetchData = async () => {
			if (reportid > 0) {
				const response = await fetch(
					`${server_url}/ReportColumn/columndata/${reportid}`
				);
				const result = await response.json();
				setFormData(result);
			} else {
				setFormData({
					...formData,
					["ReportID"]: reportid,
				});
			}
		};
		fetchData();
	}, []);

	const handleChange = (event) => {
		console.log("Check the Event : ", event.target);
		const { name, value, type, checked } = event.target;
		setFormData({
			...formData,
			[name]: type === "checkbox" ? checked : value,
		});
	};

	const handleCancel = (event) => {
		// if (formData.reportID > 0) {
		// 	navigate("/createreport/" + formData.reportID);
		// }
		navigate("/createreport/" + id);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		// Submit the updated data to the API
		const tokenString = localStorage.getItem("token");
		await fetch(`${server_url}/ReportColumn`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		});
		navigate("/reports");
	};

	const columnFields = [
		{
			key: "columnName",
			name: "columnName",
			label: "Column Name",
			type: "text",
		},
		{
			key: "header",
			name: "header",
			label: "Header",
			type: "text",
		},
		{
			key: "dataType",
			name: "dataType",
			label: "Data Type",
			type: "text",
		},
		{
			key: "columnType",
			name: "columnType",
			label: "Column Type",
			type: "select",
			select: true,

			options: ["Source", "Custom"],
		},
		{
			key: "customDef",
			name: "customDef",
			label: "Custom Def",
			type: "text",
		},

		{
			key: "conditionalFormat",
			name: "conditionalFormat",
			label: "Conditional Format",
			type: "text",
		},

		{
			key: "pinned",
			name: "pinned",
			label: "Pinned",
			type: "select",
			select: true,

			options: ["none", "left", "right"],
		},
		{
			key: "aggFunc",
			name: "aggFunc",
			label: "Agg Func",
			type: "select",
			select: true,
			options: [
				"none",
				"min",
				"max",
				"count",
				"avg",
				"first",
				"last",
				"weightaverage",
			],
		},
		{
			key: "numDecimals",
			name: "numDecimals",
			label: "Number of Decimals",
			type: "text",
		},
		{
			key: "hide",
			name: "hide",
			label: "Hide",
			type: "checkbox",
		},
		{
			key: "rowGroup",
			name: "rowGroup",
			label: "Row Group",
			type: "checkbox",
		},
	];

	return (
		<div>
			<div className="d-flex justify-content-between">
				<Typography className="h1">Edit Report Details</Typography>
				<IconButton onClick={handleCancel}>
					<CloseOutlined />
				</IconButton>
			</div>
			<form onSubmit={handleSubmit}>
				<div>
					<div className="d-flex flex-wrap">
						{columnFields.map((colField, index) => {
							return colField.type === "checkbox" ? (
								<div className="d-flex justify-content-around align-self-center ms-2 mt-4">
									<Typography variant="body1">{colField.label}:</Typography>
									<input
										type="checkbox"
										name={colField.name}
										checked={formData[colField.name]}
										onChange={handleChange}
									/>
								</div>
							) : (
								<div className="col-md-5 col-11 m-3">
									<Typography variant="body1">{colField.label}:</Typography>
									<TextField
										className="form-field mt-1"
										type={colField.type}
										name={colField.name}
										key={colField.key}
										value={formData[colField.name]}
										onChange={handleChange}
										select={colField.select || false}
									>
										{colField.options &&
											colField.options.map((option) => (
												<MenuItem value={option}>
													<Typography>{option}</Typography>
												</MenuItem>
											))}
									</TextField>
								</div>
							);
						})}
					</div>
				</div>
				<div className="d-flex justify-content-center">
					<button className="primaryButton" type="submit">
						Save
					</button>
					<button className="outlinedButton ms-3" onClick={handleCancel}>
						Canel
					</button>
				</div>
			</form>
		</div>
	);
};

export default ReportColumnDetails;
