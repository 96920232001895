import * as yup from "yup";
import { statesList } from "core/utils/statesList";
import { getText } from "core/utils/getContentText";
import {
	formatNumberChange,
	formatNumberKeyDown,
} from "components/TextFields/MobileUtils";
import { InputAdornment } from "@mui/material";

const getTextContent = (key) => getText(`projects.addProperty.${key}`);

const addPropertyValidationSchema = yup.object({
	propertyName: yup
		.string("Enter Property Name")
		.required("Property Name is required"),
	address: yup.string("Enter address").required("address is required"),
	city: yup.string("Enter city").required("city is required"),
	state: yup.string("Enter state").required("state is required"),
	zip: yup.string("Enter zipcode").required("ZipCode is required"),
	propertyDetails: yup
		.string("Enter Property Details")
		.required("Property Details is required"),
	propertyLocation: yup
		.string("Enter Property Location")
		.required("Property Location is required"),
	homeOwnerName: yup
		.string("Enter Homeowner Name")
		.required("Homeowner Name is required"),
	mobileNumber: yup
		.string("Enter Mobile Number")
		.required("Mobile Number is required"),
	legalDescription: yup.string("Enter Legal Description"),
	propertyType: yup
		.string("Enter Property Type")
		.required("Property Type is required"),
	bedroom: yup
		.number("Enter no.of bedrooms")
		.typeError("Please enter a valid number")
		.integer("Bedroom count must be an integer")
		.nullable(),
	bath: yup
		.number("Enter no.of bathrooms")
		.typeError("Please enter a valid number")
		.test(
			"is-valid-decimal",
			"Bathroom count must be an integer or end in .5",
			(value) => {
				return !value || value === "" || value % 1 === 0 || value % 1 === 0.5;
			}
		)
		.nullable(),

	garages: yup
		.number("Enter no.of garages")
		.typeError("Please enter a valid number")
		.test(
			"is-valid-decimal",
			"Bathroom count must be an integer or end in .5",
			(value) => !value || value === "" || value % 1 === 0 || value % 1 === 0.5
		)
		.nullable(),

	stories: yup
		.number("Enter no.of stories")
		.typeError("Please enter a valid number")
		.integer("Stories count must be an integer")
		.nullable(),

	buildingSize: yup.number("Enter Sq.Ft of Building").nullable(),

	lotSize: yup.number("Enter lotSize").nullable(),
});

export const addPropertyForm = {
	steps: Array.from({ length: 2 }, (_, index) =>
		getText(`profile.contractor.step${index + 1}`)
	),
	stepDescription: {
		step0: {
			heading: "Property Location Details",
		},
		step1: {
			heading: "Property Details",
			subHeading:
				"Please enter the number of bedrooms, bathrooms, garage spaces, and stories. Additionally, provide the building size and lot size, along with the total construction area in square feet.",
		},
		step3: {
			heading: "Timeline",
		},
		step4: {
			heading: "Payment Schedule",
		},
	},
	formFields: {
		step0: [
			{
				key: "propertyName",
				placeholder: "Property Name",
				label: getTextContent("propertyName"),
				type: "text",
				required: true,
				width: "100%",
			},
			// {
			// 	key: "propertyLocation",
			// 	placeholder: "Design of Property",
			// 	label: getTextContent("propertyLocation"),
			// 	type: "text",
			// 	required: true,
			// 	width: "100%",
			// 	// className: "col-4 col-xs-12",
			// },
			{
				key: "address",
				placeholder: "Property Address",
				label: getTextContent("propertyAddress"),
				type: "textArea",
				required: true,
				width: "100%",
			},
			{
				key: "city",
				placeholder: "City",
				label: getTextContent("city"),
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "state",
				placeholder: "state",
				label: getTextContent("state"),
				type: "select",
				select: true,
				options: statesList,
				required: true,
				width: "33%",
			},
			{
				key: "zip",
				placeholder: "Zip",
				label: getTextContent("zip"),
				type: "text",
				required: true,
				width: "33%",
			},
			{
				key: "propertyDetails",
				placeholder: "Details of Property",
				label: getTextContent("propertyDetails"),
				type: "textArea",
				required: true,
				width: "50%",
				extraInputProps: {
					multiline: true,
				},
			},
			{
				key: "homeOwnerName",
				placeholder: "Homeowner Name",
				label: getTextContent("homeownerName"),
				type: "textArea",
				required: true,
				width: "50%",
			},
			{
				key: "mobileNumber",
				placeholder: "(xxx) xxx-xxxx",
				label: getTextContent("homeownerMobile"),
				type: "mobile",
				required: true,
				width: "50%",
				extraInputProps: (formik) => ({
					onChange: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("mobileNumber", validatedValue);
						};
						formatNumberChange(e, onValidation);
					},
					onKeyDown: (e) => {
						const onValidation = (validatedValue) => {
							formik.setFieldValue("homeownerMobile", validatedValue);
						};
						formatNumberKeyDown(e, onValidation);
					},
					InputProps: {
						startAdornment: (
							<InputAdornment position="start">
								<span class="flag-icon flag-icon-us"></span> +1
							</InputAdornment>
						),
					},
				}),
			},
		],
		step1: [
			{
				key: "legalDescription",
				placeholder: "Legal Description to know more about Property",
				label: getTextContent("legalDescription"),
				type: "textarea",
				width: "100%",
				extraInputProps: {
					multiline: true,
				},
			},
			{
				key: "propertyType",
				placeholder: "Property Type",
				label: getTextContent("propertyType"),
				type: "select",
				required: true,
				select: true,
				options: ["New build", "Renovation", "Remodel", "Tear-down - Rebuild"],
				width: "50%",
			},

			{
				key: "architectureStyle",
				placeholder: "Architechture Style",
				label: getTextContent("architectureStyle"),
				width: "50%",
				type: "select",
				select: true,
				options: [
					"Cape Cod",
					"Victorian house",
					"Mediterranean",
					"Italianate",
					"Federal architecture",
					"Art Deco",
					"Queen Anne",
					"Gothic Revival",
					"Greek Revival",
					"Craftsman",
					"Tudor architecture",
					"Colonial architecture",
					"Prairie",
					"French country",
					"Bungalow",
					"Colonial Revival",
					"Mid-century modern",
					"Farmhouse",
					"Cottage",
					"Georgian architecture",
					"Ranch",
					"Neoclassical architecture",
					"Contemporary",
					"Craftsman Bungalow",
				],
				renderCondition: (type) => type === "Renovation",
			},

			{
				key: "bedroom",
				placeholder: "Bedroom",
				label: getTextContent("bedroom"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},

			{
				key: "bath",
				placeholder: "Bathroom",
				label: getTextContent("bathroom"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},

			{
				key: "garages",
				placeholder: "Count of Garages",
				label: getTextContent("garage"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},

			{
				key: "stories",
				placeholder: "Stories",
				label: getTextContent("stories"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},

			{
				key: "buildingSize",
				placeholder: "Building Size",
				label: getTextContent("buildingSize"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},
			{
				key: "lotSize",
				placeholder: "Lot Size",
				label: getTextContent("lotSize"),
				type: "text",
				width: "50%",
				renderCondition: (type) => type === "Renovation",
			},
		],
	},
	validationSchema: addPropertyValidationSchema,
	defaultState: (defaultState) => {
		if (defaultState) {
			return defaultState;
		}
		const initialState = Object.fromEntries(
			Object.keys(addPropertyValidationSchema.fields).map((key) => {
				return [key, ""];
			})
		);
		return {
			...initialState,
		};
	},
};
