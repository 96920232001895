export const ADD_NEW_JOB = "ADD_NEW_JOB";
export const ADD_NEW_JOB_SUCCESS = "ADD_NEW_JOB_SUCCESS";
export const ADD_NEW_JOB_ERROR = "ADD_NEW_JOB_ERROR";

export const GET_JOB_BY_ID = "GET_JOB_BY_ID";
export const GET_JOB_BY_ID_SUCCESS = "GET_JOB_BY_ID_SUCCESS";
export const GET_JOB_BY_ID_ERROR = "GET_JOB_BY_ID_ERROR";

export const UPDATE_JOB_USERS_ROLES = "UPDATE_JOB_USERS_ROLES";
export const UPDATE_JOB_USERS_ROLES_SUCCESS = "UPDATE_JOB_USERS_ROLES_SUCCESS";
export const UPDATE_JOB_USERS_ROLES_ERROR = "UPDATE_JOB_USERS_ROLES_ERROR";

export const GET_JOB_USERS_ROLES = "GET_JOB_USERS_ROLES";
export const GET_JOB_USERS_ROLES_SUCCESS = "GET_JOB_USERS_ROLES_SUCCESS";
export const GET_JOB_USERS_ROLES_ERROR = "GET_JOB_USERS_ROLES_ERROR";
