import {
	CONNECTION_LOADING,
	CONNECTION_GET_ALL_SUCCESS,
	CONNECTION_ERROR,
	CONNECTION_CLEAR_SNACKBAR,
} from "../constants/types";

const initialState = {
	connectionList: [],
	loading: true,
	status: "success",
	text: "",
	showSnackbar: false,
};

const connectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case CONNECTION_CLEAR_SNACKBAR:
			return {
				...state,
				showSnackbar: false,
			};
		case CONNECTION_LOADING:
			return {
				...state,
				loading: true,
			};
		case CONNECTION_ERROR:
			return {
				...state,
				connectionList: [],
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};
		case CONNECTION_GET_ALL_SUCCESS:
			return {
				...state,
				connectionList: action.payload.connectionList,
				loading: false,
				status: action.payload.status,
				text: action.payload.text,
				showSnackbar: true,
			};

		default:
			return state || {};
	}
};

export default connectionReducer;
