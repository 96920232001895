export const ACTIVITY_LOADING = "ACTIVITY_GET_ALL_BIGIN";
export const ACTIVITY_GET_ALL_SUCCESS = "ACTIVITY_GET_ALL_SUCCESS";
export const ACTIVITY_ERROR = "ACTIVITY_ERROR";
export const ACTIVITY_CLEAR_SNACKBAR = "ACTIVITY_CLEAR_SNACKBAR";
export const ALLOCATION_LOADING = "ALLOCATION_GET_ALL_BIGIN";
export const ALLOCATION_GET_ALL_SUCCESS = "ALLOCATION_GET_ALL_SUCCESS";
export const ALLOCATION_ERROR = "ALLOCATION_ERROR";
export const ALLOCATION_CLEAR_SNACKBAR = "ALLOCATION_CLEAR_SNACKBAR";
export const CHANGE_ORDER_LOADING = "CHANGE_ORDER_GET_ALL_BIGIN";
export const CHANGE_ORDER_GET_ALL_SUCCESS = "CHANGE_ORDER_GET_ALL_SUCCESS";
export const CHANGE_ORDER_ERROR = "CHANGE_ORDER_ERROR";
export const CHANGE_ORDER_CLEAR_SNACKBAR = "CHANGE_ORDER_CLEAR_SNACKBAR";
export const CLIENT_SELECTION_LOADING = "CLIENT_SELECTION_GET_ALL_BIGIN";
export const CLIENT_SELECTION_GET_ALL_SUCCESS =
	"CLIENT_SELECTION_GET_ALL_SUCCESS";
export const CLIENT_SELECTION_ERROR = "CLIENT_SELECTION_ERROR";
export const CLIENT_SELECTION_CLEAR_SNACKBAR =
	"CLIENT_SELECTION_CLEAR_SNACKBAR";
export const CONNECTION_LOADING = "CONNECTION_GET_ALL_BIGIN";
export const CONNECTION_GET_ALL_SUCCESS = "CONNECTION_GET_ALL_SUCCESS";
export const CONNECTION_ERROR = "CONNECTION_ERROR";
export const CONNECTION_CLEAR_SNACKBAR = "CONNECTION_CLEAR_SNACKBAR";
export const CONNECTION_STATUS_LOADING = "CONNECTION_STATUS_GET_ALL_BIGIN";
export const CONNECTION_STATUS_GET_ALL_SUCCESS =
	"CONNECTION_STATUS_GET_ALL_SUCCESS";
export const CONNECTION_STATUS_ERROR = "CONNECTION_STATUS_ERROR";
export const CONNECTION_STATUS_CLEAR_SNACKBAR =
	"CONNECTION_STATUS_CLEAR_SNACKBAR";
export const CONTACT_LOADING = "CONTACT_GET_ALL_BIGIN";
export const CONTACT_GET_ALL_SUCCESS = "CONTACT_GET_ALL_SUCCESS";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_CLEAR_SNACKBAR = "CONTACT_CLEAR_SNACKBAR";
export const CONTRACT_TYPE_LOADING = "CONTRACT_TYPE_GET_ALL_BIGIN";
export const CONTRACT_TYPE_GET_ALL_SUCCESS = "CONTRACT_TYPE_GET_ALL_SUCCESS";
export const CONTRACT_TYPE_ERROR = "CONTRACT_TYPE_ERROR";
export const CONTRACT_TYPE_CLEAR_SNACKBAR = "CONTRACT_TYPE_CLEAR_SNACKBAR";
export const CUSTOMER_LOADING = "CUSTOMER_GET_ALL_BIGIN";
export const CUSTOMER_GET_ALL_SUCCESS = "CUSTOMER_GET_ALL_SUCCESS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const CUSTOMER_CLEAR_SNACKBAR = "CUSTOMER_CLEAR_SNACKBAR";
export const DAILY_LOG_LOADING = "DAILY_LOG_GET_ALL_BIGIN";
export const DAILY_LOG_GET_ALL_SUCCESS = "DAILY_LOG_GET_ALL_SUCCESS";
export const DAILY_LOG_ERROR = "DAILY_LOG_ERROR";
export const DAILY_LOG_CLEAR_SNACKBAR = "DAILY_LOG_CLEAR_SNACKBAR";
export const EMPLOYEE_LOADING = "EMPLOYEE_GET_ALL_BIGIN";
export const EMPLOYEE_GET_ALL_SUCCESS = "EMPLOYEE_GET_ALL_SUCCESS";
export const EMPLOYEE_ERROR = "EMPLOYEE_ERROR";
export const EMPLOYEE_CLEAR_SNACKBAR = "EMPLOYEE_CLEAR_SNACKBAR";
export const INVOICE_LOADING = "INVOICE_GET_ALL_BIGIN";
export const INVOICE_GET_ALL_SUCCESS = "INVOICE_GET_ALL_SUCCESS";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const INVOICE_CLEAR_SNACKBAR = "INVOICE_CLEAR_SNACKBAR";
export const JOB_LOADING = "JOB_GET_ALL_BIGIN";
export const JOB_GET_ALL_SUCCESS = "JOB_GET_ALL_SUCCESS";
export const JOB_ERROR = "JOB_ERROR";
export const JOB_CLEAR_SNACKBAR = "JOB_CLEAR_SNACKBAR";
export const JOB_EMPLOYEE_LOADING = "JOB_EMPLOYEE_GET_ALL_BIGIN";
export const JOB_EMPLOYEE_GET_ALL_SUCCESS = "JOB_EMPLOYEE_GET_ALL_SUCCESS";
export const JOB_EMPLOYEE_ERROR = "JOB_EMPLOYEE_ERROR";
export const JOB_EMPLOYEE_CLEAR_SNACKBAR = "JOB_EMPLOYEE_CLEAR_SNACKBAR";
export const LEAD_LOADING = "LEAD_GET_ALL_BIGIN";
export const LEAD_GET_ALL_SUCCESS = "LEAD_GET_ALL_SUCCESS";
export const LEAD_ERROR = "LEAD_ERROR";
export const LEAD_CLEAR_SNACKBAR = "LEAD_CLEAR_SNACKBAR";
export const LEAD_ACTIVITY_LOADING = "LEAD_ACTIVITY_GET_ALL_BIGIN";
export const LEAD_ACTIVITY_GET_ALL_SUCCESS = "LEAD_ACTIVITY_GET_ALL_SUCCESS";
export const LEAD_ACTIVITY_ERROR = "LEAD_ACTIVITY_ERROR";
export const LEAD_ACTIVITY_CLEAR_SNACKBAR = "LEAD_ACTIVITY_CLEAR_SNACKBAR";
export const LEAD_STATUS_LOADING = "LEAD_STATUS_GET_ALL_BIGIN";
export const LEAD_STATUS_GET_ALL_SUCCESS = "LEAD_STATUS_GET_ALL_SUCCESS";
export const LEAD_STATUS_ERROR = "LEAD_STATUS_ERROR";
export const LEAD_STATUS_CLEAR_SNACKBAR = "LEAD_STATUS_CLEAR_SNACKBAR";
export const ORGANIZATION_LOADING = "ORGANIZATION_GET_ALL_BIGIN";
export const ORGANIZATION_GET_ALL_SUCCESS = "ORGANIZATION_GET_ALL_SUCCESS";
export const ORGANIZATION_ERROR = "ORGANIZATION_ERROR";
export const ORGANIZATION_CLEAR_SNACKBAR = "ORGANIZATION_CLEAR_SNACKBAR";
export const ORGANIZATION_STATUS_LOADING = "ORGANIZATION_STATUS_GET_ALL_BIGIN";
export const ORGANIZATION_STATUS_GET_ALL_SUCCESS =
	"ORGANIZATION_STATUS_GET_ALL_SUCCESS";
export const ORGANIZATION_STATUS_ERROR = "ORGANIZATION_STATUS_ERROR";
export const ORGANIZATION_STATUS_CLEAR_SNACKBAR =
	"ORGANIZATION_STATUS_CLEAR_SNACKBAR";
export const ORGANIZATION_TYPE_LOADING = "ORGANIZATION_TYPE_GET_ALL_BIGIN";
export const ORGANIZATION_TYPE_GET_ALL_SUCCESS =
	"ORGANIZATION_TYPE_GET_ALL_SUCCESS";
export const ORGANIZATION_TYPE_ERROR = "ORGANIZATION_TYPE_ERROR";
export const ORGANIZATION_TYPE_CLEAR_SNACKBAR =
	"ORGANIZATION_TYPE_CLEAR_SNACKBAR";
export const PAYMENT_LOADING = "PAYMENT_GET_ALL_BIGIN";
export const PAYMENT_GET_ALL_SUCCESS = "PAYMENT_GET_ALL_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const PAYMENT_CLEAR_SNACKBAR = "PAYMENT_CLEAR_SNACKBAR";
export const PURCHASE_ORDER_LOADING = "PURCHASE_ORDER_GET_ALL_BIGIN";
export const PURCHASE_ORDER_GET_ALL_SUCCESS = "PURCHASE_ORDER_GET_ALL_SUCCESS";
export const PURCHASE_ORDER_ERROR = "PURCHASE_ORDER_ERROR";
export const PURCHASE_ORDER_CLEAR_SNACKBAR = "PURCHASE_ORDER_CLEAR_SNACKBAR";
export const ROLE_LOADING = "ROLE_GET_ALL_BIGIN";
export const ROLE_GET_ALL_SUCCESS = "ROLE_GET_ALL_SUCCESS";
export const ROLE_ERROR = "ROLE_ERROR";
export const ROLE_CLEAR_SNACKBAR = "ROLE_CLEAR_SNACKBAR";
export const TASK_LOADING = "TASK_GET_ALL_BIGIN";
export const TASK_GET_ALL_SUCCESS = "TASK_GET_ALL_SUCCESS";
export const TASK_ERROR = "TASK_ERROR";
export const TASK_CLEAR_SNACKBAR = "TASK_CLEAR_SNACKBAR";
export const WARRANTY_SUPPORT_LOADING = "WARRANTY_SUPPORT_GET_ALL_BIGIN";
export const WARRANTY_SUPPORT_GET_ALL_SUCCESS =
	"WARRANTY_SUPPORT_GET_ALL_SUCCESS";
export const WARRANTY_SUPPORT_ERROR = "WARRANTY_SUPPORT_ERROR";
export const WARRANTY_SUPPORT_CLEAR_SNACKBAR =
	"WARRANTY_SUPPORT_CLEAR_SNACKBAR";

export const CUSTOMER_TYPE_LOADING = "CUSTOMER_TYPE_GET_ALL_BIGIN";
export const CUSTOMER_TYPE_GET_ALL_SUCCESS = "CUSTOMER_TYPE_GET_ALL_SUCCESS";
export const CUSTOMER_TYPE_ERROR = "CUSTOMER_TYPE_ERROR";
export const CUSTOMER_TYPE_CLEAR_SNACKBAR = "CUSTOMER_TYPE_CLEAR_SNACKBAR";

export const CUSTOMER_STATUS_LOADING = "CUSTOMER_STATUS_GET_ALL_BIGIN";
export const CUSTOMER_STATUS_GET_ALL_SUCCESS =
	"CUSTOMER_STATUS_GET_ALL_SUCCESS";
export const CUSTOMER_STATUS_ERROR = "CUSTOMER_STATUS_ERROR";
export const CUSTOMER_STATUS_CLEAR_SNACKBAR = "CUSTOMER_STATUS_CLEAR_SNACKBAR";

export const PROPERTY_LOADING = "CUSTOMER_GET_ALL_BIGIN";
export const PROPERTY_GET_ALL_SUCCESS = "CUSTOMER_GET_ALL_SUCCESS";
export const PROPERTY_ERROR = "CUSTOMER_ERROR";
export const PROPERTY_CLEAR_SNACKBAR = "CUSTOMER_CLEAR_SNACKBAR";

export const TASK_STATUS_LOADING = "TASK_STATUS_GET_ALL_BIGIN";
export const TASK_STATUS_GET_ALL_SUCCESS = "TASK_STATUS_GET_ALL_SUCCESS";
export const TASK_STATUS_ERROR = "TASK_STATUS_ERROR";
export const TASK_STATUS_CLEAR_SNACKBAR = "TASK_STATUS_CLEAR_SNACKBAR";

export const PURCHASE_ORDER_STATUS_LOADING =
	"PURCHASE_ORDER_STATUS_GET_ALL_BIGIN";
export const PURCHASE_ORDER_STATUS_GET_ALL_SUCCESS =
	"PURCHASE_ORDER_STATUS_GET_ALL_SUCCESS";
export const PURCHASE_ORDER_STATUS_ERROR = "PURCHASE_ORDER_STATUS_ERROR";
export const PURCHASE_ORDER_STATUS_CLEAR_SNACKBAR =
	"PURCHASE_ORDER_STATUS_CLEAR_SNACKBAR";

export const WARRANTY_STATUS_LOADING = "WARRANTY_STATUS_GET_ALL_BIGIN";
export const WARRANTY_STATUS_GET_ALL_SUCCESS =
	"WARRANTY_STATUS_GET_ALL_SUCCESS";
export const WARRANTY_STATUS_ERROR = "WARRANTY_STATUS_ERROR";
export const WARRANTY_STATUS_CLEAR_SNACKBAR = "WARRANTY_STATUS_CLEAR_SNACKBAR";

export const CHANGE_ORDER_STATUS_LOADING = "CHANGE_ORDER_STATUS_GET_ALL_BIGIN";
export const CHANGE_ORDER_STATUS_GET_ALL_SUCCESS =
	"CHANGE_ORDER_STATUS_GET_ALL_SUCCESS";
export const CHANGE_ORDER_STATUS_ERROR = "CHANGE_ORDER_STATUS_ERROR";
export const CHANGE_ORDER_STATUS_CLEAR_SNACKBAR =
	"CHANGE_ORDER_STATUS_CLEAR_SNACKBAR";

export const INVOICE_STATUS_LOADING = "INVOICE_STATUS_GET_ALL_BIGIN";
export const INVOICE_STATUS_GET_ALL_SUCCESS = "INVOICE_STATUS_GET_ALL_SUCCESS";
export const INVOICE_STATUS_ERROR = "INVOICE_STATUS_ERROR";
export const INVOICE_STATUS_CLEAR_SNACKBAR = "INVOICE_STATUS_CLEAR_SNACKBAR";

export const getEnvUrl = () => {
	const env = process.env.REACT_APP_ENVIRONMENT || "develop";

	console.log("Check the Env :", process.env, env);
	if (env === "develop") {
		return "https://devbuildercopilotapi.azurewebsites.net/api";
	} else if (env === "staging") {
		return "https://corewebapi20240513161440.azurewebsites.net/api";
	} else if (env === "production") {
		return "https://corewebapi20240513161440.azurewebsites.net/api";
	}
	return "https://corewebapi20240513161440.azurewebsites.net/api";
};
export const server_url = getEnvUrl();
// export const server_url = "https://localhost:44342/api";
